import axios from '../../http';
import { dateFormat } from '../../public';

const homeEachart = {
  /**
   * 今日停车数量
   * @returns { value }
   */
  todayCountByUser() {
    return axios.get('/acb/2.0/parkRecord/statics/todayCountByUser')
  },
  /**
   * 今日停车次数统计
   */
  todayCountPark() {
    return axios.get('/acb/2.0/dataAnalysisMain/getParkRecordCount')
  },  
  /**
   * 停车场路内路外统计
   */
  parkInOutData() {
    return axios.get('/acb/2.0/dataAnalysisMain/getParkCount')
  },   
  /**
   * 泊位路内路外统计
   */
  berthInOutData() {
    return axios.get('/acb/2.0/dataAnalysisMain/getBerthCount')
  },  
  /**
   * 汇总统计
   * @returns { parkCount: 车场总数， berthCount： 泊位总数 }
   */
  getGeneralStatisticsNew() {
    return axios.get('/acb/2.0/generalStatistics/getGeneralStatisticsNew')
  },
  /**
   * 设备统计
   */
  getEquipmentData() {
    return axios.get('/acb/2.0/dataAnalysisMain/getEquipment')
  },
  /**
   * 会员支付占比
   * @returns { totleNoMemberPayMoney: 非会员金额， totle： 总金额, totleNoMemberPayMoneyPercent: 非会员百分比, totleMemberPayMoneyPercent: 会员百分比, totleMemberPayMoney: 会员金额 }
   */
  payStaticByMember() {
    return axios.get('/acb/2.0/parkRecord/payStaticByMember')
  },

  /**
   * 汇总统计
   * @param date
   * @returns { berthTurnover: 日均泊位周转， parkUtilizationRatio： 日均泊位利用率 }
   */
  getSummary(date) {
    return axios.get('/acb/2.0/generalStatistics/summary', {
      data: {
        dataDate: date
      }
    })
  },
  /**
   * 今日停车高低峰
   * @returns { today: Array， yesterday：Array } 今日和昨日泊位使用情况，Array包括：泊位数、日期、出入场次数、停车时长（小时）、占用泊位数量、占用率
   */
  getHourParkPeakStatics() {
    return axios.get('/acb/2.0/berthStatistics/hourParkPeakStatics')
  },
  /**
   * 今日停车高低峰
   * @returns { parkEasyIndex: 停车难易度， stableIndex：停车稳定指数， parkCreditIndex：停车信用指数， parkSmartIndex：停车智能指数， berthRationalIndex：泊位建设合理指数 }
   */
  parkIndex() {
    return axios.get('/acb/2.0/generalStatistics/parkIndex')
  },
  /**
   * 今日停车高低峰
   * @returns { parkEasyIndex: 停车难易度， stableIndex：停车稳定指数， parkCreditIndex：停车信用指数， parkSmartIndex：停车智能指数， berthRationalIndex：泊位建设合理指数 }
   */
  parkRecordScroll() {
    return axios.get('/acb/2.0/parkRecord/ParkRecordScroll')
  },

  /**
   * 昼夜停车时长占比（31天）
   * @param date: { startDate, endDate }
   * @returns { nigthTime: number， dayTime：number } 夜间停车时长_单位秒、白天停车时长_单位秒
   */
  dayAndNightParkTime(date) {
    return axios.get('/acb/2.0/parkingAnalysis/dayAndNightParkTime', {
      data: {
        startDate: date.startDate,
        endDate: date.endDate
      }
    })
  },
  /**
   * 停车压力车场Top5（今日实时）
   * ?reportType=1&reportDate=2021-03-05
   * @returns { parkName: 车场名称， berthCount：泊位数, berthOccupyCount: 泊位占用数 } 固定值 1、当天日期_yyyy-MM-dd }
   */
  berthOccupyRatio() {
    return axios.get('/acb/2.0/parkReport/queryParkIndexTopN/berthOccupyRatio', {
      data: {
        reportType: 1, // 固定值 1
        reportDate: dateFormat(new Date(), 'yyyy-MM-dd'), // 当天日期_yyyy-MM-dd
      }
    })
  },
  /**
   * 车场收入top5（31天）
   * @param date: { startDate：yyyy-MM-dd 昨天, endDate:yyyy-MM-dd 31天前 }
   * @returns { parkName: 车场名称， actualPay：收入_单位分 }
   */
  actualPay(date) {
    return axios.get('/acb/2.0/parkReport/queryParkIndexTopN/actualPay', {
      data: {
        reportType: 1, // reportType: 固定值 1
        startDate: date.startDate,
        endDate: date.endDate
      }
    })
  },
  /**
   * 城市收入对比(31天)
   * @param data: { startDate：yyyy-MM-dd 昨天, endDate:yyyy-MM-dd 31天前 }
   * @returns { areaName: 区域名称， actualPay：收入_单位分 }
   */
  queryListAmountByArea(data) {
    return axios.get('/acb/2.0/parkReport/queryListAmountByArea', {
      data: {
        reportType: 1, // reportType: 固定值 1
        startDate: data.startDate,
        endDate: data.endDate
      }
    })
  },
}
export default homeEachart
