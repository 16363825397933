<!--
 * @Author: wch
 * @Date: 2020-08-06 15:14:57
 * @LastEditTime: 2020-11-13 10:38:33
 * @LastEditors: Please set LastEditors
 * @Description: 消息通知
 * @FilePath: \trunk\src\components\notification\notification.vue
-->
<template>
  <transition name="fade">
    <div class="message" v-if="visible">
      <div class="title">
        <span class="tip">消息提醒</span>
        <i class="el-icon-close" @click="close" style="cursor: pointer"></i>
      </div>
      <div class="content" style="text-algin:center">
        <img :src="imgSrc" alt="" style="width: 100px; height: 100px" />
      </div>
      <div
        class="content"
        style="
          border-bottom-style: solid;
          border-color: #dcdee0;
          border-width: 1px;
          margin-bottom: 14px;
        "
      >
        <span style="color: #323233">{{ content }}</span
        ><span
          @click="btnSure"
          style="color: #0ba7ee; cursor: pointer; margin-left: 10px"
          >[去看看]</span
        >
      </div>
      <el-checkbox v-model="checked">本次不再提醒</el-checkbox>
      <!-- <div class="btnSure" @click="btnSure">查看</div> -->
    </div>
  </transition>
</template>

<script>
import carAlarm from "./carAlarm.png";
import msgAlarm from "./msgAlarm.png";

export default {
  name: "notification",
  data() {
    return {
      content: "",
      click: "",
      time: 5000,
      visible: true,
      checked: false,
      type: 1,
    };
  },
  computed: {
    imgSrc: function () {
      return this.type == 1 ? msgAlarm : carAlarm;
    },
  },
  methods: {
    close() {
      this.visible = false;
      sessionStorage.blockNotification = this.checked ? 1 : 0;
      sessionStorage.notificationShowing = 0;
      },
    btnSure() {
      this.clickSure();
      this.close();
    },
  },
  mounted() {
    // this.close();
  },
};
</script>
<style lang="stylus" scoped>
// 渐变过渡
.fade-enter, .fade-leave-active {
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.35s;
}

.message {
  width: 330px;
  bottom: 20px;
  right: 20px;
  padding: 14px 26px 14px 13px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #ebeef5;
  position: fixed;
  background-color: #fff;
  z-index: 1000;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s, transform 0.3s, left 0.3s, right 0.3s, top 0.4s, bottom 0.3s;
  overflow: hidden;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 14px;
    border-bottom-style: solid;
    border-color: #dcdee0;
    border-width: 1px;

    .tip {
      display: inline-block;
      margin-right: 10px;
      color: #323233;
    }
  }

  .content {
    text-align: center;
    padding: 20px;
    background-color: #fff !important;
  }

  .btnSure {
    padding: 10px 5px;
    width: 70px;
    margin: 0 auto;
    text-align: center;
    border-radius: 8px;
    color: #ffffff;
    background: #429EFD;
    cursor: pointer;
  }
}
</style>
