import ElementUI from "element-ui";
import moment from "moment";
import "element-ui/lib/theme-chalk/index.css";
import "./common/font/font.css";
import "./common/css/base.styl";
import Vue from "vue";
import "@/assets"; // 引入svg
import "@/comps"; // 引入全局组件
// import echarts from 'echarts';
import GeminiScrollbar from "vue-gemini-scrollbar";
import "./common/css/index.css";
import VueResource from "vue-resource";
import VueLazyLoad from "vue-lazyload";
import Viewer from "v-viewer";
import VueClipboard from "vue-clipboard2";
import VueAMap from "vue-amap";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import $axios from "@/common/js/http";
import * as echarts from "echarts";
import api from "./common/js/api";
import ACascader from "@/components/business/a-cascader";
import ADatePicker from "@/components/business/a-date-picker";
import AOperationSelect from "@/components/business/a-operation-select";
import AParkTypeSelect from "@/components/business/a-park-type-select";
import AParkSelect from "@/components/business/a-park-select";
import AuthorityComponent from "@/components/auComponent";
import { request, setAlreadyAu, TimeAll } from "@/common/js/public";
import ClipboardJS from "clipboard";
import i18n from "./i18n";
import notification from "@/components/notification/notification";
import "./assets/icon/font_4539787_bqzdl3qt8fw/iconfont.css";
import * as filters from "@/common/js/filters";
Object.keys(filters).forEach((key) => Vue.filter(key, filters[key]));

import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

Vue.use(VueQuillEditor);
Vue.prototype.$api = api;
Vue.prototype.$TimeAll = TimeAll;
Vue.prototype.$axios = $axios;
Vue.prototype.$request = request;
Vue.prototype.$echarts = echarts;
// Vue.prototype.$echarts = echarts;
Vue.prototype.$setAuthority = setAlreadyAu;
Vue.prototype.$EventBus = new Vue();
Vue.prototype.$moment = moment;
Vue.prototype.$notification = notification.install;
Vue.prototype.$colorList = [
  "#5A90FF",
  "#5FE4AD",
  "#788DB4",
  "#FFC314",
  "#8172FF",
  "#79D4F9",
  "#AB70D6",
  "#FF9844",
  "#1AAEAD",
  "#FD90BC",
  "#28A1DC",
  "#1BC99F",
  "#F6725A",
  "#FCABAB",
  "#9B9B4E",
  "#CFC78F",
  "#996E52",
  "#80B594",
  "#FF3300",
  "#9933CC",
  "#00FF00",
  "#0f6eff",
  "#FF9900",
  "#FFFF00",
  "#171717",
  "#FD90BC",
  "#28A1DC",
  "#1BC99F",
  "#F6725A",
  "#FCABAB",
  "#9B9B4E",
];
Vue.component("ACascader", ACascader);
Vue.component("ADatePicker", ADatePicker);
Vue.component("AOperationSelect", AOperationSelect);
Vue.component("AParkSelect", AParkSelect);
Vue.component("AParkTypeSelect", AParkTypeSelect);
Vue.use(AuthorityComponent);
Vue.use(VueAMap);
// Vue.use(ElementUI);
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value),
});
Vue.use(GeminiScrollbar);
Vue.use(VueResource);
Vue.use(VueClipboard);
Vue.use(VueLazyLoad, {
  preload: 1.3,
  error: require("./assets/img/car-pic.png"),
  loading: require("./assets/img/qr_loading.gif"),
  try: 2,
});
Vue.use(Viewer, {
  debug: true,
  defaultOptions: {
    zIndex: 9999,
  },
});

import kkVueLog from "@/common/directives/buriedPoint";

const options = {
  // 埋点数据上传的接口，必填
  // url: "http://10.100.11.232:8080/acb/2.0/park/list",
  url: "/acb/2.0/temp/saveMenuStayDuration",
  router,
  logFunction(config) {
    // if (config.duration) {
    //   console.log("config-", config);
    //   console.log("时间是", config.duration);
    //   return;
    // }
    console.log(config.url, "config.urlconfig.url");
    let menuUrl = config.url;
    menuUrl = menuUrl.substring(menuUrl.lastIndexOf("/") + 1);
    console.log(menuUrl.indexOf("daping"), "menuUrl-->");
    if (menuUrl.indexOf("daping") == 0) {
      return;
    }
    $axios
      .get(this.url, {
        data: {
          // ...config,
          menuUrl: menuUrl,
          stayDuration: config.duration,
        },
      })
      .then((res) => {});
  },
  // 一个数组，(可选) 需要记录停留时间的页面vue路由path, 若写['*']则为所有页面
  durationRoute: ["*"],

  // 是否需要记录跳转来源 (可选)
  // needReferrer: false,

  // 跳转来源的页面标记字段 (可选) 例如 http://www.baidu.com?from=baidu
  // sign: 'from'
};
Vue.use(kkVueLog, options);
// 地图初始化
VueAMap.initAMapApiLoader({
  key: "d93943ad3b7098e4b4fb156442f9ffa1",
  plugin: ["AMap.PolyEditor", "AMap.Geocoder"],
  uiVersion: "1.0", // 版本号
});

Vue.config.productionTip = false;

let self = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
let clipboard1 = new ClipboardJS(".qrbtn1");
let clipboard2 = new ClipboardJS(".qrbtn2");
let clipboard3 = new ClipboardJS(".qrbtn3");
clipboard1.on("success", function (e) {
  self.$alert("复制成功！", "提示", {
    confirmButtonText: "确定",
    callback: (action) => {},
  });
  e.clearSelection();
});
clipboard2.on("success", function (e) {
  self.$alert("复制成功！", "提示", {
    confirmButtonText: "确定",
    callback: (action) => {},
  });
  e.clearSelection();
});
clipboard3.on("success", function (e) {
  self.$alert("复制成功！", "提示", {
    confirmButtonText: "确定",
    callback: (action) => {},
  });
  e.clearSelection();
});
