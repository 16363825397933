export default [
    {
        path: 'deviceManage21',
        name: 'deviceManage21',
        meta: {
            title: '物品管理'
        },
        component: () => import('@/views/property/property')
    }, 
    {
        path: 'deviceManage24',
        name: 'deviceManage24',
        meta: {
            title: '型号配置'
        },
        component: () => import('@/views/property/assetTypes')
    },
     {
        path: 'assetTypeView',
        name: 'deviceManage24/assetTypeView',
        meta: {
            title: '资产型号查看'
        },
        component: () => import('@/views/property/assetTypeView')
    },
     {
        path: 'assetTypesAdd',
        name: 'deviceManage24/assetTypesAdd',
        meta: {
            title: '资产型号管理'
        },
        component: () => import('@/views/property/assetTypesAdd')
    },
    {
        path: 'deviceManage23',
        name: 'deviceManage23',
        meta: {
            title: '类型配置'
        },
        component: () => import('@/views/property/propertyType')
    }, 
    {
        path: 'propertyTypeAdd',
        name: 'deviceManage23/propertyTypeAdd',
        meta: {
            title: '资产类别添加'
        },
        component: () => import('@/views/property/propertyTypeAdd')
    }, 
    {
        path: 'propertyTypeView',
        name: 'deviceManage23/propertyTypeView',
        meta: {
            title: '资产类别查看'
        },
        component: () => import('@/views/property/propertyTypeView')
    }, 
    {
        path: 'property',
        name: 'property',
        meta: {
            title: '资产管理'
        },
        component: () => import('@/views/property/property')
    },
    {
        path: 'propertyView',
        name: 'deviceManage21/propertyView',
        meta: {
            title: '资产查看'
        },
        component: () => import('@/views/property/propertyView')
    },
     {
        path: 'propertyAdd',
        name: 'deviceManage21/propertyAdd',
        meta: {
            title: '资产添加'
        },
        component: () => import('@/views/property/propertyAdd')
    },
    {
        path: 'deviceManage22',
        name: 'deviceManage22',
        meta: {
            title: '物品统计'
        },
        component: () => import('@/views/property/propertyTotal')
    },
];
