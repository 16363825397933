import Vue from "vue";
import VueI18n from "vue-i18n";
// 引入语言配置
import zh from "./langs/zh";
import en from "./langs/en";
// 引入框架语言配置
import ElementLocale from "element-ui/lib/locale";
import enLocale from "element-ui/lib/locale/lang/en";
import zhLocale from "element-ui/lib/locale/lang/zh-CN";

ElementLocale.i18n((key, value) => i18n.t(key, value));
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || "zh",
  messages: {
    // 中文语言包
    zh: {
      ...zh,
      ...zhLocale,
    },
    // 英文语言包
    en: {
      ...en,
      ...enLocale,
    },
  },
  silentTranslationWarn: true,
  globalInjectiong: true,
  fallbackLocale: "zh",
});

export default i18n;
