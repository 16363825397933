<template>
  <div class="a-operation-select-wrapper">
    <el-select v-model.trim="operationId" filterable clearable @change="change">
      <el-option label="全部" :value="''" :key="''"></el-option>
      <el-option
        :label="value.operationName"
        :value="value.operationId"
        :key="value.operationId"
        v-for="value in operationList"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      operationId: "",
      operationList: [],
    };
  },
  created() {
    this.getOperationList();
  },
  methods: {
    getOperationId() {
      return this.operationId;
    },
    clear() {
      this.operationId = "";
    },
    change(val) {
      this.$emit("change", val);
    },
    // 所属运营商下拉框
    getOperationList() {
      this.$axios
        .get("/acb/2.0/operation/nameList")
        .then((res) => {
          if (res.state == 0) {
            this.operationList = res.value;
          } else {
            this.operationList = [];
          }
        })
        .catch((e) => {
          this.operationList = [];
        });
    },
  },
};
</script>

<style lang="stylus" rel="stylesheet/stylus"></style>
