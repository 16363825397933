<template>
  <div class="a-cascader-wrapper">
    <el-cascader
      ref="cc"
      :props="props"
      v-model="areaIds"
      placeholder="全部"
      popper-class="a-cascader-poper"
      :show-all-levels="false"
      @change="change"
      clearable
    ></el-cascader>
  </div>
</template>

<script>
export default {
  watch: {
    areaIds: {
      handler(val) {
        // console.log(val);
      },
    },
  },
  data() {
    const _self = this;
    return {
      areaIds: [],
      cityList: [],
      areaList: [],
      props: {
        checkStrictly: true,
        expandTrigger: "hover",
        lazy: true,
        async lazyLoad(node, resolve) {
          const { level, root } = node;
          if (root) {
            const res = await _self.getCityList();
            _self.cityList = res;
            const nodes = [
              {
                areaCode: "-1",
                value: "-1",
                label: `全部`,
                leaf: false,
              },
            ];
            // console.log("888888888888888")
            _self.areaIds = ["-1"];
            resolve(nodes);
            // console.log(res, "0000000000");
          } else {
            const { level, root } = node;
            if (level == 1) {
              let nodes = [];
              _self.cityList.forEach((item) => {
                nodes.push({
                  areaCode: item.areaCode,
                  value: item.areaId,
                  label: item.areaName,
                  leaf: false,
                });
              });
              resolve(nodes);
            } else {
              // console.log(node, "level1111111111");
              const res = await _self.getAreaList(node.value);
              if (res && res.length > 0) {
                _self.areaList = res;
              }

              let nodes = [];
              res.forEach((item) => {
                nodes.push({
                  areaCode: item.areaCode,
                  value: item.areaId,
                  label: item.areaName,
                  leaf: true,
                });
              });
              resolve(nodes);
            }
          }
        },
      },
    };
  },
  created() {},
  methods: {
    change(val) {
      console.log(val, "11111111");
      if (val.length == 0) {
        this.areaIds = ["-1"];
      }
      this.$refs.cc.dropDownVisible = false;
      // val.length 全部1 省2 区 3
      this.$emit("change", val.length);
    },
    clear() {
      this.areaIds = ["-1"];
    },
    getStreetId() {
      let streetId = "";
      if (this.areaIds.length > 0) {
        if (this.areaIds.length > 2) {
          streetId = this.areaIds[2];
        } else {
          streetId = "";
        }
      }
      // console.log(streetId, "123333");
      return streetId;
    },
    getAreaCode() {
      let areaCode = "";
      if (this.$refs.cc.getCheckedNodes()[0]) {
        areaCode = this.$refs.cc.getCheckedNodes()[0].data.areaCode;
      }

      return areaCode;
    },
    getAreaId() {
      let streetId = this.getStreetId();
      if (streetId == "") {
        let areaId = "";
        if (this.areaIds.length > 0) {
          if (this.areaIds.length > 1) {
            areaId = this.areaIds[1];
          } else {
            areaId = "";
          }
        }
        return areaId;
      } else {
        return streetId;
      }
    },
    getAreaList(areaId) {
      let areaList = [];
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`/acb/2.0/area/children/${areaId}`, {
            data: {
              id: areaId,
            },
          })
          .then((res) => {
            if (res.value && res.value.length > 0) {
              areaList = res.value;
            } else {
              areaList = [];
            }
            resolve(areaList);
          })
          .catch((e) => {
            resolve([]);
          });
      });
    },
    getCityList() {
      let cityList = [];
      return new Promise((resolve, reject) => {
        this.$axios
          .get("/acb/2.0/systems/loginUser/initAreaList")
          .then((res) => {
            if (res.value) {
              cityList = res.value.areaList;
            } else {
              cityList = [];
            }
            resolve(cityList);
          })
          .catch((e) => {
            resolve([]);
          });
      });
    },
  },
};
</script>

<style lang="stylus" rel="stylesheet/stylus"></style>
<style scoped>

</style>
