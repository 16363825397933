export default [{
  // 业务订单查询-停车支付订单
  path: "parkingPaymentOrder",
  name: "parkingPaymentOrder",
  meta: {
    title: '停车订单查询'
  },
  component: () => import('@/views/businessOrderQuery/indexTab.vue')
},
// 3.0 财务管理-业务订单查询-停车订单查询-订单欠费查询
{
  path: "oweOrder",
  name: "oweOrder",
  meta: {
    title: '欠费订单查询'
  },
  component: () =>
    import('@/views/businessOrderQuery/orderArrearageQuery')
},
{
  path: "arrearageRecord",
  name: "oweOrder/arrearageRecord",
  component: () =>
    import('@/views/Owetomodify/amendantRecord')
},
// 3.0 财务管理-业务订单查询-订单修改明细
{
  path: "orderEditDetail",
  name: "orderEditDetail",
  meta: {
    title: '订单修改明细'
  },
  component: () =>
    import('@/views/businessOrderQuery/group/orderModifyDetails')
},
// 3.0 财务管理-业务订单查询-退款订单查询
{
  path: "refundManagement",
  name: "refundManagement",
  meta: {
    title: '退款订单查询'
  },
  component: () =>
    import('@/views/businessOrderQuery/group/refundOrderQuery')
},
{
  path: "record",
  name: "refundManagement/record",
  component: () =>
    import('@/views/refundManagement/record')
},
{
  // 业务订单查询-停车欠费订单
  path: "parkingArrearageOrder",
  name: "parkingArrearageOrder",
  meta: {
    title: '停车欠费订单'
  },
  component: () =>
    import('@/views/businessOrderQuery/parkingArrearageOrder')
},
{
  // 业务订单查询-充值记录查询
  path: "rechargeTotal",
  name: "rechargeTotal",
  meta: {
    title: '充值记录查询'
  },
  component: () =>
    import('@/views/businessOrderQuery/rechargeOrder')
},
// {
//   // 业务订单查询-商品支付订单
//   path: "parkCardPayOrder",
//   name: "parkCardPayOrder",
//   meta: {
//     title: '商品支付订单'
//   },
//   component: () =>
//     import ('@/views/businessOrderQuery/parkingCardPayOrder')
// },
{
  // 业务订单查询-发票记录
  path: "invoice1",
  name: "invoice1",
  meta: {
    title: '发票记录'
  },
  component: () =>
    import('@/views/businessOrderQuery/invoiceRecord')
},
{
  // 业务订单查询-开具电子发票
  path: "invoiceOperate",
  name: "invoiceOperate",
  meta: {
    title: '开具电子发票'
  },
  component: () =>
    import('@/views/businessOrderQuery/invoiceOperate')
},
// 3.1 变成了菜单变成了 交易流水查询
// {
//   // 业务订单查询-支付流水查询
//   path: "payRecordTotal",
//   name: "payRecordTotal",
//   meta: {
//     title: '支付流水查询'
//   },
//   component: () =>
//     import ('@/views/businessOrderQuery/payRecordTotal')
// },
{
  // 业务订单查询-交易流水查询
  path: "payRecordTotal",
  name: "payRecordTotal",
  meta: {
    title: '交易流水查询'
  },
  component: () =>
    import('@/views/businessOrderQuery/transactionFlow')
},
{
  // 退款管理-欠费订单修改
  path: "oweToModify",
  name: "oweToModify",
  meta: {
    title: '欠费订单修改'
  },
  component: () =>
    import('@/views/Owetomodify/oweToModify')
},
{
  // 退款管理-退款申请
  path: "applyFor",
  name: "applyFor",
  meta: {
    title: '退款申请'
  },
  component: () =>
    import('@/views/refundManagement/applyFor')
},
// 退款管理-退款审核
{

  path: "examine",
  name: "examine",
  meta: {
    title: '退款审核'
  },
  component: () =>
    import('@/views/refundManagement/examine')
},
{
  // 处理记录审核
  path: "exceptionOrder_sensor",
  name: "exceptionOrder_sensor",
  meta: {
    title: '处理记录审核'
  },
  component: () =>
    import('@/views/errorOrder/processingRecordReview')
},
{
  // 处理记录列表
  path: "exceptionOrder_list",
  name: "exceptionOrder_list",
  meta: {
    title: '处理记录列表'
  },
  component: () =>
    import('@/views/errorOrder/processingList')
},
{
  // 处理记录列表
  path: "exceptionOrder_start",
  name: "exceptionOrder_start",
  meta: {
    title: '发起异常处理'
  },
  component: () =>
    import('@/views/errorOrder/InitiateExceptionHandling')
},
{
  // 退款管理-退款确认
  path: "affirm",
  name: "affirm",
  meta: {
    title: '退款确认'
  },
  component: () =>
    import('@/views/refundManagement/affirm')
},
{
  // 退款管理-退款记录
  path: "record",
  name: "record",
  meta: {
    title: '退款记录'
  },
  component: () =>
    import('@/views/refundManagement/record')
},

{
  // 财务报表-临停报表
  path: "temporaryParkReport",
  name: "temporaryParkReport",
  meta: {
    title: '停车收入报表'
  },
  component: () =>
    import('@/views/financialStatements/Report/stopReport')
},
{
  // 财务报表-运营报表
  path: "operationReport",
  name: "operationReport",
  meta: {
    title: '运营报表'
  },
  component: () =>
    import('@/views/financialStatements/Report/operatingReports')
},
{
  // 财务报表-停车场收入报表
  path: "parkIncomeForm",
  name: "parkIncomeForm",
  meta: {
    title: '车场报表'
  },
  component: () =>
    import('@/views/financialStatements/parkingRevenueStatement')
},
{
  // 财务报表-预付费报表
  path: "preOrderReport",
  name: "preOrderReport",
  meta: {
    title: '预付报表'
  },
  component: () =>
    import('@/views/financialStatements/prepayment')
},
{
  path: "chargingBalanceRechargeRecord",
  name: "chargingBalanceRechargeRecord",
  meta: {
    title: '充电余额充值记录'
  },
  component: () =>
    import('@/views/chargingBalanceRechargeRecord/')
},
{
  // 财务报表-收费员收入统计
  path: "collectorIncome",
  name: "collectorIncome",
  meta: {
    title: 'PDA统计'
  },
  component: () =>
    import('@/views/financialStatements/revenueStatisticsOfTollCollectors')
},
{
  // 财务报表-停车卡报表
  path: "parkCardReport",
  name: "parkCardReport",
  meta: {
    title: '停车卡报表'
  },
  component: () =>
    import('@/views/financialStatements/parkingCardReport')
},
{
  // 财务报表-商家充值报表
  path: "storeRechargeReport",
  name: "storeRechargeReport",
  meta: {
    title: '商家充值报表'
  },
  component: () =>
    import('@/views/financialStatements/merchantRechargeReport')
},

// {
//   // 财务报表-预约停车车场报表
//   path: "bookReport",
//   name: "bookReport",
//   meta: {
//     title: '预约停车车场报表'
//   },
//   component: () =>
//     import('@/views/financialStatements/reserveParking')
// },


{
  // 财务报表-预约停车车场报表
  path: "bookReport",
  name: "bookReport",
  meta: {
    title: '预约停车车场报表'
  },
  component: () =>
    import('@/views/financialStatements/index.vue')
},

/* {
  // 财务报表-预约停车商户报表
  path: "bookMerchants",
  name: "bookMerchants",
  meta: {
    title: '预约停车商户报表'
  },
  component: () =>
    import ('@/views/financialStatements/merchantReport')
}, */
{
  // 财务报表-预约停车商户报表
  path: "appointmentParkReport",
  name: "appointmentParkReport",
  meta: {
    title: '预约停车商户报表'
  },
  component: () =>
    import('@/views/financialStatements/merchantReportNew')
},
/* {
  // 财务报表-预约停车商户报表
  path: "appointmentOperationReport",
  name: "appointmentOperationReport",
  meta: {
    title: '预约停车商户报表'
  },
  component: () =>
    import('@/views/financialStatements/businessTable')
}, */


{
  path: "chargingBalanceStatics",
  name: "chargingBalanceStatics",
  meta: {
    title: '充电余额统计'
  },
  component: () =>
    import('@/views/chargingBalanceStatics/')
},
{
  path: "chargingIncomeStatics",
  name: "chargingIncomeStatics",
  meta: {
    title: '充电站收入报表'
  },
  component: () =>
    import('@/views/chargingIncomeStatics/')
},
{
  // 财务报表-营收总报表
  path: "paySummaryReport",
  name: "paySummaryReport",
  meta: {
    title: '总收入报表'
  },
  component: () =>
    import('@/views/financialStatements/generalStatementOfRevenue')
},
{
  // 财务报表-支付渠道统计
  path: "payChannelForm",
  name: "payChannelForm",
  meta: {
    title: '支付渠道统计'
  },
  component: () =>
    import('@/views/financialStatements/paymentChannelStatistics')
},
{
  // 清分结算-支付渠道对账
  path: "wayStatistics",
  name: "wayStatistics",
  meta: {
    title: '支付渠道对账'
  },
  component: () =>
    import('@/views/clearingAndClearing/paymentChannelReconciliation')
},
{
  // 清分结算-服务商对账
  path: "speciallyBusiness",
  name: "speciallyBusiness",
  meta: {
    title: '服务商对账'
  },
  component: () =>
    import('@/views/clearingAndClearing/speciallyBusiness')
},
{
  // 清分结算-普通商户对账
  path: "generalBusiness",
  name: "generalBusiness",
  meta: {
    title: '渠道对账'
  },
  component: () =>
    import('@/views/clearingAndClearing/generalBusiness')
},
{
  // 清分结算-普通商户对账
  path: "businessIncomeForm",
  name: "businessIncomeForm",
  meta: {
    title: '商户收入报表'
  },
  component: () =>
    import('@/views/clearingAndClearing/incomeForm')
},
{
  // 清分结算-挂单平账
  path: "billDiff",
  name: "billDiff",
  meta: {
    title: '平账记录'
  },
  component: () =>
    import('@/views/clearingAndClearing/flatBill')
},
{
  // 异常支付
  path: "abnormalPayment",
  name: "abnormalPayment",
  meta: {
    title: '异常支付'
  },
  component: () =>
    import('@/views/clearingAndClearing/abnormalPay')
  // import('@/views/clearingAndClearing/abnormalList')
},
{
  // 清分结算-重复支付
  path: "payRepeat",
  name: "payRepeat",
  meta: {
    title: '重复支付'
  },
  component: () =>
    import('@/views/clearingAndClearing/repeatedPayment')
},
{
  // 清分结算-财务日报
  path: "dailyInterim",
  name: "dailyInterim",
  meta: {
    title: '平台商户日报'
  },
  component: () =>
    import('@/views/clearingAndClearing/financialDaily')
},
{
  // 清分结算-商户清算
  path: "revenueStatistics",
  name: "revenueStatistics",
  meta: {
    title: '商户清算'
  },
  component: () =>
    import('@/views/clearingAndClearing/operatorLiquidation')
},
{
  // 支付配置-应用配置
  path: "applicationConfiguration",
  name: "applicationConfiguration",
  meta: {
    title: '应用配置'
  },
  component: () =>
    import('@/views/applicationConfiguration/applicationConfiguration')
},
{
  path: "appConfigDetail",
  name: "applicationConfiguration/appConfigDetail",
  component: () =>
    import('@/views/applicationConfiguration/appConfigDetail'),
  meta: {
    title: '应用配置详情'
  }
},
{
  // 支付配置-支付商户配置
  path: "merchantConfiguration",
  name: "merchantConfiguration",
  meta: {
    title: '支付商户配置'
  },
  component: () =>
    import('@/views/merchantConfiguration')
},
{
  path: "paymentMerchantConfigurationDetail",
  name: "merchantConfiguration/edit",
  component: () =>
    import('@/views/merchantConfiguration/edit'),
  meta: {
    title: '支付商户配置编辑'
  }
},
{
  path: "paymentMerchantConfiguration",
  name: "merchantConfiguration/detail",
  component: () =>
    import('@/views/merchantConfiguration/detail'),
  meta: {
    title: '支付商户配置详情'
  }
},
{
  // 支付配置-平台商户配置
  path: "platformConfiguration",
  name: "platformConfiguration",
  meta: {
    title: '平台商户配置'
  },
  component: () =>
    import('@/views/platformConfiguration')
},
{
  path: "platformMerchantConfigurationDetail",
  name: "platformConfiguration/edit",
  component: () =>
    import('@/views/platformConfiguration/edit'),
  meta: {
    title: '平台商户配置详情'
  }
},
{
  path: "bankAccount",
  name: "merchantConfiguration/bankAccount",
  component: () => import('@/views/bankAccount'),
  meta: {
    title: '银行开户'
  }
},
{
  path: "realName",
  name: "merchantConfiguration/realName",
  component: () => import('@/views/bankAccount/realName'),
  meta: {
    title: '实名认证'
  }
},
{
  path: "businessMessage",
  name: "merchantConfiguration/businessMessage",
  component: () => import('@/views/bankAccount/businessMessage'),
  meta: {
    title: '营业信息'
  }
},
{
  path: "accountMessage",
  name: "merchantConfiguration/accountMessage",
  component: () => import('@/views/bankAccount/accountMessage'),
  meta: {
    title: '账户信息'
  }
}
]
