export default {
  // 按钮
  button: {
    signin: "Sign in", // 登录
    search: "search", // 搜索
    reset: "reset", // 重置
    addto: "addto", // 添加
    edit: "edit", // 编辑
    preservation: "preservation", // 保存
    examine_and_verify: "examine and verify", // 审核修改
    export: "export", // 导出
    import: "import", // 导入
    Evaluate_batch_configuration: "Evaluate batch configuration", // 评价批量配置
    synchronized_berth: "synchronized berth", // 同步部位
    add_shift: "add shift", // 添加班次
    send_message: "send message", // 发送消息
    Create_Application: "Create Application", // 创建申请
    batch_import: "batch import", // 批量导入
    Configure_prepaid_fees: "Configure prepaid fees", // 配置预付费
    Configure_autonomous_parking: "Configure autonomous parking", // 配置自主停车
    set_up: "set up", // 设置
    Report_malfunction: "Report malfunction", // 上报故障
    Previous_parking_lot: "Previous parking lot", // 上一个车场
    Next_parking_lot: "Next parking lot", // 下一个车场
    Previous_Road: "Previous Road", // 上一条道路
    The_next_road: "The next road", // 下一条道路
    Clean_up_zombie_cars: "Clean up zombie cars", // 清理僵尸车
    synchronization: "synchronization", // 同步
    Open_screen: "Open screen", // 开屏
    Turn_off_the_screen: "Turn off the screen", // 关屏
    Produce_programs: "Produce programs", // 制作节目
    Delete_Program: "Delete Program", // 删除节目
    Add_materials: "Add materials", // 添加素材
    Delete_material: "Delete material", // 删除素材
    Create_Merchant: "Create Merchant", // 创建商户
    Create_configuration: "Create configuration", // 创建配置
    Create_SMS_task: "Create SMS task", // 创建短信任务
    Create_Phone_Task: "Create Phone Task", // 创建电话任务
    Large_outstanding_fee_reminder_configuration: "Large outstanding fee reminder configuration", // 大额欠费提醒配置
    Create_advertisement: "Create advertisement", // 创建广告
    Create_news: "Create news", // 创建资讯
    Create_push_notifications: "Create push notifications", // 创建推送
    Add_product: "Add product", // 添加商品
    Add_order: "Add order", // 添加订单
    Create_a_problem: "Create a problem", // 创建问题
    Already_listed: "Already listed", // 已上架
    Not_listed_yet: "Not listed yet", // 未上架
    Create_a_new_application: "Create a new application", // 创建新应用
    Merge_invoicing: "Merge invoicing", // 合并开票
    configuration_messages: "configuration messages", // 配置消息
    Mark_as_read: "Mark as read", // 标记已读
    All_read: "All read", // 全部已读
    details: "details", // 详情
    operation: "operation", // 操作
    delete: "delete", // 删除
    view: "view", // 查看
    quit: "quit", // 离职
    modify: "modify", // 修改
    Remove_from_shelves: "Remove from shelves", // 下架
    Put_on_shelves: "Put on shelves", // 上架
    reset_password: "reset password ", // 重置密码
    handle: "handle", // 处理
    to_examine: "to examine", // 审核
    Details_of_outstanding_fees: "Details of outstanding fees", // 欠费详情
    journal: "journal", // 日志
    Move_up: "Move up", // 上移
    Move_down: "Move down", // 下移
    preview: "preview", // 预览
    version_management: "version management ", // 版本管理
    Synchronize_to_official_website: "Synchronize to official website", // 同步至官网
    Add_submenu: "Add submenu", // 添加子菜单
    Disable: "Disable", // 禁用
    Stop_using: "Stop using", // 停用
    On_the_job: "On the job", // 在职
    Enable: "Enable", // 启用
    Add_Program: "Add Program", // 添加节目
    Resend: "Resend", // 重新发送
    Parking_Lot_Configuration: "Parking Lot Configuration", // 停车场配置
    QR_code_download: "QR code download", // 二维码下载
    Modify_remaining_berths: "Modify remaining berths", // 修改剩余泊位
    describe: "describe", // 描述
    View_monitoring: "View monitoring", // 查看监控
    to_configure: "to configure", // 配置
    Allocate_roads: "Allocate roads", // 分配道路
    go_online: "go online", // 上线
    Offline: "Offline", // 下线
    determine: "determine", // 确定
    cancel: "cancel", // 取消
    return: "return", // 返回
  },
  // protal页
  portal: {},
  // 左侧菜单
  menu: {},
  // 顶部导航
  nav: {},
  // 搜索模块
  searchModule: {
    Parking_card_name: "Parking card name",
  },
  // 列表
  list: {},
  // 弹窗
  pop_up: {},
  // 错误提示
  error_message: {},
  // 输入框提示
  placeholder: {
    enter_one_user_name: "enter one user name", // 请输入用户名
    Please_input_a_password: "Please input a password", // 请输入密码
    Please_enter_the_verification_code: "Please enter the verification code", // 请输入验证码
    cell_phone_number: "cell-phone number", // 手机号
    Please_enter_your_phone_number: "Please enter your phone number", // 请输入手机号
    Verification_code: "Verification code", // 校验码
    Please_enter_a_new_password: "Please enter a new password", // 请输入新密码
    Please_enter_the_new_password_again: "Please enter the new password again", // 请再次输入新密码
  },
  // 登录页
  login_page: {
    Scan_code_to_log_in: "Scan code to log in", // 扫码登录
    Account_login: "Account login", // 账号登录
    QR_code_invalid: "QR code invalid", // 二维码已失效
    Refresh_QR_code: "Refresh QR code", // 刷新二维码
    Scan_code_successfully: "Scan code successfully ！", // 扫码成功！
    confirm_login_on_your_phone:
      "Please confirm login on your phone according to the prompt message", // 请在手机上根据提示信息确认登录
    open: "open", // 打开
    aipark_management_App: "Aipark Management App", // 智慧泊车管理端APP
    Login_successful: "Login successful", // 登录成功
    automatic_logon: "automatic logon", // 自动登录
    Forgot_password: "Forgot password", // 忘记密码
    First_login_change_your_login_password:
      "First login, please change your login password (uppercase letters+lowercase letters+numbers+special characters, 8-20 characters)",
  },
};
