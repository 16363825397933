<template>
  <div class="a-park-select-wrapper">
    <el-autocomplete
      class="inline-input"
      v-model="modelvalue"
      :fetch-suggestions="querySearchAsync"
      placeholder="请输入内容"
      value-key="parkName"
      :trigger-on-focus="false"
      @select="handleSelect"
    ></el-autocomplete>
  </div>
</template>

<script>
export default {
  props: {
    instance: null,
    parkTypeRefName: {
      default: "",
    },
    operationRefName: {
      default: "",
    },
    areaRefName: {
      default: "",
    },
    slaveRelations: {
      default: "0,1,2",
    },
    dataSource: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      modelvalue: "",
      item: {},
    };
  },
  watch: {
    modelvalue: {
      handler(val) {
        if (val == "") {
          this.item = {};
        }
        //   if(){

        //   }else{

        //   }
        console.log(val, "modelvalue");
      },
    },
  },

  created() {},
  //   watch: {
  //     instance: {
  //       handler(val) {
  //         console.log(val, "888888888");
  //       },
  //       deep: true,
  //     },
  //   },
  methods: {
    clear() {
      this.modelvalue = "";
      this.item = {};
    },
    getParkId() {
      let parkId = "";
      if (JSON.stringify(this.item) != "{}" && this.item) {
        parkId = this.item.parkId;
      } else {
        parkId = "";
      }
      return parkId;
      //   return
    },
    handleSelect(item) {
      console.log(item, "item");
      this.item = item;
      this.$emit("change", item);
    },
    querySearchAsync(queryString, cb) {
      this.item = "";
      console.log(this.instance.$refs, "parkTypeSelect");
      const refs = this.instance.$refs;
      console.log(refs[this.areaRefName], "refs[this.areaRefName]");
      let areaId = "";
      if (refs[this.areaRefName]) {
        areaId = refs[this.areaRefName].getStreetId()
          ? refs[this.areaRefName].getStreetId()
          : refs[this.areaRefName].getAreaId();
      }
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 100,
            parkName: queryString,
            slaveRelations: this.slaveRelations,
            areaIds: areaId,
            operationId: refs[this.operationRefName]
              ? refs[this.operationRefName].getOperationId()
              : "",
            dataSource: refs[this.parkTypeRefName]
              ? refs[this.parkTypeRefName].getParkType()
              : this.dataSource,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            // this.parkData = res.value.list;
            // this.temParkData = res.value.list.length
            //   ? res.value.list[0]
            //   : { parkName: "", parkId: "" };
            // this.formInline.parkId = "";
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
  },
};
</script>

<style lang="stylus" rel="stylesheet/stylus"></style>
