/*
 * @Author: DZM
 * @Date: 2022-07-01 14:29:54
 * @LastEditors: lijing
 * @LastEditTime: 2022-09-01 17:12:12
 * @Description: 
 */
// 测试地址
export const nvrMediaUrl1 = "https://acb-pre.acs.aipark.com/steam/getNvrMediaUrl2";

// 郑州专线
export const nvrMediaUrl2 =
  process.env.VUE_APP_BUILD_MODE === "pre"
    ? (window.location.protocol == "http:" ? "https://acb-pre.acs.aipark.com/steam/getNvrMediaUrl2" : "https://acb-pre.acs.aipark.com/steam/getNvrMediaUrl2")
    : "/steam/getNvrMediaUrl2";
// 视频监控用户名
export const username = process.env.VUE_APP_BUILD_MODE === "pre" ? "test" : "zz_prd";

// 视频监控密钥
export const secretKey =
  process.env.VUE_APP_BUILD_MODE === "pre"
    ? "JNbDrcEN0lylP8ojAJmTDPAZq3ctLw"
    : "vAMsb3qRMLv6HyQUNcy5fs4SIht2dy";
console.log(process.env.VUE_APP_BUILD_MODE);
