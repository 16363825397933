/*
 * @Author: your name
 * @Date: 2021-02-24 16:04:48
 * @LastEditTime: 2021-03-01 17:17:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \aiparkcity-acb\src\router\modules\park-guidance.js
 */
export default [
	{
    path: 'material',
    component: () =>
      import('@/views/parkingGuidance/materialManage'),
    name: 'material',
    meta: {
      title: '素材管理'
    }
	},
	{
    path: 'addMaterial',
    component: () =>
      import('@/views/parkingGuidance/materialManage/addMaterial'),
    name: 'material/addMaterial',
    meta: {
      title: '添加/修改'
    }
	},
	{
    path: 'materialDetail',
    component: () =>
      import('@/views/parkingGuidance/materialManage/materialDetail'),
    name: 'material/materialDetail',
    meta: {
      title: '素材详情'
    }
  },
    {
        path: 'screen',
        component: () =>
        import('@/views/parkingGuidance/screenManagent'),
            name: 'screen',
            meta: {
            title: '诱导屏管理'
        }
    },
    {
        path: 'editScreenDetail',
            component: () =>
    import('@/views/parkingGuidance/screenManagent/editScreenDetail'),
        name: 'screen/editScreenDetail',
        meta: {
        title: '诱导屏编辑'
    }
    },
    {
        path: 'program',
            component: () =>
    import('@/views/parkingGuidance/videoProgramManagement'),
        name: 'program/videoProgramManagement',
        meta: {
        title: '节目管理'
    }
    },
    {
        path: 'addProgram',
            component: () =>
    import('@/views/parkingGuidance/videoProgramManagement/addDetail'),
        name: 'program/addProgram',
        meta: {
        title: '节目管理添加/修改'
    }
    }
]
