/**
 * @param {vuex instance} store
 * vuex 部分状态 持久化插件
 */

export default function statePresist(store) {
  let state = sessionStorage.getItem('vuex_state');
  if (state) {
    state = JSON.parse(state);
    store.dispatch('menu/CHANGE_MENU', state.menu.currentPortalMenu);
    store.dispatch('tabs/SET_TABS', state.tabs.currentTabs);
    store.commit('SET_USERNAME', state.username);
    store.commit('menu/SET_REFRESH_MENU', state.menu.allAuthorityMenu);
  }
  store.subscribe((mutation, state) => {
    sessionStorage.setItem('vuex_state', JSON.stringify(state));
  });
}
