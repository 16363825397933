/* eslint-disable */
import { MessageBox, Message } from "element-ui";
import qs from "qs";
import Md5Handle from "js-md5";
import axios from "./http";
import Vue from "vue";
import Router from "@/router";
import moment from "moment";
var ts = new Date().getTime();
moment.suppressDeprecationWarnings = true;
/**
 * http请求
 * opt:{
 *    method: '',   //请求方式
 *    config: {}, // 请求配置  可不传
 *    data: {}, // 参数
 *    url: '',     //请求的url
 *    success: () => {},  // 成功的回调
 *    fail: () => {} // 失败的回调  可不传
 * }
 */
function weDateFormat(date, strDate) {
  let weekOfDay = moment(date).format("E"); // 指定日期的周的第几天

  let lastMonday = moment(date)
    .subtract(weekOfDay - 1, "days")
    .format("YYYY-MM-DD"); // 周一日期
  let lastTuesday = moment(date)
    .subtract(weekOfDay - 2, "days")
    .format("YYYY-MM-DD"); // 周二日期
  let lastWednesday = moment(date)
    .subtract(weekOfDay - 3, "days")
    .format("YYYY-MM-DD"); // 周三日期
  let lastThursday = moment(date)
    .subtract(weekOfDay - 4, "days")
    .format("YYYY-MM-DD"); // 周四日期
  let lastFriday = moment(date)
    .subtract(weekOfDay - 5, "days")
    .format("YYYY-MM-DD"); // 周五日期
  let lastSaturday = moment(date)
    .subtract(weekOfDay - 6, "days")
    .format("YYYY-MM-DD"); // 周六日期
  let lastSunday = moment(date)
    .add(7 - weekOfDay, "days")
    .format("YYYY-MM-DD"); // 周日日期
  return {
    lastMonday,
    lastTuesday,
    lastWednesday,
    lastThursday,
    lastFriday,
    lastSaturday,
    lastSunday,
  };
}
export function formatWeekDate(date) {
  let val = `${moment(weDateFormat(date).lastSunday).format("YYYY-MM-DD")}`;
  let nowT = moment(new Date()).format("YYYY-MM-DD");
  let isBefore = moment(nowT).isBefore(val);
  if (isBefore) {
    return `${date} - ${nowT}`;
  } else {
    return `${date} - ${val}`;
  }
}

function getDuration(second) {
  if (second && second != 0) {
    var duration = 0;
    var days = Math.floor(second / 86400);
    var hours = Math.floor((second % 86400) / 3600);
    var minutes = Math.floor(((second % 86400) % 3600) / 60);
    var seconds = Math.floor(((second % 86400) % 3600) % 60);
    if (days > 0) duration = days + "天" + hours + "时" + minutes + "分" + seconds + "秒";
    else if (hours > 0) duration = hours + "时" + minutes + "分" + seconds + "秒";
    else if (minutes > 0) duration = minutes + "分" + seconds + "秒";
    else if (seconds > 0) duration = seconds + "秒";
    return duration;
  } else {
    return "0秒";
  }
}

// 根据传入的时间计算流逝的时间
export function formatDuring(mss, timeType) {
  return getDuration(mss);

  // let x = 14400; // 单位是秒
  let time = moment.duration(mss, timeType);
  let days = "00天";
  let hours = "00时";
  let minutes = "00分";
  let seconds = "00秒";
  if (time.days()) {
    if (time.days() > 9) {
      days = `${time.days()}天`;
    } else {
      days = `0${time.days()}天`;
    }
  }
  if (time.hours()) {
    console.log(time.hours(), "8888");
    if (time.hours() > 9) {
      hours = `${time.hours()}时`;
    } else {
      hours = `0${time.hours()}时`;
    }
  }
  if (time.minutes()) {
    if (time.minutes() > 9) {
      minutes = `${time.minutes()}分`;
    } else {
      minutes = `0${time.minutes()}分`;
    }
  }
  if (time.seconds()) {
    if (time.seconds() > 9) {
      seconds = `${time.seconds()}秒`;
    } else {
      seconds = `0${time.seconds()}秒`;
    }
  }
  // let arr = [days, hours, minutes, seconds];
  if (days == "00天") {
    days = "";
  }
  if (hours == "00时") {
    if (days == "") {
      hours = "";
    }
  }
  if (minutes == "00分") {
    if (hours == "") {
      minutes = "";
    }
  }

  return `${days}${hours}${minutes}${seconds}`;
}

export function request(opt) {
  let data = {};
  if (opt.method == "get") {
    data = {
      data: opt.data,
      ...opt.config,
    };
  } else {
    data = {
      data: opt.data,
    };
  }
  axios[opt.method](opt.url, data, opt.config ? opt.config : {}).then(
    (res) => {
      opt.success(res);
    },
    (res) => {
      // console.log('res', res)
      if (opt.fail) {
        opt.fail(res);
      } else {
        // MessageBox.alert(res.desc || '请求失败，请稍后重试！', '提示', {
        //   confirmButtonText: '确定'
        // });
      }
    }
  );
}
export function TimeAll(params, isContainToday = true) {
  const formatTemplate = "YYYY-MM-DD";
  // 所有的时间段都是到昨天为止
  const now = moment();
  const endDate = isContainToday ? now : now.subtract(1, "day");
  let startDate;
  if (params != 16 && params > 4) {
    const monthTemplate = "YYYY-MM";
    let monthArray = [
      "0",
      "1",
      "2",
      "3",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
    ];
    const diff = monthArray[params];
    startDate = moment(endDate).subtract(Number(diff), "month");
    return [startDate.format(monthTemplate), endDate.format(monthTemplate), params];
  } else {
    if (params == 16) {
      // 目前已经删除了"全部"标签，暂时保留逻辑
      return ["", "", params];
    } else {
      const dayArray = ["", "0", "1", "7", "30"];
      const diff = dayArray[params];
      // 目前已经删除了"今天"标签，暂时保留逻辑
      if (diff == 0) {
        return [now.format(formatTemplate), now.format(formatTemplate), params];
      }
      startDate = moment(endDate).subtract(Number(diff) - 1, "day");
      return [startDate.format(formatTemplate), endDate.format(formatTemplate), params];
    }
  }
}
export function dateFormat(date, fmt) {
  if (!(date instanceof Date)) {
    return "";
  }
  fmt = fmt || "yyyy-MM-dd HH:mm:ss";
  let o = {
    // 月份
    "M+": date.getMonth() + 1,
    // 日
    "d+": date.getDate(),
    // 小时
    "H+": date.getHours(),
    // 分
    "m+": date.getMinutes(),
    // 秒
    "s+": date.getSeconds(),
    // 季度
    "q+": Math.floor((date.getMonth() + 3) / 3),
    // 毫秒
    S: date.getMilliseconds(),
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  for (let k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
}
// 输入金额格式化限制
export function onlyNumber(value) {
  value = value.replace(/[^\d.]/g, "");
  value = value.replace(/^\./g, "");
  value = value.replace(/\.{2,}/g, ".");
  value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
  value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
  return value;
}
// 金钱格式化
export function formatNum(val) {
  val = val + "";
  if (val != parseInt(val)) {
    var back = val.split(".")[1];
    var before = val.split(".")[0];
  } else {
    before = val;
  }
  var count = 0;
  var arr = before.split("");
  var len = arr.length;
  arr.reverse();
  for (var i = 0; i < len; i++) {
    count++;
    if (count % 3 == 0 && arr[i + 1]) {
      arr[i] = "," + arr[i];
    }
  }
  arr.reverse();
  if (back) {
    val = arr.join("") + "." + back;
  } else {
    val = arr.join("") + ".00";
  }
  return val;
}

// ajax 导出
export function handleExport(res) {
  let b = res.data;
  let reader = new FileReader();
  reader.addEventListener("loadend", () => {
    let data = {};
    try {
      data = JSON.parse(reader.result);
      if (data.state == 2) {
        sessionStorage.toLogin = 0;
        MessageBox.alert("登录超时，请重新登录！", "提示", {
          confirmButtonText: "确定",
        }).then(() => {
          Router.push("/");
        });
      } else {
        let hint = data.desc !== "成功" ? data.desc : "请在下载中心下载";
        MessageBox.alert(hint || "请求失败！", "提示", {
          confirmButtonText: "确定",
        });
      }
    } catch (e) {
      if (res.headers["content-disposition"]) {
        sessionStorage.setItem(
          "filename",
          decodeURI(res.headers["content-disposition"].split("=")[1])
        );
        let blob = res.data;
        let filename = sessionStorage.getItem("filename") || "download.xls";
        let a = document.createElement("a");
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        let body = document.getElementsByTagName("body")[0];
        body.appendChild(a);
        a.click();
        body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }
    }
  });
  reader.readAsText(b);
}
// md5 加密
export function jsMd5(account, pwd) {
  return Md5Handle(account + pwd);
}

export function exportExcelNew(url, opt, method, hint) {
  method = method || "post";
  axios[method](
    url,
    {
      data: opt,
    },
    {
      responseType: "blob",
    }
  ).then((res) => {
    // if (hint.length > 0) {
    //   MessageBox.alert(hint, '提示', {
    //     confirmButtonText: '确定'
    //   });
    // }
  });
}
// 导出
export function exportExcel(url, opt) {
  // opt.sessionId = sessionStorage.token;
  axios.headers.common["Session-Id"] = sessionStorage.token;
  axios
    .get(url + "?" + qs.stringify(opt))
    .then((res) => {
      if (res.body && res.body.state) {
        if (res.body.state == "2" || res.body.state == "5") {
          if (sessionStorage.toLogin == "1") {
            MessageBox.confirm("登录失效，请重新登录", "提示", {
              confirmButtonText: "确定",
              showCancelButton: false,
            })
              .then(() => {
                sessionStorage.toLogin = 0;
                Router.push("/");
              })
              .catch(() => {
                Router.push("/");
              });
          }
        } else {
          MessageBox.alert(res.body.desc, "提示", {
            confirmButtonText: "确定",
          });
        }
      } else {
        // 正常下载
        let form = document.createElement("form"); // 定义一个form表单
        form.style.display = "none"; // 在form表单中添加查询参数
        form.target = "";
        form.method = "get";
        form.action = url;
        for (let i in opt) {
          let input = document.createElement("input");
          input.type = "hidden";
          input.name = i;
          input.value = opt[i];
          form.append(input);
        }
        let input4 = document.createElement("input");
        input4.type = "hidden";
        input4.name = "sessionId";
        input4.value = sessionStorage.token;
        document.getElementsByTagName("body")[0].append(form); // 将表单放置在web中
        form.append(input4);
        form.submit(); // 表单提交
        form.remove();
      }
    })
    .catch((err) => {
      if (err.toString().indexOf("911") != -1) {
        if (sessionStorage.toLogin == "1") {
          MessageBox.confirm("登录失效，请重新登录", "提示", {
            confirmButtonText: "确定",
            showCancelButton: false,
          })
            .then(() => {
              sessionStorage.toLogin = 0;
              Router.push("/");
            })
            .catch(() => {
              Router.push("/");
            });
        }
      } else if (err.toString().indexOf("timeout") != -1) {
        MessageBox.confirm("当前网络不稳定，请稍候重试", "提示", {
          confirmButtonText: "确定",
          showCancelButton: false,
        })
          .then(() => {
            Router.push("/");
          })
          .catch(() => {
            Router.push("/");
          });
      }
    });
}
// 翻页记录条目
export function setIndex(page, arr) {
  arr.forEach((value, index) => {
    value.index = (page - 1) * 15 + index + 1;
  });
  return arr;
}
export function setAlreadyAu(pageName, authority) {
  if (authority) {
    if (sessionStorage.authorityPage) {
      let au = JSON.parse(sessionStorage.authorityPage);
      au[pageName] = authority;
      sessionStorage.authorityPage = JSON.stringify(au);
    } else {
      sessionStorage.authorityPage = JSON.stringify({
        [pageName]: authority,
      });
    }
    return authority;
  } else {
    let au = JSON.parse(sessionStorage.authorityPage);
    return au[pageName];
  }
}
/**
 *
 * @param {
 * start 开始日期
 * end 结束日期
 * dateType 日期类型 支持 月份 天数
 * dayLimit 日期时长限制
 * monthLimit 月份长度限制
 * } param0
 */
export function checkDate({ start, end, dateType, dayLimit, monthLimit, dayTip, monthTip }) {
  if (dateType == 1) {
    if (moment(end).diff(start, "days") > dayLimit - 1) {
      MessageBox.alert(dayTip || "搜索时间不能超过30天", "提示");
      return false;
    }
  } else {
    if (moment(end).diff(start, "months") > monthLimit - 1) {
      MessageBox.alert(monthTip || "搜索月份不能超过12个月", "提示");
      return false;
    }
  }

  return true;
}
export function moneyTofix(money, unit = true) {
  if (money == 0 || !money) {
    return unit ? "0元" : "0";
  } else {
    return formatNum(
      unit ? Math.round((money / 100) * 100) / 100 + "元" : Math.round((money / 100) * 100) / 100
    );
  }
}

export function confirmFn(options) {
  /**
   * options.tipText 询问文字
   * options.sucText 成功时的文字
   * options.sucFn 成功回调
   * options.cancText 取消时的文字
   * options.cancFn 取消时的回调
   */
  MessageBox.confirm(options.tipText || "是否执行该操作？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      options.sucFn && options.sucFn();
      Message({
        type: "success",
        message: options.sucText || "操作成功!",
      });
    })
    .catch(() => {
      Message({
        type: "info",
        message: options.cancText || "已取消操作！",
      });
    });
}

// 图片放大
export function showBigImage(url) {
  // // 创建图片
  var imgObj = new Image();
  imgObj.src = url;

  // let bodyW = window.screen.availWidth;
  // let bodyH = window.screen.availHeight;
  let bodyW = document.body.clientWidth;
  let bodyH = document.body.clientHeight;
  // let w = 0;
  // let h = 0;
  let size = 0;
  // 创建图片父级
  let div = document.createElement("div");
  div.setAttribute(
    "style",
    "cursor: pointer; display: none;position: fixed; left: 0;top: 0;right: 0;bottom: 0;background:rgba(0,0,0,0.5);z-index: 9999"
  );
  div.style.width = bodyW + "px";
  div.style.height = bodyH + "px";
  if (typeof url !== "function" && url.indexOf("http") === 0) {
    div.style.display = "block";
  }
  div.appendChild(imgObj);
  document.getElementsByTagName("body")[0].appendChild(div);
  // 图片加载成功之后
  imgObj.onload = function () {
    imgObj.setAttribute("style", `position: relative;`);
    var iw = imgObj.width;
    var ih = imgObj.height;
    var dw = div.style.width.split("p")[0];
    var dh = div.style.height.split("p")[0];
    if (iw >= ih) {
      var nih = (ih / iw) * dw;
      if (nih > dh) {
        this.style.height = dh + "px";
        this.style.left = (dw - (iw / ih) * dh) / 2 + "px";
      } else {
        this.style.width = dw + "px";
        this.style.top = (dh - nih) / 2 + "px";
      }
    } else {
      var niw = (dh / ih) * iw;
      this.style.height = dh + "px";
      this.style.left = (dw - niw) / 2 + "px";
    }
  };

  // 图片的滚动放大
  function mWheel(e) {
    let ev = e || window.event;
    // let dir = ev.deltaY;
    let dir = ev.detail ? ev.detail * -120 : ev.wheelDelta;
    // dir = -dir;
    if (dir > 0) {
      size += dir / 1000;
      imgObj.style.transform = `scale(${1 + size})`;
      // imgObj.style.transform = 'scale('+Number(1 + size )+')';
    } else {
      size += dir / 1000;
      if (size < -1) {
        size = -1;
        imgObj.style.transform = `scale(${1 + size})`;
        return;
      }
      imgObj.style.transform = `scale(${1 + size})`;
    }
  }

  // 判断浏览器是否支持滚动事件
  var mousewheelevt = /Firefox/i.test(navigator.userAgent) ? "DOMMouseScroll" : "mousewheel"; // FF doesn't recognize mousewheel as of FF3.x
  // 调用图片放大
  if (div.attachEvent) {
    // if IE (and Opera depending on user setting)
    div.attachEvent("on" + mousewheelevt, mWheel);
  } else if (div.addEventListener) {
    // WC3 browsers
    div.addEventListener(mousewheelevt, mWheel, false);
  }

  // 拖动图片
  div.onmousedown = function (e) {
    var flagInner = true;
    var ev = e || window.event;

    var mPageX = ev.pageX;
    var mPageY = ev.pageY;

    var disX = ev.pageX - imgObj.offsetLeft;
    var disY = ev.pageY - imgObj.offsetTop;
    this.onmousemove = function (e) {
      ev = e || window.event;
      var mPageX2 = ev.pageX;
      var mPageY2 = ev.pageY;
      if (
        mPageX2 - mPageX > 5 ||
        mPageY2 - mPageY > 5 ||
        mPageX - mPageX2 > 5 ||
        mPageY - mPageY2 > 5
      ) {
        flagInner = false;
      }
      ev.preventDefault();
      var moveX = ev.pageX - disX;
      var moveY = ev.pageY - disY;

      imgObj.style.left = moveX + "px";
      imgObj.style.top = moveY + "px";
    };
    this.onmouseup = function (e) {
      if (flagInner) {
        if (this.isIE || this.isIE11) {
          this.removeNode(true);
        } else {
          this.remove();
        }
        this.isIE = function isIE() {
          if (!!window.ActiveXObject || "ActiveXObject" in window) {
            return true;
          } else {
            return false;
          }
        };
        this.isIE11 = function isIE11() {
          if (/Trident\/7\./.test(navigator.userAgent)) {
            return true;
          } else {
            return false;
          }
        };
      }
      this.onmousemove = null;
      this.onmouseup = null;
    };
    // disable default wheel action of scrolling page
    if (ev.preventDefault) {
      ev.preventDefault();
    } else {
      return false;
    }
  };
}

export function getDt(opt) {
  Vue.http.headers.common["Session-Id"] = sessionStorage.token;
  // 避免清缓存
  sessionStorage.toLogin = !sessionStorage.toLogin ? true : sessionStorage.toLogin;
  // let http = 'http://10.100.16.124:8085'	// 关键
  // let http = 'http://10.100.16.199:8085' // 关鹰
  // let http = 'http://lhb:8090' // 李红彪
  if (opt.method == "get") {
    Vue.http({
      method: "get",
      url: opt.url + "?" + qs.stringify(opt.data) + "&t=" + ts,
      emulateJSON: true,
      headers: {
        "Session-Id": sessionStorage.token,
      },
    })
      .then((res) => {
        res.body = res.body || JSON.parse(res.bodyText);
        if (res.body.state == "2" || res.body.state == "5") {
          if (sessionStorage.toLogin == "true") {
            MessageBox.confirm("登录失效，请重新登录", "提示", {
              confirmButtonText: "确定",
              showCancelButton: false,
            })
              .then(() => {
                sessionStorage.toLogin = false;
                Router.push("/");
              })
              .catch(() => {
                Router.push("/");
              });
          }
        } else {
          opt.success(res.body);
        }
      })
      .catch((err) => {
        if (err.toString().indexOf("911") != -1) {
          if (sessionStorage.toLogin == "true") {
            MessageBox.confirm("登录失效，请重新登录", "提示", {
              confirmButtonText: "确定",
              showCancelButton: false,
            })
              .then(() => {
                sessionStorage.toLogin = false;
                Router.push("/");
              })
              .catch(() => {
                Router.push("/");
              });
          }
        } else if (err.toString().indexOf("timeout") != -1) {
          MessageBox.confirm("当前网络不稳定，请稍候重试", "提示", {
            confirmButtonText: "确定",
            showCancelButton: false,
          })
            .then(() => {
              Router.push("/");
            })
            .catch(() => {
              Router.push("/");
            });
        }
      });
  } else {
    Vue.http[opt.method](opt.url, opt.data)
      .then((res) => {
        res.body = res.body || JSON.parse(res.bodyText);
        if (res.body.state == "2") {
          if (sessionStorage.toLogin == "true") {
            MessageBox.confirm("登录失效，请重新登录", "提示", {
              confirmButtonText: "确定",
              showCancelButton: false,
            })
              .then(() => {
                sessionStorage.toLogin = false;
                Router.push("/");
              })
              .catch(() => {
                Router.push("/");
              });
          }
        } else {
          opt.success(res.body);
        }
      })
      .catch((err) => {
        if (err.toString().indexOf("911") != -1) {
          if (sessionStorage.toLogin == "true") {
            MessageBox.confirm("登录失效，请重新登录", "提示", {
              confirmButtonText: "确定",
              showCancelButton: false,
            })
              .then(() => {
                sessionStorage.toLogin = false;
                Router.push("/");
              })
              .catch(() => {
                Router.push("/");
              });
          }
        } else if (err.toString().indexOf("timeout") != -1) {
          MessageBox.confirm("当前网络不稳定，请稍候重试", "提示", {
            confirmButtonText: "确定",
            showCancelButton: false,
          })
            .then(() => {
              Router.push("/");
            })
            .catch(() => {
              Router.push("/");
            });
        }
      });
  }
}
export function getLately31Day() {
  let date = new Date();
  let startDate = null;
  let endDate = null;
  let lately31Day = new Date(date.getTime() - 3600 * 1000 * 24);
  let yesterday = new Date(date.getTime() - 3600 * 1000 * 24 * 31);
  startDate = dateFormat(yesterday, "yyyy-MM-dd");
  endDate = dateFormat(lately31Day, "yyyy-MM-dd");
  return {
    startDate,
    endDate,
  };
}
export function formatterParkCount(val) {
  let value = val + "";
  let length = value.length;
  if (length > 5 && length <= 8) {
    let arr = value.split("");
    arr.splice(arr.length - 4, 0, ".");
    value = (+arr.join("")).toFixed(2) + "万";
  } else if (length > 8) {
    let arr = value.split("");
    arr.splice(arr.length - 8, 0, ".");
    value = (+arr.join("")).toFixed(2) + "亿";
  } else {
    value;
  }
  return value;
}
/*
 * 截取地址参数
 */
export const getQueryVariable = (variable) => {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return "";
};
