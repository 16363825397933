function getAu(authority, keyString) {
  let keys = keyString.split(".");
  return keys.reduce((subAu, key) => {
    return subAu[key];
  }, authority);
}
// v-if="$route.meta.authority.button.detail"
function handleDirective(authority, keyString) {
  let permission = getAu(authority, keyString);
  return !!permission;
}

function handleArrayAuthority(authority, permission) {
  let au = [];
  permission.forEach(a => {
    au.push(handleDirective(authority, a))
  })
  return au.some(a => a)
}

const AuthorityComponent = {
  functional: true,
  render: function (h, context) {
    let {
      ComponentName,
      permission
    } = context.props;
    let varType = typeof permission;
    if (permission && varType !== 'string' && !(permission instanceof Array)) {
      throw new TypeError('expect string or array but got a ' + typeof varType)
    }
    let authority = context.parent.$route.meta.authority || {};
    let per = true;
    if (permission instanceof Array) {
      per = handleArrayAuthority(authority, permission);
    } else if (varType === 'string') {
      per = handleDirective(authority, permission);
    }
    return per && h(ComponentName, context.data, context.children);
  }
};
export default {
  install(Vue) {
    Vue.component('AuthorityComponent', AuthorityComponent);
  }
}
