export default [{
  // 商户管理-商户管理
  path: 'mechantInfoManage',
  name: 'mechantInfoManage',
  meta: {
    title: '商户信息'
  },
  component: () =>
    import('@/views/operatorManage/operatorManage/operatorManage'),
},
{
  // 商户管理-商户管理编辑
  path: 'MerchantManagerAE',
  name: 'mechantInfoManage/operatorManage-AE',
  meta: {
    title: '商户管理编辑'
  },
  component: () =>
    import('@/views/operatorManage/operatorManage/operatorManage-AE'),
},
{
  // 商户管理-商户管理详情
  path: 'operatorManageDetail',
  name: 'mechantInfoManage/operatorManageDetail',
  meta: {
    title: '商户管理详情'
  },
  component: () =>
    import('@/views/operatorManage/operatorManage/operatorManageDetail'),
},
{
  // 商户管理-发票配置列表
  path: 'invoiceManage',
  name: 'invoiceManage',
  meta: {
    title: '发票配置'
  },
  component: () =>
    import('@/views/operatorManage/operatorManage/invoiceManage'),
},
{
  // 商户管理-发票配置编辑
  path: 'invoiceManage-AE',
  name: 'invoiceManage/invoiceManage-AE',
  meta: {
    title: '配置编辑'
  },
  component: () =>
    import('@/views/operatorManage/operatorManage/invoiceManage-AE'),
},
{
  // 商户管理-发票配置详情
  path: 'invoiceManageDetail',
  name: 'invoiceManage/invoiceManageDetail',
  meta: {
    title: '配置详情'
  },
  component: () =>
    import('@/views/operatorManage/operatorManage/invoiceManageDetail'),
},
{
  // 商户管理-商户功能管理
  path: 'operatorFunctionManage',
  name: 'operatorFunctionManage',
  meta: {
    title: '商户功能管理'
  },
  component: () =>
    import('@/views/operatorManage/operatorFunctionManage/operatorFunctionManage')
},
{
  // 商户管理-商户功能管理添加和编辑
  path: 'operatorFunctionManageAE',
  name: 'operatorFunctionManage/operatorFunctionManageAE',
  meta: {
    title: '商户功能管理添加和编辑'
  },
  component: () =>
    import('@/views/operatorManage/operatorFunctionManage/operatorFunctionManage-AE')
},
{
  // 商户管理-商户管理员管理
  path: 'operatorAdministratorsManage',
  name: 'operatorAdministratorsManage',
  meta: {
    title: '商户管理员管理'
  },
  component: () =>
    import(
      '@/views/operatorManage/operatorAdministratorsManage/operatorAdministratorsManage'
    )
},
{
  // 商户管理-修改商户信息
  path: 'operatorAdministratorsManageAE',
  name: 'operatorAdministratorsManage/operatorAdministratorsManageAE',
  meta: {
    title: '修改商户信息'
  },
  component: () =>
    import(
      '@/views/operatorManage/operatorAdministratorsManage/operatorAdministratorsManage-AE'
    )
},
{
  // 商户管理-查看商户信息
  path: 'operatorAdministratorsManageDetail',
  name: 'operatorAdministratorsManage/operatorAdministratorsManageDetail',
  meta: {
    title: '查看商户信息'
  },
  component: () =>
    import(
      '@/views/operatorManage/operatorAdministratorsManage/operatorAdministratorsManageDetail'
    )
},
{
  // 商家管理-商家管理员管理
  path: 'storeManagerManage',
  name: 'storeManagerManage',
  meta: {
    title: '商家管理员'
  },
  component: () =>
    import(
      '@/views/businessManagement/merchantAdministrator/merchantAdministrator'
    )
},
{
  // 商家管理-修改商家管理员信息
  path: 'storeAdministratorsManageAE',
  name: 'storeManagerManage/storeAdministratorsManageAE',
  meta: {
    title: '修改商家管理员信息'
  },
  component: () =>
    import(
      '@/views/businessManagement/merchantAdministrator/operatorAdministratorsManage-AE'
    )
},
{
  // 商家管理-查看商家管理
  path: 'storeAdministratorsManageDetail',
  name: 'storeManagerManage/storeAdministratorsManageDetail',
  meta: {
    title: '查看商家管理'
  },
  component: () =>
    import(
      '@/views/businessManagement/merchantAdministrator/operatorAdministratorsManageDetail'
    )
},
// -----------------------
{
  // 商家管理-商家信息
  path: 'storeManage',
  name: 'storeManage',
  meta: {
    title: '商家信息'
  },
  component: () =>
    import(
      '@/views/businessManagement/businessInformation/businessInformation'
    )
},
{
  // 商家管理-商家信息编辑
  path: 'storeManagerAE',
  name: 'storeManage/operatorManage-AE',
  meta: {
    title: '商家信息编辑'
  },
  component: () =>
    import('@/views/businessManagement/businessInformation/operatorManage-AE'),
},
{
  // 商家管理-商家余额
  path: 'balanceDetails',
  name: 'storeManage/operatorManage-AE/balanceDetails | storeManage/storeManageDetail/balanceDetails',
  meta: {
    title: '商家信息编辑'
  },
  component: () =>
    import('@/views/businessManagement/businessInformation/balanceDetails'),
},
{
  // 商家管理-商家信息详情
  path: 'storeManageDetail',
  name: 'storeManage/storeManageDetail',
  meta: {
    title: '商家信息详情'
  },
  component: () =>
    import('@/views/businessManagement/businessInformation/operatorManageDetail'),
},
{
  // 商家管理-商家管理编辑
  path: 'storeManagerAE',
  name: 'storeManage/storeManagerAE',
  meta: {
    title: '商户管理编辑'
  },
  component: () =>
    import('@/views/businessManagement/businessInformation/operatorManage-AE'),
},
{
  // 小票配置-list
  path: 'pdaTicketConfig',
  name: 'pdaTicketConfig',
  meta: {
    title: '小票配置'
  },
  component: () =>
    import('@/views/operatorManage/pdaTicketConfig/index')
},
{
  // 商家管理-查看商家管理
  path: 'pdaTicketConfigDetail',
  name: 'pdaTicketConfig/pdaTicketConfigDetail',
  meta: {
    title: '小票配置编辑'
  },
  component: () =>
    import(
      '@/views/operatorManage/pdaTicketConfig/pdaTicketConfigDetail'
    )
},
{
  // 商家管理-查看商家管理
  path: 'pdaTicketConfigDetailSee',
  name: 'pdaTicketConfig/pdaTicketConfigDetailSee',
  meta: {
    title: '小票配置查看'
  },
  component: () =>
    import(
      '@/views/operatorManage/pdaTicketConfig/pdaTicketConfigDetailSee'
    )
},
]
