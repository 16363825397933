/*
 * @Author: DZM
 * @Date: 2022-03-25 16:57:20
 * @LastEditors: lijing
 * @LastEditTime: 2022-08-22 18:06:58
 * @Description: 
 */
import axios from '../../http';
import { dateFormat } from '../../public';
const rightSideApi = {
  /**
   * @description: 车场总数
   * @return {*}
   */
   queryMemberAdviseByType(params) {
      return axios.post('/acb/2.0/dataAnalysisOverView/queryMemberAdviseByType', {
        data: params
      })
    },
  /**
   * @description: 车场服务评价
   * @return {*}
   */  
   queryParkEvaluationByScore(params) {
      return axios.post('/acb/2.0/dataAnalysisOverView/queryParkEvaluationByScore', {
        data: params
      })
    },
    /**
     * @description: 车辆进出场信息
     * @return {*}
     */    
    parkRecordScroll(params) {
       return axios.get('/acb/2.0/dataAnalysisOverView/parkRecordScroll', {
         data: params
       })
    },
    /**
     * @description: 停车收入统计
     * @return {*}
     */    
     parkIncomeTrend(params) {
       return axios.get('/acb/2.0/dataAnalysisTrend/parkIncomeTrend', {
         data: params
       })
    },
    /**
     * @description: 停车收入统计java
     * @return {*}
     */    
    javaParkIncomeTrend(params) {
      return axios.get('/acb/2.0/aceParkAnalysis/parkIncomeTrend', {
        data: params
      })
   },
    // ----------------------------------------------------------------------------新页面接口
    /**
     * @description: 平均泊位收入、客单价
     * @return {*}
     */    
     bigavgBerthIncome(params) {
      return axios.get('/acb/2.0/bigScreen/map/avgBerthIncome', {
        data: params
      })
    },
    /**
     * @description: 平均泊位收入、客单价java
     * @return {*}
     */    
    javaBigavgBerthIncome(params) {
      return axios.get('/acb/2.0/aceParkAnalysis/parkShouldPay', {
        data: params
      })
    },
   /**
    * @description: 周转率、利用率
    * @return {*}
    */    
    parkUsingRatio(params) {
     return axios.get('/acb/2.0/bigScreen/map/parkUsingRatio', {
       data: params
     })
  },
  /**
    * @description: 周转率
    * @return {*}
    */    
  javaBerthTurnoverRatio(params) {
    return axios.get('/2.0/aceBerth/homePageBerthStatics', {
      data: params
    })
  },
  /**
    * @description: 利用率
    * @return {*}
    */    
  javaBerthUtilizationRatio(params) {
    return axios.get('/acb/2.0/aceBerth/berthUtilizationRatio', {
      data: params
    })
  },
  /**
   * @description: 车场服务评价
   * @return {*}
   */    
   parkServiceNum(params) {
    return axios.get('/acb/2.0/bigScreen/map/parkServiceNum', {
      data: params
    })
  },
  /**
   * @description: 服务车次数
   * @return {*}
   */    
  javaParkingService(params) {
    return axios.get('/acb/2.0/parkingStatistics/parkingService', {
      data: params
    })
  },
  /**
   * @description: 车场收入
   * @return {*}
   */    
  javaParkIncome(params) {
    return axios.get('/acb/2.0/aceParkAnalysis/singleParkIncomeTrend', {
      data: params
    })
  },
 /**
  * @description: 车场收入分析
  * @return {*}
  */    
  parkIncome(params) {
   return axios.get('/acb/2.0/bigScreen/map/parkIncome', {
     data: params
   })
},
}
export default rightSideApi
