<!--
 * @Author: faf
 * @desc:
 * @Date: 2022-02-08 09:39:37
 * @LastEditors: faf
 * @ LastEditTime:
-->
<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>
export default {
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
#app
  width: 100%;
  height: 100%;
  overflow-y: overlay;
</style>
