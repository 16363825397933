import { pathToRegexp } from "path-to-regexp";
import store from "@/store";
import moment from "moment";
import { MessageBox } from "element-ui";

export function portalHasPage(path) {
  let portalMenu = store.state.menu.currentPortalMenu;
  return portalMenu.some((r) => {
    let rP = "/" + r.webUri;
    return rP === path;
  });
}

export function findArrEle(arr, attrName, comVal) {
  return arr.some((v) => {
    if (comVal) {
      return v[attrName] === comVal;
    } else {
      return v === comVal;
    }
  });
}

export function getAuthorityFirstMenu(menu) {
  return menu.reduce((mock, item) => {
    if (item.type === 0) {
      mock.push({
        code: item.code,
        desc: item.desc,
        parentCode: item.parentCode,
        webUri: item.webUri,
        type: item.type,
      });
    }
    return mock;
  }, []);
}

export function findPortalMenu(menu, path) {
  return menu[path];
}

function setButAndTab(obj, arr) {
  arr.forEach((ele) => {
    if (ele.type == 2) {
      obj.button[ele.function] = 1;
      if (ele.children) {
        obj.subPage[ele.function] = {
          button: {},
          subPage: {},
          tabs: {},
        };
        setButAndTab(obj.subPage[ele.function], ele.children);
      }
    } else if (ele.type == 1) {
      obj.tabs[ele.code] = {
        button: {},
        subPage: {},
        tabs: {},
      };
      if (ele.children) {
        setButAndTab(obj.tabs[ele.code], ele.children);
      }
    }
  });
}

export function splitButtonAndMenu(menu = [], button) {
  let menuList = [];
  button = button || {};
  menu.forEach((ele) => {
    menuList.push(ele);
    let obj = {
      button: {},
      subPage: {},
      tabs: {},
    };
    button[ele.webUri] = obj;
    if (ele.children && ele.children[0] && ele.children[0].type !== 0) {
      setButAndTab(obj, ele.children);
      ele.children = [];
    } else {
      let { menuList } = splitButtonAndMenu(ele.children, button);
      ele.children = menuList;
    }
  });
  return {
    menuList,
    button,
  };
}

export function filterAuMenu(menuList, target) {
  return menuList.filter((m) => m.path == target);
}

export function flattenDeep(arr, res = []) {
  arr.forEach((item) => {
    if (item.children && item.children.length) {
      flattenDeep(item.children, res);
    } else {
      res.push(item);
    }
  });
  return res;
}

export function ifDupl(list, path) {
  return list.filter((v) => v.path === path)[0];
}

export function setRelativePath(routes, parentPath = "") {
  routes.forEach((route) => {
    route.meta = route.meta ? route.meta : {};
    let relativePath = parentPath + route.path;
    route.meta.relativePath = relativePath;
    if (route.children && route.children.length > 0) {
      setRelativePath(route.children, relativePath);
    }
  });
}

// 用于获取 根父页面路径
export function getPath(path) {
  let pathArr = path.split("|");
  let res = null;
  let i = 0;
  while (!res && i < pathArr.length) {
    path = pathArr[i++];
    path = path[0] === "/" ? path : "/" + path;
    let matched = path.match(/(\/?\w+)/);
    res = matched && matched[1];
  }
  return res;
}

export function pathAuInclude(webUri, name) {
  let result = false;
  name = name.split("|");
  let i = 0;
  while (i < name.length && !result) {
    if (pathToRegexp(webUri + "/:args*").exec(name[i++])) {
      result = true;
    }
  }
  return result;
}

export function ifInclude(source, target) {
  source = source.split("|");
  target = target.split("|");
  source = source.length > 1 ? source : source[0];
  target = target.length > 1 ? target : target[0];
  let result = false;
  if (typeof source === "string") {
    return source.indexOf(target) > -1;
  } else if (Array.isArray(source)) {
    for (let i = 0; i < source.length; i++) {
      result = source[i].indexOf(target);
      if (result > -1) {
        return true;
      }
    }
  } else if (Array.isArray(target)) {
    let result = -1;
    for (let i = 0; i < target.length; i++) {
      result = source.indexOf(target[i]);
      if (result > -1) {
        return true;
      }
    }
  }
}

export function handleResource(oldSource) {
  let arrAuthority = [];
  let authorityMenu = {};
  let allAuButton = {};
  for (let i in oldSource) {
    let { menuList, button } = splitButtonAndMenu(oldSource[i]);
    authorityMenu[i] = menuList;
    allAuButton = {
      ...allAuButton,
      ...button,
    };
    arrAuthority = arrAuthority.concat(menuList);
  }
  return {
    arrAuthority,
    allAuButton,
    authorityMenu,
  };
}

function padLeftZero(str) {
  return ("00" + str).substr(str.length);
}

// 获取0点或者59分时间（fmt:时间格式, day: 0当天 1明天  -1昨天... ,isZero：true 获取0点时间 false：23:59:59）
// 例：（'yyyy-MM-dd HH:mm:ss', -7 , false)=> 2020-10-28 59:59:59
export function getZeroOrLastDateTime(fmt, day = 0, isZero = true) {
  const zero = 3600 * 1000 * 24 * day;
  const end = 24 * 60 * 60 * 1000 * (day + 1) - 1;
  const timeNum = isZero ? zero : end;
  // const time = new Date(`${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()} 00:00:00`).getTime() + timeNum;
  // todo
  const time = new Date().getTime() + timeNum;
  // let date = new Date(time);
  // if (/(y+)/i.test(fmt)) {
  //   fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  // }
  // let o = {
  //   'M+': date.getMonth() + 1,
  //   'd+': date.getDate(),
  //   'H+': date.getHours(),
  //   'm+': date.getMinutes(),
  //   's+': date.getSeconds()
  // };
  // for (let k in o) {
  //   if (new RegExp(`(${k})`).test(fmt)) {
  //     let str = o[k] + '';
  //     fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
  //   }
  // }
  // return fmt;
  return formatTime({ date: time, fmt });
}
// 格式化日期为特定结构
export function formatTime({ date = new Date(), fmt = "yyyy:MM:dd hh:mm:ss" }) {
  date = new Date(date);
  if (/(y+)/i.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  let o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "H+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + "";
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
    }
  }
  return fmt;
}

export function getControlPosition(e) {
  return offsetXYFromParentOf(e);
}

export function offsetXYFromParentOf(evt) {
  if (!evt.target.offsetParent) return;
  const offsetParent = evt.target.offsetParent.offsetParent || document.body;
  const offsetParentRect =
    offsetParent === document.body
      ? {
          left: 0,
          top: 0,
        }
      : offsetParent.getBoundingClientRect();

  const x = evt.clientX + offsetParent.scrollLeft - offsetParentRect.left;
  const y = evt.clientY + offsetParent.scrollTop - offsetParentRect.top;

  return {
    x,
    y,
  };
}

// Create an data object exposed by <DraggableCore>'s events
export function createCoreData(lastX, lastY, x, y) {
  // State changes are often (but not always!) async. We want the latest value.
  const isStart = !isNum(lastX);

  if (isStart) {
    // If this is our first move, use the x and y as last coords.
    return {
      deltaX: 0,
      deltaY: 0,
      lastX: x,
      lastY: y,
      x: x,
      y: y,
    };
  } else {
    // Otherwise calculate proper values.
    return {
      deltaX: x - lastX,
      deltaY: y - lastY,
      lastX: lastX,
      lastY: lastY,
      x: x,
      y: y,
    };
  }
}

function isNum(num) {
  return typeof num === "number" && !Number.isNaN(num);
}

export function initUTCDate(date) {
  // 格式化UTC时间  2021-10-10  10:00:00  为  20211010T100000Z
  let utcDate = moment(date).utc(8).format();
  return utcDate.split("-").join("").split(":").join("");
}

export function getBeforeDate(n = 0) {
  let d = new Date();
  let year = d.getFullYear();
  let mon = d.getMonth() + 1;
  let day = d.getDate();
  let s = "";
  if (day <= n) {
    if (mon > 1) {
      mon = mon - 1;
    } else {
      year = year - 1;
      mon = 12;
    }
  }
  d.setDate(d.getDate() - n);
  year = d.getFullYear();
  mon = d.getMonth() + 1;
  day = d.getDate();
  s = year + "-" + (mon < 10 ? "0" + mon : mon) + "-" + (day < 10 ? "0" + day : day);
  return s;
}

// 两值比较大小  并提示
export function compareSize(min, max, tips) {
  if (!min || !max) return true;
  if (Number(min) > Number(max)) {
    MessageBox.alert(`${tips}输入不正确，请重新输入`, "提示", {
      confirmButtonText: "确定",
    });
    return false;
  } else {
    return true;
  }
}
