import axios from "axios";
import qs from "qs";
import {
  MessageBox
} from 'element-ui';
import Router from '@/router';
import {
  handleExport,
  getQueryVariable
} from './public.js'
import { nvrMediaUrl1, nvrMediaUrl2 } from "@/common/js/videoConfig";
axios.defaults.timeout = 500000;
// axios.defaults.headers["Session-Id"] = sessionStorage.token;
axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
// axios.defaults.baseURL = '/bigdata';
//  POST传参序列化
// let pending = [];
// let CancelToken = axios.CancelToken;
// let removePending = (config) => {
//     for (let p in pending) {
//         if (pending[p].u === config.url + '&' + config.method) {
//             pending[p].f();
//             pending.splice(p, 1);
//         }
//     }
// }
axios.interceptors.request.use(
  config => {
    // removePending(config);
    //  config.cancelToken = new CancelToken((c) => {
    //     pending.push({ u: config.url + '&' + config.method, f: c });
    // });
    config.headers["Session-Id"] = sessionStorage.token || '';
    if (config.method !== "get") {
      if (config.url == nvrMediaUrl2) {
        return config;
      }
      if (config.data) {
        if (config.headers['Content-Type'].indexOf('application/json') === -1) {
          if (config.headers['Content-Type'].indexOf('multipart/form-data') !== -1) {
            delete config.headers['Content-Type']
            config.data = config.data.data
          } else {
            config.data = qs.stringify(config.data.data, {
              arrayFormat: 'repeat'
            });
          }
        } else {
          config.data = config.data.data
        }
      }
    } else {
      config.params = config.data;
    }
    return config;
  },
  error => {
    //   Indicator.close();
    //   Toast("错误的传参");
    return Promise.reject(error);
  }
);

function timeoutCb() {
  MessageBox.alert('登录超时，请重新登录！', '提示', {
    confirmButtonText: '确定'
  }).then(() => {
    Router.push('/');
  }).catch(() => {
    Router.push('/');
  });
}
//  code状态码200判断
axios.interceptors.response.use(
  // 200
  res => {
    // console.log('200-->', res, res.config.responseType);
    if (res.config.responseType == 'blob') {
      handleExport(res);
      return;
    }
    if (res.data.state == 2 || res.state == 2) {
      if (sessionStorage.toLogin !== 'haveToLogin') {
        sessionStorage.toLogin = 'haveToLogin';
        timeoutCb();
      }
    } else if (res.data.state != 2 && res.config.responseType != 'blob') {
      if (res.config.url == nvrMediaUrl2) {
        return new Promise(resolve => {
          resolve(res.data);
        })
      } else {
        return new Promise((resolve, reject) => {
          // resolve(res.data);
          if (res.data.state == 0) {
            resolve(res.data);
          } else {
            if (res.config.url != '/acb/2.0/systems/login/barCodeLogin') {
              const protalModule = getQueryVariable('module');
              if (protalModule === '') {
                MessageBox.alert(res.data.desc, '提示', {
                  confirmButtonText: '确定'
                });
              }
              reject(res.data);
            }
          }
        });
      }
    }
  },
  error => {
    console.log('errot--->', error);
    MessageBox.alert(error.response.data.desc || error.response.statusText || '服务器响应失败！', '提示', {
      confirmButtonText: '确定'
    }).then(() => { });
    // Indicator.close();
    return Promise.reject(error);
  }
);

export default axios;
