/*
 * @Author: DZM
 * @Date: 2022-03-23 18:14:51
 * @LastEditors: lijing
 * @LastEditTime: 2022-08-17 15:13:08
 * @Description: 
 */
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistedstate from '@/common/plugins/persist';
Vue.use(Vuex);

const modulesFiles = require.context('./modules', true, /\.js$/);
const modules = modulesFiles.keys().reduce((mock, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  const value = modulesFiles(modulePath);
  mock[moduleName] = value.default;
  return mock;
}, {});

export default new Vuex.Store({
  modules,
  state: {
    username: '',
    menuCollapse: false,
    regionalCollection: {
      areaId: "",
      dataSource: ''
    },
    newregionalCollection: {
      parkId: '',
      onclickTag: ''
    },
    coordinate: {
    },
    complainManageTag: true
  },
  plugins: [VuexPersistedstate],
  mutations: {
    SET_USERNAME(state, username) {
      state.username = username;
    },
    SET_COLLAPSE(state, v) {
      state.menuCollapse = v;
    },
    SET_regionalCollection(state, v) {
      state.regionalCollection = v
    },
    SET_newregionalCollection(state, v) {
      state.newregionalCollection = v
    },
    SET_ComplainManageTag(state, data) {
      state.complainManageTag = data;
    },
    SET_Coordinate(state, data) {
      state.coordinate = data;
    },
  }
});
