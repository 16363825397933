export default [{
  path: 'home',
  name: 'home',
  component: () =>
    // import('@/views/home/newHome'),
    // import('@/views/home/homeGridLayout'),
    import('@/views/homeOverview/indexMap'),
  meta: {
    title: '首页'
  }
},
{
  path: 'homeConfig',
  name: 'homeConfig',
  component: () =>
    import('@/views/homeConfig/homeConfig'),
  meta: {
    title: '停车概览配置'
  }
},
{
  path: 'edrsRule',
  name: 'edrsRule',
  component: () =>
    import('@/views/dataRule/index'),
  meta: {
    title: '数据路由规则'
  }
},
{
  path: 'ruleEdit',
  name: 'edrsRule/ruleEdit',
  component: () =>
    import('@/views/dataRule/ruleEdit'),
  meta: {
    title: '数据路由规则'
  }
},
{
  path: 'acbOverview',
  name: 'acbOverview',
  meta: {
    title: '概览'
  },
  component: () =>
    import('@/views/overview'),
},
{
  path: 'parkEocList',
  name: 'parkList/parkEocList',
  meta: {
    title: '车场修改审核'
  },
  component: () =>
    import('@/views/EocConnect')
},
{
  path: 'parkList',
  name: 'parkList',
  meta: {
    title: '道路配置'
  },
  component: () =>
    import('@/views/parkManage')
},
// ace2.2--------------------------------------------
{
  path: 'synchronousCarYard',
  name: 'parkList/synchronousCarYard',
  meta: {
    title: '同步接入车场'
  },
  component: () =>
    import('@/views/parkManage/children/synchronousCarYard')
},
{
  path: 'synchronousCarYardDetail',
  name: 'parkList/synchronousCarYard/synchronousCarYardDetail',
  meta: {
    title: '同步接入车场详情'
  },
  component: () =>
    import('@/views/parkManage/children/detail')
},
{
  path: 'synchronizationLog',
  name: 'parkList/synchronousCarYard/synchronizationLog',
  meta: {
    title: '同步日志'
  },
  component: () =>
    import('@/views/parkManage/children/synchronizationLog')
},
// ------------>
{
  path: 'parkConfig',
  name: 'parkList/parkConfig|parkList/noCloseParentDetail/parkConfig',
  component: () =>
    import('@/views/parkManage/parkList/parkConfig'),
  meta: {
    title: '停车场配置'
  }
}, {
  path: 'addClosePark',
  name: 'parkList/addClosePark|parkList/noCloseParentDetail/addClosePark',
  component: () =>
    import('@/views/parkManage/parkList/addClosePark'),
  meta: {
    title: '添加封闭车场'
  }
}, {
  path: 'cloasParkDetail',
  name: 'parkList/cloasParkDetail|parkList/noCloseParentDetail/cloasParkDetail',
  component: () =>
    import('@/views/parkManage/parkList/parkConfig'),
  meta: {
    title: '查看封闭车场详情'
  }
}, {
  path: 'closeParkConfig',
  name: 'parkList/closeParkConfig|parkList/noCloseParentDetail/closeParkConfig',
  component: () =>
    import('@/views/parkManage/parkList/closeParkConfig'),
  meta: {
    title: '封闭停车场配置'
  }
}, {
  path: 'noCloseChildDetail',
  name: 'parkList/noCloseChildDetail|parkList/noCloseParentDetail/noCloseChildDetail',
  component: () =>
    import('@/views/parkManage/parkList/noCloseChildDetail'),
  meta: {
    title: '查看非封闭车场子车场详情'
  }
}, {
  path: 'noCloseParentDetail',
  name: 'parkList/noCloseParentDetail',
  component: () =>
    import('@/views/parkManage/parkList/noCloseParentDetail'),
  meta: {
    title: '查看非封闭车场车场父车场'
  }
}, {
  path: 'addNoClosePark',
  name: 'parkList/addNoClosePark|parkList/noCloseParentDetail/addNoClosePark',
  component: () =>
    import('@/views/parkManage/parkList/addNoClosePark'),
  meta: {
    title: '添加非封闭车场'
  }
}, {
  path: 'nvrVideoMonitorEquipment',
  name: 'nvrVideoMonitorEquipment',
  component: () => import("@/views/equipmentManage/videoMonitorEquipment/index"),
  meta: {
    title: 'NVR管理'
  }
},
{
  path: 'editVideoMonitorEquip',
  component: () =>
    import('@/views/equipmentManage/videoMonitorEquipment/editVideoMonitorEquip'),
  name: 'nvrVideoMonitorEquipment/editVideoMonitorEquip',
  meta: {
    title: '编辑视频监控设备'
  }
},
// {
//   path: 'videoMonitorEdit',  // pre环境因和商用页面混乱 故注释改回master代码
//   component: () =>
//       import('@/views/equipmentManage/videoMonitorEquipment/addVideoMonitorEquip'),
//   name: 'monitorEquipment/addVideoMonitorEquip',
//   meta: {
//     title: '添加视频监控设备'
//   }
// },
{
  path: 'videoMonitorEdit',
  component: () =>
    import('@/views/equipmentManage/videoMonitor/edit'),
  name: 'monitorEquipment/addVideoMonitorEquip',
  meta: {
    title: '添加视频监控设备'
  }
},
{
  path: 'parkRecordTotal',
  name: 'parkRecordTotal',
  component: () =>
    import('@/views/recordTotal/parkRecordTotal/parkRecordTotal'),
  meta: {
    title: '停车记录查询'
  }
},
{
  path: 'highBitVideoRecording',
  name: 'highBitVideoRecording',
  component: () =>
    import('@/views/recordTotal/highBitVideoRecording/highBitVideoRecording'),
  meta: {
    title: '高位同步记录'
  }
},
{
  path: 'berthList',
  name: 'berthList',
  component: () =>
    import('@/views/parkManage/Listberth'),
  meta: {
    title: '泊位列表'
  }
}, {
  path: 'chargeRules',
  name: 'chargeRules',
  component: () =>
    // import('@/views/chargeSet/chargeRule/chargeRule'),  // 老计费规则_2024-06-21
    import('@/views/chargeSet/billingRules/index'), // 新计费规则
  meta: {
    title: '计费规则'
  }
}, {
  path: 'bindDetails',
  component: () =>
    import('@/views/chargeSet/billingRules/bindDetails'),
  name: 'chargeRules/bindDetails',
  meta: {
    title: '绑定详情'
  }
}, {
  path: 'addRule',
  component: () =>
    import('@/views/chargeSet/chargeRule/addRuleNew'),
  name: 'chargeRules/addRuleNew',
  meta: {
    title: '添加计费规则A'
  }
}, {
  path: 'addRule1',
  component: () =>
    import('@/views/chargeSet/chargeRule/addRule'),
  name: 'chargeRules/addRule',
  meta: {
    title: '添加计费规则A'
  }
}, {
  path: 'addRule_xia',
  component: () =>
    import('@/views/chargeSet/chargeRule/addRule_xia'),
  name: 'chargeRules/addRule_xia',
  meta: {
    title: '添加计费规则A'
  }
},
{
  path: 'seeRule',
  component: () =>
    import('@/views/chargeSet/chargeRule/seeRule'),
  name: 'chargeRules/seeRule',
  meta: {
    title: '添加计费规则A'
  }
},
{
  path: 'seeRuleNew',
  component: () =>
    import('@/views/chargeSet/chargeRule/seeRuleNew'),
  name: 'chargeRules/seeRuleNew',
  meta: {
    title: '添加计费规则A'
  }
},
{
  path: 'seeRule_xia',
  component: () =>
    import('@/views/chargeSet/chargeRule/seeRule_xia'),
  name: 'chargeRules/seeRule_xia',
  meta: {
    title: '添加计费规则A' // 厦门计费规则详情
  }
},
{
  path: 'setRule',
  component: () =>
    import('@/views/chargeSet/chargeRule/setRule'),
  name: 'chargeRules/setRule',
  meta: {
    title: '绑定车场'
  }
},
{
  path: 'addTimePeriod',
  component: () =>
    import('@/views/chargeSet/chargeRule/addTimePeriod'),
  name: 'chargeRules/addTimePeriod',
  meta: {
    title: '添加计费规则'
  }
},
{
  path: "/rule-ae",
  name: "chargeRules/rule-ae",
  component: () =>
    import('@/views/chargeSet/chargeRule/rule-ae'),
  meta: {
    title: '添加计费规则',
    authority: 0
  }
},
// 连续停车计费规则-start
{
  path: 'continueChargeRules',
  name: 'continueChargeRules',
  component: () =>
    import('@/views/chargeSet/continueChargeRule/chargeRule'),
  meta: {
    title: '连续计费规则'
  }
}, {
  path: 'addContinueRule',
  component: () =>
    import('@/views/chargeSet/continueChargeRule/addRuleNew'),
  name: 'continueChargeRules/addRuleNew',
  meta: {
    title: '添加计费规则A'
  }
}, {
  path: 'addContinueRule1',
  component: () =>
    import('@/views/chargeSet/continueChargeRule/addRule'),
  name: 'continueChargeRules/addRule',
  meta: {
    title: '添加计费规则A'
  }
}, {
  path: 'addContinueRule_xia',
  component: () =>
    import('@/views/chargeSet/continueChargeRule/addRule_xia'),
  name: 'continueChargeRules/addRule_xia',
  meta: {
    title: '添加计费规则A'
  }
},
{
  path: 'seeContinueRule',
  component: () =>
    import('@/views/chargeSet/continueChargeRule/seeRule'),
  name: 'continueChargeRules/seeRule',
  meta: {
    title: '添加计费规则A'
  }
},
{
  path: 'seeContinueRuleNew',
  component: () =>
    import('@/views/chargeSet/continueChargeRule/seeRuleNew'),
  name: 'continueChargeRules/seeRuleNew',
  meta: {
    title: '添加计费规则A'
  }
},
{
  path: 'seeContinueRule_xia',
  component: () =>
    import('@/views/chargeSet/continueChargeRule/seeRule_xia'),
  name: 'continueChargeRules/seeRule_xia',
  meta: {
    title: '添加计费规则A' // 厦门计费规则详情
  }
},
{
  path: 'setContinueRule',
  component: () =>
    import('@/views/chargeSet/continueChargeRule/setRule'),
  name: 'continueChargeRules/setRule',
  meta: {
    title: '绑定车场'
  }
},
{
  path: 'addContinueTimePeriod',
  component: () =>
    import('@/views/chargeSet/continueChargeRule/addTimePeriod'),
  name: 'continueChargeRules/addTimePeriod',
  meta: {
    title: '添加计费规则'
  }
},
{
  path: "/continueRule-ae",
  name: "continueChargeRules/rule-ae",
  component: () =>
    import('@/views/chargeSet/continueChargeRule/rule-ae'),
  meta: {
    title: '添加计费规则',
    authority: 0
  }
},
// 连续停车计费规则-end
{
  path: 'arrearsRecordTotal',
  name: 'arrearsRecordTotal',
  component: () =>
    import('@/views/recordTotal/arrearsRecordTotal/arrearsRecordTotal'),
  meta: {
    title: '停车欠费订单'
  }
},
{
  path: 'payRecordQuery',
  name: 'payRecordQuery',
  component: () =>
    import('@/views/recordTotal/paymentRecordQuery/paymentRecord'),
  meta: {
    title: '停车支付订单'
  }
},
{
  path: 'arrearsInquiries',
  name: 'arrearsInquiries',
  component: () =>
    import('@/views/recordTotal/arrearsInquiries/arrearsInquiries'),
  meta: {
    title: '欠费追缴查询'
  }
},
{
  path: 'entryExitOperationPDA',
  name: 'entryExitOperationPDA',
  component: () =>
    import('@/views/recordTotal/entryExitOperationPDA/entryExitOperationPDA'),
  meta: {
    title: 'PDA操作记录'
  }
},
{
  path: 'preOrderRecord',
  name: 'preOrderRecord',
  component: () =>
    import('@/views/recordTotal/parkPayOrder2/parkPayOrder'),
  meta: {
    title: '停车预付订单'
  }
},
{
  path: 'parkCarAlarm',
  component: () =>
    import('@/views/dataalarmTotal/dataalarMessage'),
  name: 'parkCarAlarm',
  meta: {
    title: '车辆报警查询'
  }
},

{
  path: 'carpoliceLong',
  component: () =>
    import('@/views/dataalarmTotal/carpoliceLong'),
  name: 'parkCarAlarm/carpoliceLong',
  meta: {
    title: '车辆报警套牌车'
  }
},

{
  path: 'carpoliceDeck',
  component: () =>
    import('@/views/dataalarmTotal/carpoliceDeck'),
  name: 'parkCarAlarm/carpoliceDeck',
  meta: {
    title: '车辆报警超长停车'
  }
},

{
  path: 'collectorList',
  component: () =>
    import('@/views/collectorManage/collectorList/collectorManage'),
  name: 'collectorList',
  meta: {
    title: '人员信息'
  }
},
{
  path: 'collectorAE',
  component: () =>
    import('@/views/collectorManage/collectorList/collectorManage-AE'),
  name: 'collectorList/collectorAE',
  meta: {
    title: '收费员列表添加修改'
  }
},
{
  path: 'collectorAttence',
  component: () =>
    import('@/views/collectorManage/collectorAttence/'),
  name: 'collectorAttence',
  meta: {
    title: '人员考勤'
  }
},

{
  path: 'userAttendanceDetails',
  component: () => import('@/views/collectorManage/collectorAttence/userAttendanceDetails'),
  name: 'collectorAttence/userAttendanceDetails',
  meta: {
    title: '人员考勤详情'
  }
},
{
  // 电子围栏
  path: 'elecFence',
  component: () => import('@/views/collectorManage/electronicFence/'),
  name: 'elecFence',
  meta: {
    title: '电子围栏'
  }
},
{
  path: 'calendar',
  component: () =>
    import('@/views/collectorManage/collectorAttence/calendar'),
  name: 'collectorAttence/calendar',
  meta: {
    title: '考勤日历'
  }
},
{
  path: 'attenceDetail',
  component: () =>
    import('@/views/collectorManage/collectorAttence/detail'),
  name: 'collectorAttence/calendar/attenceDetail',
  meta: {
    title: '考勤详情'
  }
},

{
  path: 'classes',
  component: () => import('@/views/collectorManage/collectorClasses/'),
  name: 'classes',
  meta: {
    title: '排班管理'
  }
},

{
  path: 'classesManageDetail',
  component: () => import('@/views/collectorManage/collectorClasses/detail'),
  name: 'classes/classesManageDetail',
  meta: {
    title: '班次管理详情'
  }
},

{
  path: 'classesAddEdit',
  component: () => import('@/views/collectorManage/collectorClasses/addEdit'),
  name: 'classes/classesAddEdit',
  meta: {
    title: '班次管理添加编辑'
  }
},


{
  path: 'scheduling',
  component: () => import('@/views/collectorManage/collectorPersonnel/'),
  name: 'scheduling',
  meta: {
    title: '人员排班'
  }
},

{
  path: 'personnelSchedulingEdit',
  component: () => import('@/views/collectorManage/collectorPersonnel/edit'),
  name: 'scheduling/personnelSchedulingEdit',
  meta: {
    title: '人员排班编辑'
  }
},
{
  path: 'inspectionConf',
  component: () => import('@/views/collectorManage/collectorConfig/auditConfig'),
  name: 'inspectionConf',
  meta: {
    title: '稽查配置'
  }
},

{
  path: 'auditConf',
  component: () => import('@/views/collectorManage/collectorExamine/auditAndAudit'),
  name: 'auditConf',
  meta: {
    title: '稽查审核'
  }
},

{
  path: 'auditCheck',
  component: () => import('@/views/collectorManage/collectorExamine/checked'),
  name: 'auditConf/auditCheck',
  meta: {
    title: '稽查审核处理'
  }
},

{
  path: 'auditDetail',
  component: () => import('@/views/collectorManage/collectorExamine/detail'),
  name: 'auditConf/auditDetail',
  meta: {
    title: '稽查审核查看'
  }
},

{
  path: 'auditMemberStatistics',
  component: () => import('@/views/collectorManage/collectorWork/Auditworkstatistics'),
  name: 'auditMemberStatistics',
  meta: {
    title: '稽查员工作统计'
  }
},
// 收费员工作统计
{
  path: 'personStatistics',
  component: () => import('@/views/collectorManage/collectorWorkStatistics/collectorWorkStatistics'),
  name: 'personStatistics',
  meta: {
    title: '收费员工作统计'
  }
},

{
  path: 'auditStatistics',
  component: () => import('@/views/collectorManage/collectorStatistics/auditStatistics'),
  name: 'auditStatistics',
  meta: {
    title: '被稽查人员统计'
  }
},

{
  path: 'auditStatisticsDetail',
  component: () => import('@/views/collectorManage/collectorStatistics/auditStatisticsDetail'),
  name: 'auditStatistics/auditStatisticsDetail',
  meta: {
    title: '稽查统计详情'
  }
},
// {
//   path: 'videoMonitorEquipment',
//   component: () =>
//     import('@/views/equipmentManage/videoMonitorEquipment1/index'),
//   name: 'videoMonitorEquipment',
//   meta: {
//     title: '视频监控设备管理'
//   }
// },
{
  path: 'editVideoMonitorEquip1',
  component: () =>
    import('@/views/equipmentManage/videoMonitorEquipment1/editVideoMonitorEquip'),
  name: 'videoMonitorEquipment1/editVideoMonitorEquip1',
  meta: {
    title: '编辑视频监控设备'
  }
}, {
  path: 'addVideoMonitorEquip',
  component: () =>
    // import('@/views/equipmentManage/videoMonitorEquipment1/addVideoMonitorEquip'),
    import('@/views/equipmentManage/videoMonitorEquipment/addVideoMonitorEquip'),
  name: 'nvrVideoMonitorEquipment/addVideoMonitorEquip1',
  meta: {
    title: '添加视频监控设备'
  }
},
// {  // 2.9 需求删除此页面
//   path: 'videoMonitorEquipment1',
//   component: () =>
//     import('@/views/equipmentManage/videoMonitorEquipment1/index'),
//   name: 'videoMonitorEquipment1',
//   meta: {
//     title: '视频监控列表'
//   }
// },
{
  path: 'addVideoMonitorEquip1',
  component: () =>
    import('@/views/equipmentManage/videoMonitorEquipment1/addVideoMonitorEquip'),
  // import('@/views/equipmentManage/videoMonitorEquipment/addVideoMonitorEquip'),
  name: 'videoMonitorEquipment1/addVideoMonitorEquip1',
  meta: {
    title: '添加视频监控设备'
  }
},
{
  path: 'videoEquipmentList',
  component: () =>
    import('@/views/equipmentManage/equipmentState'),
  name: 'videoEquipmentList',
  meta: {
    title: '设备状态'
  }
},
{
  path: '/geomagneticSignalManagement',
  component: () =>
    import('@/views/equipmentManage/geomagneticSignalManagement'),
  name: 'geomagneticSignalManagement',
  meta: {
    title: '地磁信号记录'
  }
},
{
  path: 'parkingequipmentDetail',
  component: () =>
    import('@/views/equipmentManage/parkingEquipment/parkingequipmentDetail'),
  name: 'videoEquipmentList/parkingequipmentDetail',
  meta: {
    title: '车场维度详情'
  }
},
{
  path: 'roadOutEquipment',
  component: () =>
    import('@/views/equipmentManage/roadOutEquipment/'),
  name: 'roadOutEquipment',
  meta: {
    title: '路外车场设备监控'
  }
}, {
  path: 'roadOutEquipmentDetail',
  component: () =>
    import('@/views/equipmentManage/roadOutEquipment/roadOutEquipmentDetail'),
  name: 'roadOutEquipment/roadOutEquipmentDetail',
  meta: {
    title: '路外车场设备监控详情'
  }
},
{
  path: 'barManager',
  component: () =>
    import('@/views/equipmentManage/barManager/barManager'),
  name: 'barManager',
  meta: {
    title: '杆位管理'
  }
},
{
  path: 'pdaAdmin',
  component: () =>
    import('@/views/equipmentManage/equipmentList/equipmentList'),
  name: 'pdaAdmin',
  meta: {
    title: 'PDA管理' // 需要被缓存
  }
},
{
  path: 'equipmentAE',
  name: 'pdaAdmin/equipmentAE',
  component: () =>
    import('@/views/equipmentManage/equipmentList/equipment-AE'),
  meta: {
    title: '设备修改、添加' // 需要被缓存
  }
},
{
  path: 'pdaPush',
  name: 'pdaPush',
  component: () =>
    import('@/views/equipmentManage/pdaPush/index'),
  meta: {
    title: '消息通知' // 需要被缓存
  }
},
{
  path: 'pdaPushAE',
  name: 'pdaPush/pdaPushAE',
  component: () =>
    import('@/views/equipmentManage/pdaPush/pdaPush-AE'),
  meta: {
    title: 'PDA推送添加' // 需要被缓存
  }
},
{
  path: "inspectionVehicle",
  name: "inspectionVehicle",
  component: () => import("@/views/Inspection/index"),
  meta: {
    title: "巡检车管理"
  }
},
{
  path: 'brandManage',
  component: () =>
    import('@/views/equipmentManage/brandManage/'),
  name: 'brandManage',
  meta: {
    title: '设备品牌'
  }
},
{
  path: 'addBrand',
  component: () =>
    import('@/views/equipmentManage/brandManage/addBrand'),
  name: 'brandManage/addBrand',
  meta: {
    title: '添加品牌'
  }
},
{
  path: 'remoteInspection',
  component: () =>
    import('@/views/equipmentManage/remoteInspection/remoteInspection'),
  name: 'remoteInspection',
  meta: {
    title: '远程巡检'
  }
},
{
  path: 'deviceAlarm',
  component: () =>
    import('@/views/equipmentManage/deviceAlarm/deviceAlarm'),
  name: 'deviceAlarm',
  meta: {
    title: '设备报警'
  }
},
{
  path: 'deviceAlarmDetail',
  component: () =>
    import('@/views/equipmentManage/deviceAlarm/deviceAlarmDetail'),
  name: 'deviceAlarm/deviceAlarmDetail',
  meta: {
    title: '设备报警详情'
  }
},
{
  path: '/specialRecord',
  component: resolve => require(['@/views/whiteList/index.vue'],
    resolve),
  name: 'specialRecord',
  meta: {
    title: '白名单管理'
  }
},
{
  path: '/specialApply',
  component: resolve => require(['@/views/whiteList/specialApply'],
    resolve),
  name: 'specialApply',
  meta: {
    title: '白名单申请'
  }
},
{
  path: '/specialAudit',
  component: resolve => require(['@/views/whiteList/specialAudit'],
    resolve),
  name: 'specialAudit',
  meta: {
    title: '白名单审核'
  }
},
{
  path: 'blacklist',
  name: 'blacklist',
  component: () =>
    import('@/views/buinessSet/blacklist/blacklist'),
  meta: {
    title: '黑名单管理'
  }
},
{
  path: 'blacklist-AE',
  component: () =>
    import('@/views/buinessSet/blacklist/blacklist-AE'),
  name: 'blacklist/blacklist-AE',
  meta: {
    title: '添加黑名单'
  }
},
{
  path: 'parkingCardRule',
  component: () =>
    import('@/views/parkingCard/parkingCardRule/index'),
  name: 'parkingCardRule',
  meta: {
    title: '停车卡管理'
  }
},
{
  path: 'parkRuleAddEdit',
  name: 'parkingCardRule/parkRuleAddEdit',
  component: () =>
    import('@/views/parkingCard/parkingCardRule/parkRuleAddEdit'),
  meta: {
    title: '停车卡管理修改'
  }
},
{
  path: 'parkingCardManagementLog',
  name: 'parkingCardRule/parkingCardManagementLog',
  component: () =>
    import('@/views/parkingCard/parkingCardRule/parkingCardManagementLog'),
  meta: {
    title: '停车卡管理日志'
  }
},
{
  path: 'parkingCardDetailLog',
  name: 'parkingCardManage/parkingCardDetailLog',
  component: () =>
    import('@/views/parkingCard/parkingCardManage/parkingCardDetailLog'),
  meta: {
    title: '停车卡订单日志'
  }
},
{
  path: '/parkRuleDetail',
  name: 'parkingCardRule/parkRuleDetail',
  component: () =>
    import('@/views/parkingCard/parkingCardRule/parkRuleDetail'),
  meta: {
    title: '停车卡管理详情'
  }
},
{
  path: 'parkingCardManage',
  component: () =>
    import('@/views/parkingCard/parkingCardManage/index'),
  name: 'parkingCardManage',
  meta: {
    title: '停车卡业务'
  }
},
{
  path: 'parkCardAddEdit',
  component: () =>
    import('@/views/parkingCard/parkingCardManage/parkCardAddEdit'),
  name: 'parkingCardManage/parkCardAddEdit',
  meta: {
    title: '添加订单'
  }
},
{
  path: 'parkCardNewAdd',
  component: () =>
    import('@/views/parkingCard/parkingCardManage/parkCardNewAdd'),
  name: 'parkingCardManage/parkCardNewAdd',
  meta: {
    title: '添加订单'
  }
},
// {
//   path: 'parkCardDetail',
//   component: () =>
//     import('@/views/parkingCard/parkingCardManage/parkCardDetail'),
//   name: 'parkingCardManage/parkCardDetail',
//   meta: {
//     title: '查看订单'
//   }
// },
{
  path: 'parkCardDetail',
  component: () =>
    import('@/views/parkingCard/parkingCardManage/parkCardDetail1'),
  name: 'parkingCardManage/parkCardDetail1',
  meta: {
    title: '查看订单'
  }
},
{
  path: 'finParkingCardManage',
  component: () =>
    import('@/views/parkingCard/parkingCardManage2/indexTab.vue'),
  name: 'finParkingCardManage',
  meta: {
    title: '停车卡业务查询',
    keepAlive: true
  }
},
{
  path: 'parkCardAddEdit2',
  component: () =>
    import('@/views/parkingCard/parkingCardManage2/parkCardAddEdit'),
  name: 'finParkingCardManage/parkCardAddEdit',
  meta: {
    title: '添加订单'
  }
},
{
  path: 'parkCardDetail2',
  component: () =>
    import('@/views/parkingCard/parkingCardManage2/parkCardDetail'),
  name: 'finParkingCardManage/parkCardDetail',
  meta: {
    title: '查看订单'
  }
},
{
  path: 'parkCardCountTotal',
  component: () =>
    import('@/views/parkingCard/parkCardCountTotal'),
  name: 'parkCardCountTotal',
  meta: {
    title: '停车卡统计'
  }
},
{
  path: 'berthStateMonitor',
  component: () =>
    import('@/views/equipmentState/berthMonitor/berthStateMonitor'),
  name: 'berthStateMonitor',
  meta: {
    title: '泊位状态监控'
  }
},
{
  path: 'berthStateMonitorDetail',
  component: () =>
    import('@/views/equipmentState/berthMonitor/berthStateMonitorDetail'),
  name: 'berthStateMonitor/berthStateMonitorDetail',
  meta: {
    title: '泊位状态监控详情'
  }
},
{
  path: 'aomOrder',
  component: () =>
    import('@/views/equipmentState/aomOrder/aomOrder'),
  name: 'aomOrder',
  meta: {
    title: '设备工单'
  }
},
{
  path: 'aomOrderDetail',
  component: () =>
    import('@/views/equipmentState/aomOrder/aomOrderDetail'),
  name: 'aomOrder/aomOrderDetail',
  meta: {
    title: '设备工单详情'
  }
},
{
  path: 'berthStateStatistics',
  component: () =>
    import('@/views/equipmentState/berthStateStatistics/berthStateStatistics'),
  name: 'berthStateStatistics',
  meta: {
    title: '实时统计'
  }
},
{
  path: 'berthStateTotal',
  component: () =>
    import('@/views/equipmentState/berthStateTotal/berthStateTotal'),
  name: 'berthStateTotal',
  meta: {
    title: '历史统计'
  }
},
{
  path: 'equipmentAlarmInfo',
  component: () =>
    import('@/views/allInformation/equipmentAlarmInfo/equipmentAlarmInfo'),
  name: 'equipmentAlarmInfo',
  meta: {
    title: '设备报警消息'
  }
},
{
  path: "migratePark",
  component: () => import("@/views/parkManage/dataMigration"),
  name: "migratePark",
  meta: {
    title: "数据迁移"
  }
},
{
  path: "preChargeSetting",
  component: () => import("@/views/parkManage/prepaidConfig"),
  name: "preChargeSetting",
  meta: {
    title: "预付费规则"
  }
},
{
  path: "addeditPrepaidConfig",
  component: () => import("@/views/parkManage/addediteConfig"),
  name: "preChargeSetting/addeditPrepaidConfig",
  meta: {
    title: "预付费配置新增|编辑"
  }
},
{
  path: "prepaidDetail",
  component: () => import("@/views/parkManage/prepaidDetail"),
  name: "preChargeSetting/prepaidDetail",
  meta: {
    title: "预付费配置|查看"
  }
},
{
  path: "videoMonitorEquipment",
  component: () => import("@/views/equipmentManage/videoMonitorEquipment/index"),
  name: "videoMonitorEquipment",
  meta: {
    title: "NVR管理"
  }
},
{
  path: "monitorEquipment",
  component: () => import("@/views/equipmentManage/videoMonitor/index"),
  name: "monitorEquipment",
  meta: {
    title: "监控设备"
  }
},
{
  path: "selfParkingSetting",
  component: () => import("@/views/parkConfig/index"),
  name: "selfParkingSetting",
  meta: {
    title: "自主停车配置"
  }
},
{
  path: "parkConfigAE",
  component: () => import("@/views/parkConfig/addConfig"),
  name: "selfParkingSetting/parkConfigAE",
  meta: {
    title: "自主停车配置添加"
  }
},
{
  path: "parkConfigDE",
  component: () => import("@/views/parkConfig/detailConfig"),
  name: "selfParkingSetting/parkConfigDE",
  meta: {
    title: "自主停车配置详情"
  }
},
{
  path: "freeRuleSetting",
  component: () => import("@/views/freeRule/index"),
  name: "freeRuleSetting",
  meta: {
    title: "免费规则"
  }
}
  // todo
  // {
  //   path: 'EocConnect',
  //   component: () =>
  //       import('@/views/EocConnect'),
  //   name: 'EocConnect',
  //   meta: {
  //     title: 'Eco打通'
  //   }
  // }
];
