export default [{
  // 运营统计-全市数据分析-欠费总分析
  path: 'arrearageSummation',
  name: 'arrearageSummation',
  meta: {
    title: '欠费总分析'
  },
  component: () =>
    import(
      '@/views/statisticalAnalysis/citywideDataAnalysis/arrearageSummation'
    )
},
{
  // 运营统计-欠费总分析-详情1
  path: 'arrearageParkingList',
  name: 'arrearageSummation/arrearageParkingList',
  meta: {
    title: '欠费车场列表'
  },
  component: () =>
    import(
      '@/views/statisticalAnalysis/citywideDataAnalysis/arrearageSummation/arrearageParkingList'
    )
},
{
  // 运营统计-欠费总分析-欠费车牌详情
  path: 'licensePlateOwe',
  name: 'arrearageSummation/licensePlateOwe',
  meta: {
    title: '欠费车牌详情'
  },
  component: () =>
    import(
      '@/views/statisticalAnalysis/citywideDataAnalysis/arrearageSummation/licensePlateOwe'
    )
},
{
  // 运营统计-欠费总分析-详情2
  path: 'arrearagePlateList',
  name: 'arrearageSummation/arrearagePlateList',
  meta: {
    title: '欠费车牌列表'
  },
  component: () =>
    import(
      '@/views/statisticalAnalysis/citywideDataAnalysis/arrearageSummation/arrearagePlateList'
    )
},
{
  // 运营统计-全市数据分析-欠费历史分析
  path: 'arrearageHistory',
  name: 'arrearageHistory',
  meta: {
    title: '订单欠费分析'
  },
  component: () =>
    import(
      '@/views/statisticalAnalysis/citywideDataAnalysis/arrearageHistory'
    )
},
{
  // 运营统计-全市数据分析-临时停车统计
  path: 'temporaryPark',
  name: 'temporaryPark',
  meta: {
    title: '临时停车统计'
  },
  component: () =>
    import(
      '@/views/statisticalAnalysis/citywideDataAnalysis/temporaryPark'
    )
},
{
  // 运营统计-全市数据分析-夜间停车统计
  path: 'nightPark',
  name: 'nightPark',
  meta: {
    title: '夜间停车统计'
  },
  component: () =>
    import(
      '@/views/statisticalAnalysis/citywideDataAnalysis/nightPark'
    )
},
{
  // 运营统计-各区数据分析-各区收入统计
  path: 'regionIncomeStatistical',
  name: 'regionIncomeStatistical',
  meta: {
    title: '停车营收分析'
  },
  component: () =>
    import(
      // '@/views/statisticalAnalysis/regionStatisticalAnalysis/regionIncomeStatistical'
      '@/views/parkingChargeBusiness/parkingBusiness/index'
    )
},
{
  // 运营统计-各区数据分析-各区收入分析
  path: 'regionIncomeAnalysis',
  name: 'regionIncomeAnalysis',
  meta: {
    title: '区域营收对比'
  },
  component: () =>
    import(
      // '@/views/statisticalAnalysis/regionStatisticalAnalysis/regionIncomeAnalysis'
      '@/views/parkingChargeBusiness/regionalRevenue/index'
    )
},
{
  // 运营统计-各区数据分析-各区欠费分析
  path: 'regionArrearsAnalysis',
  name: 'regionArrearsAnalysis',
  meta: {
    title: '城市欠费分析'
  },
  component: () =>
    import(
      '@/views/statisticalAnalysis/regionStatisticalAnalysis/regionArrearsAnalysis'
    )
},
{
  // 运营统计-各区数据分析-欠费追缴分析
  path: 'regionArrearsRecoverAnalysis',
  name: 'regionArrearsRecoverAnalysis',
  meta: {
    title: '欠费追缴分析'
  },
  component: () =>
    import(
      '@/views/statisticalAnalysis/regionStatisticalAnalysis/regionArrearsRecoverAnalysis'
    )
},
{
  // 运营统计-停车场数据分析-停车场实况数据
  path: 'parkingLivedata',
  name: 'parkingLivedata',
  meta: {
    title: '停车场实况数据'
  },
  component: () =>
    import(
      '@/views/statisticalAnalysis/parkingStatisticalAnalysis/parkingLivedata'
    )
},
{
  // 运营统计-停车场数据分析-停车场运营分析
  path: 'parkingHistorydata',
  name: 'parkingHistorydata',
  meta: {
    title: '停车场运营分析'
  },
  component: () =>
    import(
      '@/views/statisticalAnalysis/parkingStatisticalAnalysis/parkingHistorydata'
    )
},
{
  // 运营统计-停车场数据分析-商业车场对比
  path: 'govermentOrBusinessParkingContrast',
  name: 'govermentOrBusinessParkingContrast',
  meta: {
    title: '商业车场对比'
  },
  component: () =>
    import(
      '@/views/statisticalAnalysis/govermentOrBusinessParkingContrast/govermentOrBusinessParkingContrast'
    )
},
{
  // 运营统计-设备分析-设备分布情况
  path: 'equRuning',
  name: 'equRuning',
  meta: {
    title: '设备分布情况'
  },
  component: () =>
    import(
      // '@/views/deviceStatistics/equRuning'
      '@/views/equipmentAnalysis/equipmentDistribution'
    )
},
{
  // 运营统计-设备分析-设备状态分析
  path: 'equStatus',
  name: 'equStatus',
  meta: {
    title: '设备状态分析'
  },
  component: () =>
    import(
      // '@/views/deviceStatistics/equRuning'
      '@/views/equipmentAnalysis/equipmentStatusAnalysis'
    )
},

{
  // 运营统计-会员统计-停车分析
  path: 'parkingAnalysis',
  name: 'parkingAnalysis',
  meta: {
    title: '停车分析'
  },
  component: () =>
    import(
      '@/views/statisticalAnalysis/Memberanalysis/Memberstatistics/Parkinganalysis'
    )
},
{
  // 运营统计-会员统计-注册分析
  path: 'registerAnalysis',
  name: 'registerAnalysis',
  meta: {
    title: '注册分析'
  },
  component: () =>
    import(
      '@/views/statisticalAnalysis/Memberanalysis/Memberstatistics/Registeranalysis'
    )
},
{
  // 运营统计-会员统计-支付分析
  path: 'payAnalysis',
  name: 'payAnalysis',
  meta: {
    title: '支付分析'
  },
  component: () =>
    import(
      '@/views/statisticalAnalysis/Memberanalysis/Memberstatistics/Payanalysis'
    )
},
{
  // 运营统计-停车业务分析-停车总分析
  path: 'parkingAnalysisOverview',
  name: 'parkingAnalysisOverview',
  meta: {
    title: '停车总分析'
  },
  component: () =>
    import(
      '@/views/Parkinganalysis/parkingAnalysis'
    )
},
{
  // 运营统计-停车业务分析-城市停车时长分析
  path: 'parkingAnalysisDuration',
  name: 'parkingAnalysisDuration',
  meta: {
    title: '停车时长分析'
  },
  component: () =>
    import(
      '@/views/parkingAnalysisDuration/parkingAnalysisDuration'
    )
},
{
  // 运营统计-停车业务分析-城市停车时段分析
  path: 'parkingAnalysisInterval',
  name: 'parkingAnalysisInterval',
  meta: {
    title: '停车时段分析'
  },
  component: () =>
    import(
      '@/views/parkingAnalysisInterval/index'
    )
  // component: () =>
  //   import(
  //     '@/views/deviceStatistics/dataDeviceStatistics'
  //   )
},
{
  // 客单价分析
  path: 'unitPriceAnalysis',
  name: 'unitPriceAnalysis',
  meta: {
    title: '停车收入分析'
  },
  component: () =>
    import(
      // '@/views/statisticalAnalysis/unitPriceAnalysis/index'
      '@/views/parkingChargeBusiness/parkingRevenueAnalysis/index'
    )
},
{
  // 泊位利用分析
  path: 'parkingBerthUtilization',
  name: 'parkingBerthUtilization',
  meta: {
    title: '泊位利用分析'
  },
  component: () =>
    import(
      '@/views/parkingBerthUtilization'
    )
},
{
  // 泊位利用分析
  path: 'parkingBerthTurnover',
  name: 'parkingBerthTurnover',
  meta: {
    title: '泊位周转分析'
  },
  component: () =>
    import(
      '@/views/parkingBerthTurnover'
    )
},
{
  // 车辆类型分析
  path: 'parkingCarType',
  name: 'parkingCarType',
  meta: {
    title: '车辆类型分析'
  },
  component: () =>
    import(
      '@/views/parkingCarType'
    )
},
{
  // 热圈分析
  path: 'hotAreaAnalysis',
  name: 'hotAreaAnalysis',
  meta: {
    title: '热门商圈分析'
  },
  component: () =>
    import(
      '@/views/hotAreaAnalysis/'
    )
},
{
  // 热圈分析
  path: 'parkBerthAnalysis',
  name: 'parkBerthAnalysis',
  meta: {
    title: '停车场泊位分析'
  },
  component: () =>
    import('@/views/parkingBerthAnalysis/parkingBerthAnalysis')
},
]
