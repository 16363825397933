export default {
  // 按钮
  button: {
    signin: "登录", // 登录
    search: "查询", // 查询
    reset: "重置", // 重置
    addto: "添加", // 添加
    edit: "编辑", // 编辑
    preservation: "保存", // 保存
    examine_and_verify: "审核修改", // 审核修改
    export: "导出", // 导出
    import: "导入", // 导入
    Evaluate_batch_configuration: "评价批量配置", // 评价批量配置
    synchronized_berth: "同步泊位", // 同步泊位
    add_shift: "添加班次", // 添加班次
    send_message: "发送消息", // 发送消息
    Create_Application: "创建申请", // 创建申请
    batch_import: "批量导入", // 批量导入
    Configure_prepaid_fees: "配置预付费", // 配置预付费
    Configure_autonomous_parking: "配置自主停车", // 配置自主停车
    set_up: "设置", // 设置
    Report_malfunction: "上报故障", // 上报故障
    Previous_parking_lot: "上一个车场", // 上一个车场
    Next_parking_lot: "下一个车场", // 下一个车场
    Previous_Road: "上一条道路", // 上一条道路
    The_next_road: "下一条道路", // 下一条道路
    Clean_up_zombie_cars: "清理僵尸车", // 清理僵尸车
    synchronization: "同步", // 同步
    sync_local: "同步本地", // 同步本地
    Open_screen: "开屏", // 开屏
    Turn_off_the_screen: "关屏", // 关屏
    Produce_programs: "制作节目", // 制作节目
    Delete_Program: "删除节目", // 删除节目
    Add_materials: "添加素材", // 添加素材
    Delete_material: "删除素材", // 删除素材
    Create_Merchant: "创建商户", // 创建商户
    Create_configuration: "创建配置", // 创建配置
    Create_SMS_task: "创建短信任务", // 创建短信任务
    Create_Phone_Task: "创建电话任务", // 创建电话任务
    Large_outstanding_fee_reminder_configuration: "大额欠费提醒配置", // 大额欠费提醒配置
    Create_advertisement: "创建广告", // 创建广告
    Create_news: "创建资讯", // 创建资讯
    Create_push_notifications: "创建推送", // 创建推送
    Add_product: "添加商品", // 添加商品
    Add_order: "添加订单", // 添加订单
    Create_a_problem: "创建问题", // 创建问题
    Already_listed: "已上架", // 已上架
    Not_listed_yet: "未上架", // 未上架
    Create_a_new_application: "创建新应用", // 创建新应用
    Merge_invoicing: "合并开票", // 合并开票
    configuration_messages: "配置消息", // 配置消息
    Mark_as_read: "标记已读", // 标记已读
    All_read: "全部已读", // 全部已读
    details: "详情", // 详情
    operation: "操作", // 操作
    delete: "删除", // 删除
    view: "查看", // 查看
    quit: "离职", // 离职
    modify: "修改", // 修改
    Remove_from_shelves: "下架", // 下架
    Put_on_shelves: "上架", // 上架
    reset_password: "重置密码", // 重置密码
    handle: "处理", // 处理
    to_examine: "审核", // 审核
    Details_of_outstanding_fees: "欠费详情", // 欠费详情
    journal: "日志", // 日志
    Move_up: "上移", // 上移
    Move_down: "下移", // 下移
    preview: "预览", // 预览
    version_management: "版本管理", // 版本管理
    Synchronize_to_official_website: "同步至官网", // 同步至官网
    Add_submenu: "添加子菜单", // 添加子菜单
    Disable: "禁用", // 禁用
    Stop_using: "停用", // 停用
    On_the_job: "在职", // 在职
    Enable: "启用", // 启用
    Add_Program: "添加节目", // 添加节目
    Resend: "重新发送", // 重新发送
    Parking_Lot_Configuration: "停车场配置", // 停车场配置
    QR_code_download: "二维码下载", // 二维码下载
    Modify_remaining_berths: "修改剩余泊位", // 修改剩余泊位
    describe: "描述", // 描述
    edit_describe: "编辑描述",
    View_monitoring: "查看监控", // 查看监控
    to_configure: "配置", // 配置
    Allocate_roads: "分配道路", // 分配道路
    go_online: "上线", // 上线
    Offline: "下线", // 下线
    determine: "确定", // 确定
    cancel: "取消", // 取消
    return: "返回", // 返回
    Basic_configuration: "基础配置",
    Function_configuration: "功能配置",
    submit: "提交",
  },
  // portal页
  portal: {},
  // 左侧菜单
  menu: {},
  // 顶部导航
  nav: {},
  // 搜索模块
  searchModule: {
    // 路内
    Query_Table: "查询表格",
    region: "区域",
    Road_name: "道路名称",
    Road_coding: "道路编码",
    charge_type: "计费类型",
    Merchant_Name: "商户名称",
    Commercial_status: "商用状态",
    Road_hierarchy: "道路层级",
    Berth_number: "泊位编号", // 泊位编号   泊位号
    state: "状态",
    distribution: "分配",
    Berth_type: "泊位类型",
    Service_label: "服务标签",
    Merchant: "商户",
    Equipment_number: "设备编号",
    Type_of_parking_lot: "车场类型",
    floor: "地上",
    under_floor: "地下",
    all_floor: "地上+地下",
    phone_number: "手机号",
    plate_number: "车牌号",
    Parking_record_ID: "停车记录ID",
    Presence_situation: "在场情况",
    Entry_and_exit_types: "出入场类型",
    time_slot: "时间段",
    order_ID: "订单ID",
    Payment_channels: "支付渠道",
    Payment_devices: "支付设备",
    Operator: "操作人",
    Merchant_affiliation: "所属商户",
    Time_type: "时间类型",
    Owing_road: "欠费路段",
    Toll_collector: "收费员",
    toll_road: "收费路段",
    Payment_period: "缴费时间段",
    Operation_time: "操作时间",
    The_interval_minutes: "间隔时长大于分钟数",
    Signal_status: "信号状态",
    Operation_situation: "操作情况",
    processing_time: "处理时间",
    Record_number: "记录编号",
    Interactive_effect: "交互效果",
    full_name: "姓名",
    sex: "性别",
    male: "男",
    female: "女",
    PDA_account: "PDA账号",
    On_duty_status: "在职状态",
    role: "角色",
    Department_to: "所属部门",
    Items_received: "所领物品",
    Employee_ID: "员工编号",
    Is_it_configured: "是否配置",
    Shift_name: "班次名称",
    Belonging_operator: "运营商", // 所属运营商    运营商  运营商名称
    Violating_personnel: "违规人员",
    Violation_type: "违规类型",
    Inspector: "稽查员",
    Report_time: "上报时间",
    Processing_personnel: "处理人",
    Login_Name: "登录名", // 登录名   登录用户名
    date: "日期",
    Sending_time: "发送时间",
    serial_number: "物品编号",
    Item_Name: "物品名称",
    Type_of_item: "物品类型",
    Item_model: "物品型号",
    Recipient: "领用人",
    Car_owner_name: "车主姓名",
    classification: "分类",
    Rule_Name: "规则名称",
    Configuration_Name: "配置名称",
    Vehicle_status: "车辆状态",
    Audit_status: "审核状态",
    ReportPerson: "上报人",
    Equipment_name: "设备名称",
    Pole_position_number: "杆位号",
    Equipment_type: "设备类型",
    Operation_status: "运营状态",
    Equipment_status: "设备状态",
    Pole_position_yourself: "是否自有杆",
    address: "地址",
    Belonging_parking_lot: "所属车场",
    Brand_Name: "品牌名称",
    Alarm_type: "报警类型",
    Equipment_manufacturer: "设备厂商",
    // 路外
    parking_code: "车场编码",
    Access_method: "接入方式",
    park_name: "车场名称", // 车场名称   停车场名称   停车场
    account_number: "账号",
    Manage_the_parking_lot: "管辖车场",
    is_malfunction: "是否有故障",
    Operator_name: "运营商名称",
    Belonging_NVR: "所属NVR",
    Channel_name: "通道名称",
    Offline_time: "掉线时间",
    Vehicle_ype: "车辆类型",
    Payment_Time: "支付时间",
    Opening_time: "开闸时间",
    Correction_type: "修正类型",
    Correction_time: "修正时间",
    Entry_time: "入场时间",
    Parking_duration: "停车时长",
    Cleaning_time: "清理时间",
    Event_type: "事件类型",
    Processing_results: "处理结果",
    MB_Cloud_Box: "MB云盒",
    Platform_to_Platform: "平台对平台",
    Lightweight_Cloud_Box: "轻量化云盒",
    Choose_parking_lot: "选择停车场",
    Selected: "已选择",
    Not_selected: "未选择",
    // 停车诱导
    Induced_screen_name: "诱导屏名称",
    type: "类型",
    Program_Name: "节目名称",
    creator: "创建者", // 创建者 创建人
    Material_Name: "素材名称",
    // 数据分析与预测
    Date_search: "日期搜索",
    district_names: "热门商圈",
    Appearance_time: "出场时间",
    Vehicle_yard_business_type: "车场业务类型",
    Road_or_Parking_Lot: "道路/车场",
    // 车场备案
    applicant: "申请人",
    Update_time: "更新时间",
    Field_Name: "字段名",
    // 商户管理系统
    administrator_account: "管理员账号",
    Merchant_ID: "商户ID",
    Merchant_type: "商户类型",
    Business_type_of_operation: "经营业务类型",
    last_Change_time: "最后修改时间",
    Configure_ID: "配置ID",
    Invoice_platform: "发票平台",
    Merchant_code: "商户编码",
    last_Operator: "最后操作人",
    // 运营中心
    Tag_Name: "标签名称",
    Authentication_status: "认证状态",
    Binding_status: "绑定状态",
    Creation_time: "创建时间",
    arrears: "欠费金额",
    Accessibility: "可触达性",
    phone_arrears_recovery: "电话追缴次数",
    Autonomous_parking: "近日自主停车",
    security_interface_telephone: "公安接口电话",
    Bind_vehicle: "绑定车辆",
    Certified_vehicles: "认证车辆",
    Number_of_outstanding_payments: "欠费笔数",
    Number_of_phone_recovery: "电话追缴次数",
    Recovery_number: "追缴编号",
    Task_status: "任务状态",
    Task_type: "任务类型",
    Initiation_time: "发起时间",
    Submission_time: "提交时间",
    Dial_status: "拨打状态",
    Retrieve_the_person_in_charge: "追缴负责人",
    Recovery_phone: "追缴电话",
    Recovery_time: "追缴时间",
    Dialing_person: "拨打人",
    Recovery_of_actual_payment_rate: "追缴实缴率",
    Recovery_type: "追缴类型",
    Import_time: "导入时间",
    channel: "渠道",
    remarks: "备注",
    Operation_date: "操作日期",
    Alarm_time: "报警时间",
    Coupon_Name: "优惠券名称",
    Coupon_type: "优惠券类型",
    Event_Name: "活动名称",
    Activity_status: "活动状态",
    Collecting_Users: "领取用户",
    Usage_time: "使用时间",
    Display_module: "展示模块",
    Display_position: "展示位",
    Display_status: "展示状态",
    Display_time: "展示时间",
    Advertising_ID: "广告ID",
    title: "标题",
    Information_ID: "资讯ID",
    Is_it_placed_on_top: "是否置顶",
    Release_channel: "发布渠道",
    Task_ID: "任务ID",
    Push_title: "推送标题",
    Push_classification: "推送分类",
    Target_application: "目标应用",
    Push_status: "推送状态",
    Push_time: "推送时间",
    Parking_card_name: "停车卡名称",
    Is_it_for_online_sales: "是否线上销售",
    Latest_payment_time: "最近支付时间",
    Statistical_date: "统计日期",
    Product_status: "商品状态",
    Appointment_opening_date: "预约开放日期",
    Appointment_End_Date: "预约结束日期",
    Make_an_appointment_for_the_arrival_time: "预约到场时间",
    Payment_method: "支付方式",
    Channel_serial_number: "渠道流水号",
    Order_status: "订单状态",
    Refund_method: "退款方式",
    Refund_time: "退款时间",
    Third_party_serial_number: "第三方流水号",
    Transaction_type: "交易类型",
    Trading_Time: "交易时间",
    Discount_Rules: "优惠规则",
    Actual_consumption: "实际消费",
    renminbi_after: "元后",
    Discount_ratio_for_fees: "代付车费折扣比例",
    Opinion_ID: "意见ID",
    Opinion_status: "意见状态",
    Opinion_type: "意见类型",
    Last_information_time: "最后信息时间",
    Appeal_ID: "申诉ID",
    License_plate_color: "车牌颜色",
    Appeal_time: "申诉时间",
    Error_type: "报错类型",
    Work_Order_Number: "工单编号",
    source: "来源",
    Work_order_type: "工单类型",
    contacts: "联系人",
    Statistical_time: "统计时间",
    Start_page_advertising_data_source_control: "启动页广告数据源控制",
    Homepage_Rotating_Advertising_Data_Source_Control: "首页轮播广告数据源控制",
    Only_internal_data: "仅内部数据",
    All_data: "全部数据",
    Prohibit_downloading: "禁止下载",
    Allow_downloads: "允许下载",
    all: "全部",
    // 财务管理
    Processing_record_ID: "处理记录ID",
    Business_order_type: "业务订单类型",
    Business_Order_ID: "业务订单ID",
    Reason_for_modification: "修改原因",
    Confirm_time: "确认时间",
    Payment_Order_ID: "支付订单ID",
    Parking_Order_ID: "停车订单ID",
    Owing_range: "欠费区间",
    Order_modification_time: "订单修改时间",
    Deduction_time: "抵扣时间",
    Purchase_channels: "购买渠道",
    Order_Time: "订购时间",
    Native_channel_serial_number: "原生渠道流水号",
    Refund_order_number: "退款订单号",
    Refund_status: "退款状态",
    Reason_for_refund: "退款原因",
    Refund_application_time: "退款申请时间",
    Channel_type: "渠道类型",
    Flow_type: "流水类型",
    Transaction_amount: "交易金额",
    Platform_serial_number: "平台流水号",
    Invoice_number: "发票号码",
    mailbox: "邮箱",
    Invoice_header: "发票抬头",
    Invoice_type: "发票类型",
    Invoice_time: "开票时间",
    Reconciliation_status: "对账状态",
    Reconciliation_date: "对账日期",
    Abnormal_classification: "异常分类",
    Payment_Scenario: "支付场景",
    Our_serial_number: "我方流水号",
    Channel_side_serial_number: "渠道方流水号",
    Handling_method: "处理方式",
    // 系统管理
    Role_Name: "角色名称",
    Subsystem: "子系统",
    First_level_menu: "一级菜单",
    Secondary_menu: "二级菜单",
    Operation_type: "操作类型",
    Configuration_items: "配置项",
    Popular_region_names: "热门地区名称",
    Rule_label: "规则标签",
    Route_type: "路由类型",
    Basic_Information: "基础信息",
  },
  // 列表
  list: {
    // 路内
    index: '序号',
    park_name: "车场名称", // 车场名称   停车场名称   停车场
    Road_coding: "道路编码",
    address: "地址",
    Offline_time: "掉线时间",
    recovery_time: "恢复时间",
    Yard_code: "车场编码",
    On_the_job: "在职", // 在职
    quit: "离职", // 离职
    Road_name: "道路名称",
    Road_type: "道路类型",
    floor: "地上",
    under_floor: "地下",
    all_floor: "地上+地下",
    phone_number: "手机号",
    charge_type: "计费类型",
    Access_method: "接入方式",
    Mb_box: "MB云盒",
    Identification_all_in_one_machine: "识别一体机",
    Cloud_Box: "云盒",
    Main_control_machine: "主控机",
    Lane_perception_robot: "车道感知机器人",
    Belonging_NVR: "所属NVR",
    NVR_passageway: "NVR通道",
    Channel_name: "通道名称",
    park_passageway: "车场通道",
    Creation_date: "创建日期",
    is_malfunction: "是否有故障",
    On_duty_status: "在职状态",
    Manage_the_parking_lot: "管辖车场",
    Belonging_operator: "运营商名称", // 所属运营商    运营商  运营商名称
    Belonging_operator1: '运营商',
    yes: "是",
    no: "否",
    full_name: "姓名",
    add_time: "添加时间",
    account_number: "账号",
    Department_to: "所属部门",
    Platform_to_Platform: "平台对平台",
    Lightweight_box: "轻量化云盒",
    Duty_mode: "值守模式",
    Duty_AI: "AI值守",
    Duty_Someone: "有人值守",
    Duty_unmanned: "无人值守",
    Duty_long_range: "远程值守",
    region: "区域",
    parking_number: "车位数",
    parking_structure: "车场层级",
    total_parking_number: '总车位数',
    Road_hierarchy: "道路层级",
    Merchant_Name: "商户名称",
    Commercial_status: "商用状态",
    commercial: "商用",
    test_run: "试运行",
    Tobe_launched: "待上线",
    Abandoned: "废弃",
    unknown: "未知",
    operation: "操作",
    Road_section_name: '路段名称',
    Road_section_type: '物理类型',
    Berth_number: "泊位编号", // 泊位号 泊位编号
    Service_label: "服务标签",
    Charging_rules: '计费规则',
    latitude_and_longitude: '经纬度',
    Equipment_number: "设备编号",
    Equipment_yard: '设备车场',
    state: "状态",
    go_online: "上线", // 上线
    Offline: "下线", // 下线
    Equipment_yard_operation: '设备车场操作',
    update_time: '更新时间',
    Equipment_name: "设备名称",
    City_affiliation: "所属城市",
    Belonging_region: "所属区域",
    Belonging_parking_lot: "所属车场",
    IP_address: "IP地址",
    Port_number: "端口号",
    video_Port_number: "视频端口号",
    Correspondin_pole_position_number: '对应杆位号',
    Equipment_type: "设备类型",
    Pole_position_number: "杆位号",
    Rule_Name: "规则名称",
    Rule_description: "规则描述",
    Binding_time: '绑定时间',
    Binding_park: '绑定车场',
    Bind_operator: '绑定操作人',
    toll_collector_name: '收费员姓名',
    Employee_ID: "员工编号",
    PDA_account: "PDA账号",
    Berth_type: "泊位类型",
    Tidal_berth: '潮汐泊位',
    Shared_berth: '共享泊位',
    Charging_berth: '充电泊位',
    Disabled_berth: '残疾人泊位',
    Merchant: "商户",
    Free_car: "免费车",
    Temporary_vehicle: "临时车",
    Official_vehicle: "军警车",
    Monthly_rental_car: "月租车",
    Unlicensed_car: "无牌车",
    order_amount: '订单金额',
    count_order_amount: '出场时间-入场时间的计费金额',
    discount_amount: '折扣金额',
    count_amount_calculation: '折扣金额=停车卡抵扣+车场折扣',
    amount_receivable: '应收金额',
    count_amount_receivable: '订单金额-折扣金额',
    preferential_amount: '优惠金额',
    Actual_received_amount: '实收金额',
    Coupon_payment_amount: '优惠券支付金额',
    Total_refund_amount: '退款总计',
    count_total_refund_amount: '退款总计=退实付+退优惠',
    in_advance: '预',
    new: '新',
    white: '白',
    free: '免',
    deformity: '残',
    Parking_record_ID: "停车记录ID",
    Type_of_parking_lot: "车场类型",
    plate_number: "车牌号",
    Vehicle_group: "车辆组",
    // Berth_number: '泊位号',
    entry_time: '入场时间',
    leaving_time: '离场时间',
    Parking_duration: '停车时长',
    order_ID: "订单ID",
    billable_hours: '计费时间',
    payment_time: '支付时间',
    Pay_amount: '实付金额',
    Payment_Type: '支付类型',
    Payment_channels: "支付渠道",
    Payment_devices: "支付设备",
    Payer: '支付人',
    Payment_yard: '付款车场',
    Third_party_transaction_ID: '第三方流水ID',
    Refund_method: '退款方式',
    refund_reason: '退款原因',
    Actual_appearance_time: '实际出场时间',
    refund_amount: '退款金额',
    applicant: '申请人',
    Application_Time: '申请时间',
    Refund_time: '退款时间',
    Operation_type: '操作类型',
    Operation_source: '操作来源',
    Operator_or_Equipment: '操作人/设备',
    Operation_time: "操作时间",
    Operator: "操作人",
    toll_road: "收费路段",
    Payment_merchant: '支付商户',
    Merchant_affiliation: "所属商户",
    prepaid_amount: '预付金额',
    Deducted_amount: '已抵扣金额',
    Deduction_time: "抵扣时间",
    Parking_Order_ID: "停车订单ID",
    city: '城市',
    Operator_name: "运营商名称",
    Appearance_time: "出场时间",
    arrears: "欠费金额",
    Paid_amount_yuan: '已缴金额(元)',
    Owing_road: "欠费路段",
    Toll_collector: "收费员",
    capture_expends_time: '缴费时间',
    Entry_and_exit_types: '出入场类型',
    Entry_and_exit_time: '出入场时间',
    Interval_duration: '间隔时长',
    Equipment_No: '设备号',
    Berth_No: '泊位号',
    Brand_Name: "品牌名称",
    Signal_status: "信号状态",
    signal_time: '信号时间',
    Report_time: "上报时间",
    Record_number: "记录编号",
    Vehicle_type: "车辆类型",
    Admission_reporting_delay: '入场上报延时',
    Appearance_reporting_delay: '出场上报延时',
    Entry_interaction_results: '入场交互结果',
    Appearance_interaction_result: '出场交互结果',
    Entry_interaction_delay: '入场交互延时',
    Appearance_interaction_delay: '出场交互延时',
    Manage_roads: '管辖道路',
    role: "角色",
    jurisdictional_berths_number: '所辖泊位数',
    Items_received: "所领物品",
    Creation_time: "创建时间",
    pda_Manager_Name: '员工姓名',
    pda_Manager_Code: '员工编号',
    Inspector: "稽查员",
    Should_be_on_duty: '应到岗（天）',
    Normal_on_duty: '正常（天）',
    Late_or_Early_Departure: '迟到/早退（天）',
    clock_in: '打卡',
    Not_clocking_in: '未打卡',
    date: "日期",
    clock_in_time_for_work: '上班打卡时间',
    Check_in_time_after_work: '下班打卡时间',
    Working_hours: '工作时长',
    Attendance_Address: '考勤地址',
    Effective_range: '有效范围',
    No_electronic_fence: '无电子围栏',
    Is_it_configured: "是否配置",
    Shift_name: "班次名称",
    work_hours: '上班时间',
    off_work_time: '下班时间',
    effective: '有效',
    lose_efficacy: '失效',
    scheduling_for_this_month: '本月排班',
    nothing: '无',
    Ordered: '已排',
    scheduling_for_next_month: '下月排班',
    Violating_personnel: "违规人员",
    Violation_type: "违规类型",
    Name_of_violator: '违规人员姓名',
    Processing_personnel: "处理人",
    processing_time: "处理时间",
    Login_Name: "登录名",
    Total_revenue: '营收总额',
    personStatistics_content1: '营收总额=PDA现场收费金额+预付金额抵扣金额+PDA欠费追缴金额+小票缴费总额',
    PDA_on_site_fee_amount: 'PDA现场收费金额',
    personStatistics_content2: 'PDA通过【确认驶离】现场结单收上来金额',
    PDA_prepaid_amount: 'PDA预付金额',
    personStatistics_content3: 'PDA通过【车费预付】收上来预付金额（含抵扣金额）',
    PDA_overdue_collection_amount: 'PDA欠费追缴金额',
    personStatistics_content4: 'PDA通过【欠费追缴】收上来追缴金额',
    Total_amount_of_small_ticket_payment: '小票缴费总额',
    personStatistics_content5: '车主通过PDA打印小票缴费的金额总计',
    Small_ticket_on_site_payment_amount: '小票现场缴费金额',
    personStatistics_content6: '车主通过PDA打印的小票自主缴费并结单的金额',
    amount_due_for_recovery_from_ticket_arrears: '小票欠费追缴金额',
    personStatistics_content7: '车主通过PDA打印的欠费小票缴费金额',
    Number_of_entry_operation_records: '入场操作记录数',
    Record_of_appearance_operations: '出场操作记录数',
    Inspector_name: '稽查员姓名',
    Audit_report: '稽查上报（次）',
    Inspect_roads: '稽查道路（次）',
    Number_of_inspections: '被稽查次数',
    Recipient_type: '接收人类型',
    Full_platform: '全平台',
    recipient: '接收人',
    send_content: '发送内容',
    sender: '发送人',
    Sending_time: "发送时间",
    serial_number: "物品编号",
    Item_Name: "物品名称",
    Type_of_item: "物品类型",
    Item_model: "物品型号",
    idle: '空闲',
    claim: '认领',
    Recipient: "领用人",
    Already_received: '已领用',
    subtotal: '小计',
    License_plate_color: "车牌颜色",
    Car_owner_name: "车主姓名",
    classification: "分类",
    All_roads: '全部道路',
    Vehicle_status: "车辆状态",
    Stop_using: "停用",
    Enable: "启用",
    peration_date: "操作日期",
    Operation_content: '操作内容',
    Review_comments: '审核意见',
    All_parking_lots: '全部车场',
    reviewer: '审核人',
    Audit_time: '审核时间',
    Audit_status: "审核状态",
    Pending_approval: "待审核",
    Passed: '已通过',
    Rejected: '已驳回',
    Approval_Status: '审批状态',
    remarks: "备注",
    creator: '创建人',
    Bind_road_group: '绑定道路组',
    Configuration_Name: "配置名称",
    Configuration_Summary: '配置摘要',
    Parallel_roadside: '路侧平行',
    Roadside_vertical_parking_lot: '路侧垂停车场',
    Closed_parking_lot: '封闭车场',
    Semi_enclosed_parking_lot: '半封闭车场',
    Free_type: '免费类型',
    Entry_operator: '入场操作人',
    On_site_operator: '出场操作人',
    Exception: '异常类型',
    ReportPerson: "上报人",
    Number_of_exceptions: '异常次数',
    location_road: '所在道路',
    Operation_status: "运营状态",
    Equipment_status: "设备状态",
    off_line: "离线",
    Normal_number_of_devices: '正常设备数',
    Number_of_abnormal_devices: '异常设备数',
    abnormal: '异常',
    normal: '正常',
    PDA_devices: 'PDA设备',
    MS_equipment: 'MS设备',
    Gun_mechanism_semi_closed: '枪机-半封闭',
    Gun_Ball_Machine_Parallel: '枪球机-平行',
    Violation_of_ball_suspension: '违停球',
    Suspension_equipment: '垂停设备',
    Parallel_multi_gun_equipment: '平行多枪设备',
    Median_video_equipment: '中位视频设备'
  },
  // 弹窗
  pop_up: {
    Tips: "提示",
    Modify_the_remaining_parking_spaces: "修改剩余泊位数",
    Confirm: "确认",
    Cancel: "取消",
    Parking_space_number: "剩余泊位数",
    option: "选项",
    Total_number_pieces: "总数（个）",
    Temporary_parking_berth: "临停泊位",
    Fixed_berth: "固定泊位",
    Total_number_of_berths: "泊位总数",
    Remaining_number: "剩余（个）",
    Modify_the_remaining_number: "修改剩余（个）",
    Parking_Lot_Management: "停车场管理",
    Determine: "确定",
    This_operation_will_change_the_on_the_job_status_Do_you_want_to_continue: "此操作将改变在职状态, 是否继续?",
    This_operation_will_reset_the_user_password_Do_you_want_to_continue: "此操作将重置该用户密码, 是否继续?",
    Equipment_Management_Details: "设备管理详情",
    No_devices_currently_available: "暂无设备",
  },
  // 错误提示
  error_message: {
    phone_number_incorrect: "手机号格式不正确",
    Please_enter_fixed_berth: "请输入固定泊位",
    Please_enter_temporary_parking_space: "请输入临停泊位",
    The_quantity_cannot_exceed_the_total_number_of_fixed_berths: "数量不能大于固定泊位总数",
    The_quantity_cannot_exceed_the_total_number_of_temporary_parking_spaces: "数量不能大于临停泊位总数",
    The_berth_cannot_be_empty: "泊位不可为空",
    Employee_ID_cannot_contain_Chinese_characters: "员工编号不能存在汉字",
    The_length_of_the_employee_ID_should_be_within_3_16_characters: "员工编号长度需在3-16个字符之内",
    Employee_ID_includes_letters_and_numbers: "员工编号包括字母和数字",
    The_employee_ID_cannot_be_empty: "员工编号不能为空",
    Account_3_16_characters_and_cannot_be_a_pure_number: "账号3-16个字符且不能为纯数字",
    Account_cannot_be_empty: "账号不能为空",
    Cannot_be_empty: "不能为空",
    The_format_of_the_year_of_birth_is_incorrect: "出生年份格式不正确",
    Birth_year_cannot_be_greater_than_the_current_year: "出生年份不能大于当前年份",
    Birth_year_must_be_a_4_digit_number: "出生年份必须为4位数",
    The_name_must_be_in_Chinese_characters: "姓名必须为汉字",
    The_format_of_the_phone_number_is_incorrect: "手机号格式不正确",
  },
  // 输入框提示
  placeholder: {
    enter_one_user_name: "请输入用户名",
    Please_input_a_password: "请输入密码",
    cell_phone_number: "手机号",
    Please_enter_your_phone_number: "请输入手机号",
    Verification_code: "验证码",
    Please_enter_the_verification_code: "请输入验证码",
    check_sum: "校验码",
    Please_enter_the_check_sum: "请输入校验码",
    Please_enter_a_new_password: "请输入新密码",
    Please_enter_the_new_password_again: "请再次输入新密码",
    Please_enter_the_content: "请输入内容",
    Please_enter_the_parking_code: "请输入车场编码",
    Please_enter_the_parking_name: "请输入车场名称",
    Please_enter_the_road_name: "请输入道路名称",
    Please_enter_the_road_code: "请输入道路编码",
    Please_enter_the_address: "请输入地址",
    Please_enter_the_road_type: "请输入道路类型",
    Please_enter_the_charge_type: "请输入计费类型",
    Please_enter_the_equipment_name: "请输入设备名称",
    Please_enter_the_equipment_type: "请输入设备类型",
    Please_enter_the_rule_name: "请输入规则名称",
    Please_enter_the_rule_description: "请输入规则描述",
    Please_enter_the_rule_label: "请输入规则标签",
    Please_enter_the_charge_rules: "请输入计费规则",
    Please_enter_the_latitude_and_longitude: "请输入经纬度",
    Please_enter_the_Equipment_number: "请输入设备编号",
    Please_enter_the_Equipment_yard: "请输入设备车场",
    Please_enter_the_Parking_record_ID: "请输入停车记录ID",
    Please_enter_the_order_ID: "请输入订单ID",
    Please_enter_the_Third_party_transaction_ID: "请输入第三方流水ID",
    Please_enter_the_Third_party_serial_number: "请输入第三方流水号",
    Please_enter_the_Platform_serial_number: "请输入平台流水号",
    Please_enter_the_Invoice_number: "请输入发票号码",
    Please_enter_the_Invoice_header: "请输入发票抬头",
    Please_enter_the_Invoice_type: "请输入发票类型",
    please_choose: "请选择",
    please_enter_name: "请输入姓名",
    please_enter_account: "请输入账号",
    Please_enter_the_pinyin_of_the_city: "请输入城市拼音",
  },
  // 详情展示
  detail: {
    Gate_machine: "闸机",
    LED_screen: "LED屏",
    Identification_all_in_one_machine: "识别一体机",
    IP_intercom: "IP对讲",
    LED_payment_screen: "LED缴费屏",
    QR_code_scanner: "扫码器",
    Residual_screen: "余位屏",
    ETC_antenna: "ETC天线",
    Integrated_barrier_gate_machine: "道闸一体机",
    Intelligent_control_board: "智能控制板",
    Lane_monitoring: "车道监控",
    Gate_controller: "闸机控制器",
    Data_collection_equipment: "数据采集设备",
    off_line: "离线",
    on_line: "在线",
    unknown: "未知",
  },
  // 登录页
  login_page: {
    Scan_code_to_log_in: "扫码登录",
    Account_login: "账号登录",
    QR_code_invalid: "二维码已失效",
    Refresh_QR_code: "刷新二维码",
    Scan_code_successfully: "扫码成功！",
    confirm_login_on_your_phone: "请在手机上根据提示信息确认登录",
    open: "打开",
    aipark_management_App: "智慧泊车管理端APP",
    Login_successful: "登录成功",
    automatic_logon: "自动登录",
    Forgot_password: "忘记密码",
    First_login_change_your_login_password:
      "首次登录，请修改您的登录密码（大写字母+小写字母+数字+特殊字符，8-20位）",
    Obtain_verification_code: "获取验证码",
    Detected_first_login: "检测到首次登录，请绑定您的手机",
    The_IP_address_you_logged: "您本次登录的ip地址发生变化，请输入短信验证码后登录",
  },
};
