export default [{
    path: "organizationManage",
    name: "organizationManage",
    meta: {
      title: '区域管理'
    },
    component: () =>
        import('@/views/organizationManage/organizationManage')
  },
  {
    path: 'hotBusiness',
    component: () => import('@/views/hotBusiness/'),
    name: 'hotBusiness',
    meta: {
      title: '热门商圈设置'
    }
  },
  {
    path: 'specialVehicleRuleConfig',
    component: () => import('@/views/specialCar/'),
    name: 'specialVehicleRuleConfig',
    meta: {
      title: '特种车规则'
    }
  },
  {
    path: 'hotBusinessAdd',
    component: () => import('@/views/hotBusiness/add'),
    name: 'hotBusiness/hotBusinessAdd',
    meta: {
      title: '热门商圈设置添加'
    }
  },
  {
    path: "deptManage",
    name: "deptManage",
    meta: {
      title: '组织结构'
    },
    component: () =>
      import('@/views/shortMessageManage/shortMessageManage')
  },
  {
    path: "administratorManage",
    name: "administratorManage",
    meta: {
      title: '管理员管理'
    },
    component: () =>
      import('@/views/administratorManage')
  }, {
    path: "administratorManage-AE",
    name: "administratorManage/administratorManage-AE",
    meta: {
      title: '添加编辑账号'
    },
    component: () =>
      import('@/views/administratorManage/administratorManage-AE/administratorManage-AE')
  }, {
    path: "roleManage",
    name: "roleManage",
    meta: {
      title: '角色管理'
    },
    component: () =>
      import("@/views/roleManage")
  }, {
    path: "roleManageAE",
    name: "roleManage/roleManage-AE",
    meta: {
      title: '角色编辑/添加'
    },
    component: () =>
      import("@/views/roleManage/roleManage-AE/roleManage-AE")
  }, {
    // 监管平台管理员和角色管理
    path: "customerManage",
    name: "customerManage",
    meta: {
      title: '监管管理员管理'
    },
    component: () =>
      import("@/views/backstageOperation/customerManage/customerManage")
  }, {
    path: "customerManageAE",
    name: "customerManage/customerManageAE",
    meta: {
      title: '管理员管理增加编辑'
    },
    component: () =>
      import("@/views/backstageOperation/customerManage/customerManage-AE")
  }, {
    path: "customerRole",
    name: "customerRole",
    meta: {
      title: '监管角色管理'
    },
    component: () =>
      import("@/views/backstageOperation/roleManage/customerRole")
  },
  {
    path: "customerRole-AE",
    name: "customerRole/customerRole-AE",
    meta: {
      title: '角色管理增加编辑'
    },
    component: () =>
      import("@/views/backstageOperation/roleManage/customerRole-AE")
  },
  {
    path: "configPermiss",
    name: "customerRole/customerRole-AE/configPermiss",
    meta: {
      title: '配置权限'
    },
    component: () =>
      import("@/views/backstageOperation/roleManage/configPermiss")
  },
  {
    path: 'systemNotice',
    name: "systemNotice",
    component: () => import("@/views/allInformation/noticeAlarmInfo/noticeAlarmInfo"),
    meta: {
      title: '通知消息'
    }
  },
  {
    path: 'allinformationDetails',
    name: "systemNotice/allinformationDetails",
    component: () => import("@/views/allInformation/allinformationDetails"),
    meta: {
      title: '消息详情'
    }
  },
  {
    path: 'system1_e',
    name: "system1_e/allInformationConf",
    component: () => import("@/views/allInformation/allInformationConf"),
    meta: {
      title: '消息配置'
    }
  },
  {
    path: 'allInformationConfAdd',
    name: "system1_e/allInformationConfAdd",
    component: () => import("@/views/allInformation/allInformationConfAdd"),
    meta: {
      title: '消息配置添加'
    }
  },
  {
    path: 'allinformationConfDetails',
    name: "system1_e/allinformationConfDetails",
    component: () => import("@/views/allInformation/allinformationConfDetails"),
    meta: {
      title: '消息配置详情'
    }
  },
  {
    path: 'vehicleAlarmInfo',
    name: "vehicleAlarmInfo",
    component: () => import("@/views/allInformation/vehicleAlarmInfo/vehicleAlarmInfo"),
    meta: {
      title: '监控车辆报警消息'
    }
  },
  {
    path: 'equipmentAlarmInfo',
    name: "equipmentAlarmInfo",
    component: () => import("@/views/allInformation/equipmentAlarmInfo/equipmentAlarmInfo"),
    meta: {
      title: '设备报警消息'
    }
  },
  {
    path: 'equipmentAlarmInfoDetails',
    name: "equipmentAlarmInfo/equipmentAlarmInfoDetails",
    component: () => import("@/views/allInformation/equipmentAlarmInfoDetails"),
    meta: {
      title: '消息详情'
    }
  },
  {
    path: 'systemConfig_holiday',
    name: "systemConfig_holiday",
    component: () => import("@/views/calendarManage/CalendarConfig"),
    meta: {
      title: '节假日配置'
    }
  },
  {
    path: 'businessParamConfig',
    name: 'businessParamConfig',
    component: () => import('@/views/businessParamConfig/index'),
    meta: {
      title: '业务配置'
    }
  },
   {
    path: 'edrsRecord',
    name: "edrsRecord",
    component: () => import("@/views/dataTransmission/index"),
    meta: {
      title: '数据传输记录'
    }
  },
   {
    path: 'edrsRecordDetail',
    name: "edrsRecord/edrsRecordDetail",
    component: () => import("@/views/dataTransmission/detail"),
    meta: {
      title: '数据传输记录'
    }
  }
]
