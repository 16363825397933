export default [
  // 广告管理
  {
    path: 'advertManage',
    name: 'advertManage',
    meta: {
      title: '广告管理'
    },
    component: () =>
      import('@/views/advertManage')
  },
  // 广告管理-编辑
  {
    path: 'advertManageEdit',
    name: 'advertManage/advertManageEdit',
    meta: {
      title: '广告编辑'
    },
    component: () =>
      import('@/views/advertManage/edit')
  },
  // 广告管理-详情
  {
    path: 'advertManageDetail',
    name: 'advertManage/advertManageDetail',
    meta: {
      title: '广告详情'
    },
    component: () =>
      import('@/views/advertManage/detail')
  },
  //
  // 分类管理
  {
    path: 'categoryManage',
    name: 'advertManage/categoryManage',
    meta: {
      title: '分类管理'
    },
    component: () =>
      import('@/views/categoryManage')
  },
  // 推送管理
  {
    path: 'appPushManage',
    name: 'appPushManage',
    meta: {
      title: '推送管理'
    },
    component: () =>
      import('@/views/messageManage/appPushManage/appPushManage')
  },
  // 创建推送
  {
    path: 'pushEdit',
    name: 'appPushManage/pushEdit',
    meta: {
      title: '创建推送'
    },
    component: () =>
      import('@/views/messageManage/appPushManage/pushEdit')
  },

  {
    path: 'question',
    name: 'question',
    meta: {
      title: '常见问题'
    },
    component: () =>
      import('@/views/commonProblem/commonProblem')
  },
  {
    path: 'commonInfo',
    name: 'question/commonInfo',
    meta: {
      title: '常见问题'
    },
    component: () =>
      import('@/views/commonProblem/quesInfo')
  },

  // 推送详情
  {
    path: 'pushDetail',
    name: 'appPushManage/pushDetail',
    meta: {
      title: '推送详情'
    },
    component: () =>
      import('@/views/messageManage/appPushManage/pushDetail')
  },
  {
    // 反馈建议
    path: 'complaintsProposals',
    name: 'complaintsProposals',
    meta: {
      title: '反馈建议'
    },
    component: () =>
      import('@/views/memberManage/complaintsProposals')
  },
  {
    // 反馈建议详情
    path: 'feedbackDetail',
    name: 'complaintsProposals/feedbackDetail',
    meta: {
      title: '意见详情'
    },
    component: () =>
      import('@/views/memberManage/feedbackDetail')
  },
  {
    // 申诉管理
    path: 'complaintRecord',
    name: 'complaintRecord',
    meta: {
      title: '申诉管理'
    },
    component: () =>
      import('@/views/memberManage/complaintRecord')
  },
  {
    // 申诉管理详情
    path: 'complaintDetail',
    name: 'complaintRecord/complaintDetail',
    meta: {
      title: '申诉详情'
    },
    component: () =>
      import('@/views/memberManage/complaintDetail')
  },
  // 用户信息(原会员信息)
  {
    path: 'memberInfo',
    name: 'memberInfo',
    meta: {
      title: '用户信息'
    },
    component: () =>
      import('@/views/memberinformation/memberinformation')
  },
  // 用户详情(原会员信息详情)
  {
    path: 'memberinformationdetail',
    name: 'memberInfo/memberinformationdetail',
    meta: {
      title: '用户详情'
    },
    component: () =>
      import('@/views/memberinformation/memberinformationdetail')
  },
  // 用户详情(标签)
  {
    path: 'memberinformationdetail2',
    name: 'userTag/labelManageDetail/memberinformationdetail',
    meta: {
      title: '用户详情(标签)'
    },
    component: () =>
      import('@/views/memberinformation/memberinformationdetail')
  },
  // 用户认证(原会员身份认证)
  {
    path: 'memberIdcardAuth',
    name: 'memberIdcardAuth',
    meta: {
      title: '用户认证'
    },
    component: () =>
      import('@/views/memberIdcardAuth/memberIdcardAuth')
  },
  // 用户认证详情(原会员身份认证详情)
  {
    path: 'identityauthenticationDetail',
    name: 'memberIdcardAuth/identityauthenticationDetail',
    meta: {
      title: '用户认证详情'
    },
    component: () =>
      import('@/views/memberIdcardAuth/membershipdetail')
  },
  // 标签管理
  {
    path: 'userTag',
    name: 'userTag',
    meta: {
      title: '用户标签'
    },
    component: () =>
      import('@/views/memberinformation/labelManage')
  },
  // 标签管理详情
  {
    path: 'labelManageDetail',
    name: 'userTag/labelManageDetail',
    meta: {
      title: '标签详情'
    },
    component: () =>
      import('@/views/memberinformation/labelManageDetail')
  },
  // 车辆信息
  {
    path: 'carManager',
    name: 'carManager',
    meta: {
      title: '车辆信息'
    },
    component: () =>
      import('@/views/carManager/carManager')
  },
  // 车辆信息详情
  {
    path: 'VehicleininfoDetail',
    name: 'carManager/VehicleininfoDetail',
    meta: {
      title: '车辆信息详情'
    },
    component: () =>
      import('@/views/carManager/carManagerDetail')
  },
  // 车辆信息详情(用户)
  {
    path: 'VehicleininfoDetail2',
    name: 'memberInfo/memberinformationdetail/VehicleininfoDetail',
    meta: {
      title: '车辆信息详情(用户)'
    },
    component: () =>
      import('@/views/carManager/carManagerDetail')
  },
  // 车辆认证(原车辆行驶本认证)
  {
    path: 'memberCarAuth',
    name: 'memberCarAuth',
    meta: {
      title: '车辆认证'
    },
    component: () =>
      import('@/views/memberCarAuth/memberCarAuth')
  },
  // 车辆认证详情(原车辆行驶本认证详情)
  {
    path: 'DrivingcertificationDetail',
    name: 'memberCarAuth/DrivingcertificationDetail',
    meta: {
      title: '车辆信息详情'
    },
    component: () =>
      import('@/views/memberCarAuth/memberCarAuthDetail')
  },
  // 广告控制
  {
    path: 'advertControl',
    name: 'advertControl',
    meta: {
      title: '广告控制'
    },
    component: () =>
      import('@/views/cloudControl')
  },
  // 微信公众菜单配置
  {
    path: 'wechatMenuConfig',
    name: 'wechatMenuConfig',
    meta: {
      title: '微信公众菜单配置'
    },
    component: () =>
      import('@/views/cloudControl/wxmenu')
  },
  // 微信公众菜单配置
  {
    path: 'appHomeConfig',
    name: 'appHomeConfig',
    meta: {
      title: '移动端首页功能配置'
    },
    component: () =>
      import('@/views/cloudControl/mobileHomeManage/mobileHomeManage')
  },
  {
    path: 'appmanager',
    name: 'appmanager',
    meta: {
      title: 'app管理'
    },
    component: () =>
      import('@/views/appManage/AppManage')
  },
  {
    path: '/createApp',
    component: resolve => require(['@/views/appManage/createApp'], resolve),
    name: 'appmanager/createApp',
    meta: {
      title: '创建新应用'
    },
  },
  {
    path: '/updateIOS',
    component: resolve => require(['@/views/appManage/updateIOS'], resolve),
    name: 'appmanager/updateIOS',
    meta: {
      title: '更新版本IOS'
    },
  },
  {
    path: '/updateIOSRecord',
    component: resolve => require(['@/views/appManage/updateIOSRecord'], resolve),
    name: 'appmanager/updateIOSRecord',
    meta: {
      title: '更新版本IOS记录'
    },
  },
  {
    path: '/updateAndroid',
    component: resolve => require(['@/views/appManage/updateAndroid'], resolve),
    name: 'appmanager/updateAndroid',
    meta: {
      title: '更新版本Android'
    },
  },
  {
    path: '/updateAndroidRecord',
    component: resolve => require(['@/views/appManage/updateAndroidRecord'], resolve),
    name: 'appmanager/updateAndroidRecord',
    meta: {
      title: '更新版本Android记录'
    },
  },
  // {
  //   path: '/userList',
  //   component: resolve => require(['@/views/userManage/userList'], resolve),
  //   name: '用户列表'
  // },
  // {
  //   path: '/userAE',
  //   component: resolve => require(['@/views/userManage/userAE'], resolve),
  //   name: '编辑用户'
  // },
  {
    path: '/patchAndroidRecord',
    component: resolve => require(['@/views/appManage/patchAndroidRecord'], resolve),
    name: 'appmanager/patchAndroidRecord',
    meta: {
      title: 'Android补丁列表'
    },
  },
  {
    path: '/patchUpdateAndroid',
    component: resolve => require(['@/views/appManage/patchUpdateAndroid'], resolve),
    name: 'appmanager/patchUpdateAndroid',
    meta: {
      title: '添加补丁包'
    },
  },
  // 资讯管理
  {
    path: 'information',
    name: 'information',
    meta: {
      title: '资讯管理'
    },
    component: () =>
      import('@/views/information/information')
  },
  // 资讯编辑
  {
    path: 'infoEdit',
    name: 'information|infoEdit',
    meta: {
      title: '资讯编辑'
    },
    component: () =>
      import('@/views/information/infoEdit')
  },
  // 资讯详情
  {
    path: 'infoDetail',
    name: 'information|infoDetail',
    meta: {
      title: '资讯详情'
    },
    component: () =>
      import('@/views/information/infoDetail')
  },
  // 分类管理
  {
    path: 'classification',
    name: 'information/classification',
    meta: {
      title: '分类管理'
    },
    component: () =>
      import('@/views/information/infoManage')
  },
  // 优惠券规则
  {
    path: 'couponRules',
    name: 'couponRules',
    meta: {
      title: '优惠券规则'
    },
    component: () =>
      import('@/views/couponRule/couponRuleList')
  },
  // 优惠券规则-编辑
  {
    path: 'addEditCoupon',
    name: 'couponRules|addEditCoupon',
    meta: {
      title: '编辑优惠券规则'
    },
    component: () =>
      import('@/views/couponRule/addEditCoupon')
  },
  // 优惠券规则-详情
  {
    path: 'couponDetail',
    name: 'couponRules|couponDetail',
    meta: {
      title: '优惠券规则详情'
    },
    component: () =>
      import('@/views/couponRule/addEditCoupon')
  },
  // 活动专题
  {
    path: 'activityManagement',
    name: 'activityManagement',
    meta: {
      title: '活动专题'
    },
    component: () =>
      import('@/views/activityManagement/activityManagement')
  },
  // 活动专题-添加
  {
    path: 'activityManagementadd',
    name: 'activityManagement|activityManagementadd',
    meta: {
      title: '编辑活动专题'
    },
    component: () =>
      import('@/views/activityManagement/activityManagementadd')
  },
  // 活动专题-查看
  {
    path: 'activityManagementsee',
    name: 'activityManagement|activityManagementsee',
    meta: {
      title: '查看活动专题'
    },
    component: () =>
      import('@/views/activityManagement/activityManagementsee')
  },
  // 活动专题-模版配置
  {
    path: 'Yconfiguration',
    name: 'activityManagement|Yconfiguration',
    meta: {
      title: '模版配置'
    },
    component: () =>
      import('@/views/activityManagement/Yconfiguration')
  },
  // 条件派发
  {
    path: 'couponConditionDistribute',
    name: 'couponConditionDistribute',
    meta: {
      title: '条件派发'
    },
    component: () =>
      import('@/views/conditionalDistribution/conditionalDistribution')
  },
  // 条件派发-添加
  {
    path: 'conditionalDistributionadd',
    name: 'couponConditionDistribute|conditionalDistributionadd',
    meta: {
      title: '编辑条件派发'
    },
    component: () =>
      import('@/views/conditionalDistribution/conditionalDistributionadd')
  },
  // 条件派发-查看
  {
    path: 'conditionalDistributionsee',
    name: 'couponConditionDistribute|conditionalDistributionsee',
    meta: {
      title: '查看条件派发'
    },
    component: () =>
      import('@/views/conditionalDistribution/conditionalDistributionsee')
  },
  // 手动派发
  {
    path: 'couponDistribute',
    name: 'couponDistribute',
    meta: {
      title: '手动派发'
    },
    component: () =>
      import('@/views/couponDistribute/couponDistribute')
  },
  // 手动派发-新增
  {
    path: 'addCouponDis',
    name: 'couponDistribute|addCouponDis',
    meta: {
      title: '手动派发新增'
    },
    component: () =>
      import('@/views/couponDistribute/addCouponDis')
  },
  // 手动派发-详情
  {
    path: 'couponDisDetail',
    name: 'couponDistribute|couponDisDetail',
    meta: {
      title: '手动派发新增'
    },
    component: () =>
      import('@/views/couponDistribute/couponDisDetail')
  },
  // 车场折扣
  {
    path: 'yardDiscount',
    name: 'yardDiscount',
    meta: {
      title: '车场折扣'
    },
    component: () =>
      import('@/views/yardDiscount')
  },
  // 车场折扣-编辑
  {
    path: 'yardDiscountEdit',
    name: 'yardDiscount|yardDiscountEdit',
    meta: {
      title: '车场折扣编辑'
    },
    component: () =>
      import('@/views/yardDiscount/edit')
  },
  // 车场折扣-查看
  {
    path: 'yardDiscountDetail',
    name: 'yardDiscount|yardDiscountDetail',
    meta: {
      title: '车场折扣查看'
    },
    component: () =>
      import('@/views/yardDiscount/edit')
  },
]
