export default [
  {
    path: "arrearsOrder",
    name: "arrearsOrder",
    component: () => import("@/views/recoveryOfArrears/arrearageOrder/index"),
    meta: {
      title: "欠费订单",
    },
  },
  {
    path: "arrearsCar",
    name: "arrearsCar",
    component: () => import("@/views/recoveryOfArrears/arrearageVehicle/index"),
    meta: {
      title: "欠费车辆",
    },
  },
  {
    path: "arrearsUser",
    name: "arrearsUser",
    component: () => import("@/views/recoveryOfArrears/arrearageUser/index"),
    meta: {
      title: "欠费用户",
    },
  },
  {
    path: "recoverTask",
    name: "recoverTask",
    component: () => import("@/views/recoveryOfArrears/taskRecovery/index"),
    meta: {
      title: "任务追缴",
    },
  },
  {
    path: "smsRecoverRecord",
    name: "smsRecoverRecord",
    meta: {
      title: "短信记录",
    },
    component: () => import("@/views/recoveryOfArrears/recoveredRecords/messageRecord"),
  },
  {
    path: "phoneRecoverRecord",
    name: "phoneRecoverRecord",
    meta: {
      title: "电话记录",
    },
    component: () => import("@/views/recoveryOfArrears/recoveredRecords/telephoneRecord"),
  },
  {
    path: "arrearsRecoverTaskReport",
    name: "arrearsRecoverTaskReport",
    meta: {
      title: "任务报表",
    },
    component: () => import("@/views/recoveryOfArrears/recoveredForms/index"),
  },
  {
    path: "arrearsThirdImportPhone",
    name: "arrearsThirdImportPhone",
    meta: {
      title: "三方导入电话",
    },
    component: () => import("@/views/recoveryOfArrears/telephoneManagement/importPhone"),
  },
  {
    path: "arrearsInvalidPhone",
    name: "arrearsInvalidPhone",
    meta: {
      title: "无效电话查询",
    },
    component: () => import("@/views/recoveryOfArrears/telephoneManagement/InvalidPhone"),
  },
  {
    path: "bigDebtWarning",
    name: "bigDebtWarning",
    meta: {
      title: "大额欠费预警",
    },
    component: () => import("@/views/recoveryOfArrears/largeAmount/largeAmount"),
  },
  {
    path: "taskRecoveryOperation",
    name: "recoverTask/operation",
    meta: {
      title: "任务详情",
    },
    component: () => import("@/views/recoveryOfArrears/taskRecovery/operation"),
  },
  {
    path: "auditRecoverTask",
    name: "auditRecoverTask",
    meta: {
      title: "任务审核",
    },
    component: () => import("@/views/recoveryOfArrears/taskAudit/index"),
  },
  {
    path: "taskAuditOperation",
    name: "auditRecoverTask/operation",
    meta: {
      title: "任务详情",
    },
    component: () => import("@/views/recoveryOfArrears/taskRecovery/operation"),
  },
  {
    path: "manualOutboundCall",
    name: "manualOutboundCall",
    meta: {
      title: "人工外呼",
    },
    component: () => import("@/views/recoveryOfArrears/manualOutboundCall/index"),
  },
];
