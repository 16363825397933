import * as utils from '@/common/js/utils.js';

const state = {
  currentTabs: [],
  elTabs: {},
  // cacheTabs: []
};
const actions = {
  ADD_TAB({
    commit,
    state
  }, payload) {
    let {
      neVal,
      oldVal
    } = payload;
    // 如果是 刷新页面
    if ((!oldVal && state.currentTabs.length !== 0)) return;
    // 获取根路径
    let oldTabPath = oldVal && oldVal.name && utils.getPath(oldVal.name);
    let newTabPath = utils.getPath(neVal.name, oldTabPath);
    // 如果是 同一个页面
    if (oldVal) {
      if (neVal.path === oldVal.path) {
        return;
      }
      if (newTabPath == oldTabPath) {
        // 同一个选项卡
        switch (true) {
          // case neVal.name.indexOf(oldVal.name) > -1:
          case utils.ifInclude(neVal.name, oldVal.name):
            commit('ADD_CHILD', {
              path: newTabPath,
              fullPath: neVal.fullPath
            });
            break;
            // case oldVal.name.indexOf(neVal.name) > -1:
          case utils.ifInclude(oldVal.name, neVal.name):
            commit('DELETE_CHILD', {
              path: newTabPath,
              fullPath: neVal.fullPath
            });
            break;
          default:
        }
      } else {
        // 选项卡切换
        commit('ADD_NEW_TAB', {
          path: newTabPath,
          route: neVal
        });
      }
    } else {
      // 选项卡切换
      commit('ADD_NEW_TAB', {
        path: newTabPath,
        route: neVal
      });
    }

    // commit('ADD_NEW_TAB', payload);
  },
  SET_TABS({
    commit
  }, data) {
    commit('SET_NEW_TABS', data);
  },
  CLOSE_TAB({
    commit
  }, path) {
    commit('CLOSE_SPEC_TAB', path);
  },
  REMOVE_TABS({
    commit
  }) {
    commit('REMOVE_ALL_TABS');
  },
  // 修改标题/页面内tab
  setTitleAndTab({ commit }, payload) {
    commit('SET_TITLE_AND_TAB', payload)
  },
};
const mutations = {
  DELETE_CHILD(state, payload) {
    let tab = utils.ifDupl(state.currentTabs, payload.path);
    tab.children.pop();
  },
  ADD_CHILD(state, payload) {
    let tab = utils.ifDupl(state.currentTabs, payload.path);
    tab.children.push(payload.fullPath);
  },
  ADD_NEW_TAB(state, payload) {
    if (utils.ifDupl(state.currentTabs, payload.path)) return;
    state.currentTabs.push({
      title: payload.route.meta.title,
      path: payload.route.path,
      children: [payload.route.fullPath]
    });
  },
  CLOSE_SPEC_TAB(state, path) {
    state.currentTabs = state.currentTabs.filter(v => v.path != path);
  },
  SET_NEW_TABS(state, data) {
    state.currentTabs = data;
  },
  REMOVE_ALL_TABS(state) {
    state.currentTabs = [];
  },
  ADD_CACHE_TAB(state, tabInfo) {

  },
  // 动态修改currentTabs中特定路由的标题
  SET_TITLE_AND_TAB({ currentTabs, elTabs }, { path: p, title, tab = 'order' }) {
    elTabs[p] = tab
    let currentRouter = currentTabs.find(({ path }) => path === p)
    if (currentRouter) currentRouter.title = title
  }
};
const getters = {
  cacheTabs(state) {
    return state.currentTabs.reduce((mock, cur) => {
      mock.push(cur.children[0].slice(1));
      return mock;
    }, [])
  }
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
