const routes = [{
  path: '/',
  name: 'login',
  component: () =>
    import('../views/login'),
}, {
  path: '/findPsd',
  name: 'findPsd',
  component: () =>
    import('../views/findPsd'),
}, {
  path: '/portal',
  name: 'portal',
  component: () =>
    import('../views/portal'),
}, {
  path: '/downloadboss',
  name: 'downloadboss',
  component: () =>
    import('../views/login/code'),
}, {
  path: '/daping',
  name: 'daping',
  component: () =>
    import('@/views/homeOverviewDP/indexMap'),
}, {
  path: '/java_home_daping',
  name: 'java_home_daping',
  component: () =>
    // import('@/views/home/newHome'),
    // import('@/views/home/homeGridLayout'),
    // import('@/views/javaParkinganalysis/homeOverview/indexMap'), // java大屏
    import('@/views/homeOverview/indexMap'),  // 大数据大屏
  meta: {
    title: '首页'
  }
}
];
export default routes;
