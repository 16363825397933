export default [
  {
    path: 'writeOffDetail',
    name: 'writeOffDetail',
    meta: {
      title: '优惠券核销明细'
    },
    component: () =>
      import('@/views/couponRuleCount/writeDetails')
  },
  {
    path: 'writeOffStatistics',
    name: 'writeOffStatistics',
    meta: {
      title: '优惠券核销统计'
    },
    component: () =>
      import('@/views/couponRuleCount/writeStatistics')
  },
  {
    path: 'errorCorrection',
    name: 'errorCorrection',
    meta: {
      title: '车场纠错'
    },
    component: () =>
      import('@/views/couponRuleCount/depoErrorCorrection')
  },
  {
    path: 'depoErrorDetail',
    name: 'errorCorrection/depoErrorDetail',
    meta: {
      title: '车场纠错列表查看详情'
    },
    component: () =>
      import('@/views/couponRuleCount/depoErrorDetail')
  },
  {
    path: 'systemLog',
    name: 'systemLog',
    meta: {
      title: '系统日志'
    },
    component: () =>
      import('@/views/couponRuleCount/systemLog')
  },
  {
    path: 'bookManagement',
    name: 'bookManagement',
    meta: {
      title: '预约管理'
    },
    component: () =>
      import('@/views/couponRuleCount/reserveParking/index')
  },
  {
    path: 'addBookingManage',
    name: 'bookManagement/addBookingManage',
    meta: {
      title: '预约管理添加'
    },
    component: () =>
      import('@/views/couponRuleCount/reserveParking/addBookingManage')
  },
  {
    path: 'seeBKManageDetails',
    name: 'bookManagement/seeBKManageDetails',
    meta: {
      title: '预约管理查看'
    },
    component: () =>
      import('@/views/couponRuleCount/reserveParking/seeBKManageDetails')
  },
  {
    path: "rechargeAndRefundRecord",
    name: "rechargeAndRefundRecord",
    meta: {
      title: '充值&退款记录'
    },
    component: () =>
      import('@/views/couponRuleCount/rechargeAndRefundRecord')
  },
  {
    path: "discountRule",
    name: "discountRule",
    meta: {
      title: '优惠规则'
    },
    component: () =>
      import('@/views/couponRuleCount/discountRule')
  },
  {
    path: 'staggeredManage',
    name: 'staggeredManage',
    component: () =>
      import('@/views/couponRuleCount/staggeredPeakManage/staggeredManage/staggerdManage'),
    meta: {
      title: '错峰管理'
    }
  },
  {
    path: 'staggerdManageAE',
    component: () =>
      import('@/views/couponRuleCount/staggeredPeakManage/staggeredManage/staggerdManageAE'),
    name: 'staggeredManage/staggerdManageAE',
    meta: {
      title: '错峰商品添加编辑'
    }
  },
  {
    path: 'staggerdManageDetail',
    component: () =>
      import('@/views/couponRuleCount/staggeredPeakManage/staggeredManage/staggerdManageDetail'),
    name: 'staggeredManage/staggerdManageDetail',
    meta: {
      title: '错峰商品详情'
    }
  },

  {
    path: 'staggeredOrder',
    name: 'staggeredOrder',
    component: () =>
      import('@/views/couponRuleCount/staggeredPeakManage/staggeredOrder2/staggeredOrder'),
    meta: {
      title: '错峰订单'
    }
  },
  {
    path: 'staggeredOrderDetail2',
    component: () =>
      import('@/views/couponRuleCount/staggeredPeakManage/staggeredOrder2/staggeredOrderDetail'),
    name: 'staggeredOrder/staggeredOrderDetail',
    meta: {
      title: '错峰订单详情'
    }
  },
  {
    path: 'staggeredOrderAdd',
    component: () =>
      import('@/views/couponRuleCount/staggeredPeakManage/staggeredOrder2/staggeredOrderAdd'),
    name: 'staggeredOrder/staggeredOrderAdd',
    meta: {
      title: '错峰订单'
    }
  },

  // tab中
  {
    path: 'staggeredOrderDetail',
    component: () =>
      import('@/views/couponRuleCount/staggeredPeakManage/staggeredOrder/staggeredOrderDetail'),
    name: 'finParkingCardManage/staggeredOrderDetail',
    meta: {
      title: '错峰订单详情'
    }
  },
  {
    path: 'finStaggeredOrder',
    name: 'finStaggeredOrder',
    component: () =>
      import('@/views/couponRuleCount/staggeredPeakManage/staggeredOrder/staggeredOrder'),
    meta: {
      title: '错峰订单'
    }
  },
  {
    path: 'staggeredOrderAE',
    component: () =>
      import('@/views/couponRuleCount/staggeredPeakManage/staggeredOrder/staggeredOrderAE'),
    name: 'staggeredOrder/staggeredOrderAE',
    meta: {
      title: '修改错峰订单'
    }
  },
  {
    path: 'staggeredStatistics',
    component: () =>
      import('@/views/couponRuleCount/staggeredPeakManage/staggeredStatistics/staggeredStatistics'),
    name: 'staggeredStatistics',
    meta: {
      title: '错峰统计'
    }
  },
  {
    path: 'bookingOrder',
    component: () =>
      import('@/views/couponRuleCount/bookingOrder2/index'),
    name: 'bookingOrder',
    meta: {
      title: '预约订单'
    }
  },
  {
    path: 'bookingOrderDetails2',
    component: () =>
      import('@/views/couponRuleCount/bookingOrder2/bookingOrderDetails'),
    name: 'bookingOrder/bookingOrderDetails',
    meta: {
      title: '订单详情'
    }
  },
  {
    path: 'bookingOrderModify2',
    component: () =>
      import('@/views/couponRuleCount/bookingOrder2/bookingOrderModify'),
    name: 'bookingOrder/bookingOrderModify',
    meta: {
      title: '修改预约订单'
    }
  },
  {
    path: 'finBookOrder',
    component: () =>
      import('@/views/couponRuleCount/bookingOrder/index'),
    name: 'finBookOrder',
    meta: {
      title: '预收订单查询'
    }
  },
  {
    path: 'yujiaoOrder',
    component: () =>
      import('@/views/couponRuleCount/bookingOrder/index'),
    name: 'yujiaoOrder',
    meta: {
      title: '预约订单'
    }
  },
  {
    path: 'bookingOrderDetails',
    component: () =>
      import('@/views/couponRuleCount/bookingOrder2/bookingOrderDetails'),
    name: 'finParkingCardManage/bookingOrderDetails',
    meta: {
      title: '订单详情'
    }
  },
  {
    path: 'bookingOrderModify',
    component: () =>
      import('@/views/couponRuleCount/bookingOrder2/bookingOrderModify'),
    name: 'finParkingCardManage/bookingOrderModify',
    meta: {
      title: '修改预约订单'
    }
  },
  {
    path: 'reservationRefund',
    component: () =>
      import('@/views/couponRuleCount/bookRefund/index'),
    name: 'reservationRefund',
    meta: {
      title: '预约退款记录'
    }
  },
  {
    // 迁移停车卡订单
    path: "parkCardPayOrder",
    name: "parkCardPayOrder",
    meta: {
      title: '停车卡订单'
    },
    component: () =>
      import('@/views/couponRuleCount/financialParkingCardManage')
  },
  {
    // 迁移停车卡订单
    path: "financialParkCardAddEdit",
    name: "parkCardPayOrder/financialParkCardAddEdit",
    meta: {
      title: '停车卡添加'
    },
    component: () =>
      import('@/views/couponRuleCount/financialParkingCardManage/parkCardAddEdit')
  },
  {
    // 迁移停车卡订单
    path: "financialParkCardDetail",
    name: "parkCardPayOrder/financialParkCardDetail",
    meta: {
      title: '停车卡详情'
    },
    component: () =>
      import('@/views/couponRuleCount/financialParkingCardManage/parkCardDetail')
  },
  // acs2.4新增
  // {
  //   path: 'monitorDevice',
  //   name: 'monitorDevice',
  //   meta: {
  //     title: '视频监控点'
  //   },
  //   component: () =>
  //     import('@/views/outParkManage/monitorDeviceList/index')
  // },
  {
    path: 'poleLiftingRecord',
    name: 'poleLiftingRecord',
    meta: {
      title: '抬杆记录'
    },
    component: () =>
      import('@/views/outParkManage/exceptionManagement/manualOpenGate')
  },
  {
    path: 'shortMessageManage',
    name: 'shortMessageManage',
    meta: {
      title: '发送短信',
    },
    component: () =>
      import('@/views/outParkManage/shortMessageManage/shortMessageManage')
  },
  {
    path: 'memberSmsTask',
    name: 'memberSmsTask',
    meta: {
      title: '任务管理'
    },
    component: () =>
      import('@/views/outParkManage/shortMessageManage/shortMessageList')
  },
  {
    path: 'sendSMS',
    name: 'shortMessageManage/sendSMS',
    meta: {
      title: '发送短信'
    },
    component: () =>
      import('@/views/outParkManage/shortMessageManage/sendSMS')
  },
  // acs2.5新增
  // {
  //   path: 'dataalarMessage',
  //   name: 'dataalarMessage',
  //   meta: {
  //     title: '车辆报警'
  //   },
  //   component: () =>
  //     import('@/views/dataalarmTotal/dataalarMessage'),
  // },
  // {
  //   path: 'carpoliceLong',
  //   component: () =>
  //     import('@/views/dataalarmTotal/carpoliceLong'),
  //   name: 'dataalarMessage/carpoliceLong',
  //   meta: {
  //     title: '车辆报警套牌车'
  //   }
  // },

  // {
  //   path: '/carpoliceDeck',
  //   component: () =>
  //     import('@/views/dataalarmTotal/carpoliceDeck'),
  //   name: 'dataalarMessage/carpoliceDeck',
  //   meta: {
  //     title: '车辆报警超长停车'
  //   }
  // },
  {
    path: 'plateAlarm',
    name: 'plateAlarm',
    meta: {
      title: '车牌遮挡报警'
    },
    component: () =>
      import('@/views/dataalarmTotal/carAlert'),
  },
  {
    path: 'plateAlertDetail',
    name: 'plateAlarm/plateAlertDetail',
    meta: {
      title: '车牌遮挡报警详情'
    },
    component: () =>
      import('@/views/dataalarmTotal/carAlert/detail'),
  },

  {
    path: 'carAlarmSettings',
    name: 'carAlarm/carAlarmSettings',
    meta: {
      title: '监控车辆设置'
    },
    component: () =>
      import('@/views/dataalarmTotal/monitorVehicle/monitorVehicle'),
  },
  {
    path: 'monitorVehicleDetail',
    name: 'carAlarm/monitorVehicleDetail',
    meta: {
      title: '监控车辆详情'
    },
    component: () =>
      import('@/views/dataalarmTotal/monitorVehicle/monitorVehicleDetail'),
  },
  {
    path: 'carAlarm',
    name: 'carAlarm',
    meta: {
      title: '监控车辆报警'
    },
    component: () =>
      import('@/views/dataalarmTotal/monitorVehicleAlert'),
  },
  {
    path: 'complainManage',
    name: 'complainManage',
    meta: {
      title: '客诉工单'
    },
    component: () => import('@/views/customerComplain/customerService/complainManage'),
  },
  {
    path: 'addComplain',
    name: 'complainManage/addComplain',
    meta: {
      title: '创建客诉'
    },
    component: () => import('@/views/customerComplain/customerService/addComplain'),
  },
  {
    path: 'customerDetails',
    name: 'complainManage/customerDetails',
    meta: {
      title: '客诉详情'
    },
    component: () => import('@/views/customerComplain/customerService/customerDetails'),
  },
  {
    path: 'disposeComplain',
    name: 'complainManage/disposeComplain',
    meta: {
      title: '客诉处理'
    },
    component: () => import('@/views/customerComplain/customerService/disposeComplain'),
  },
  {
    path: 'dataSta',
    name: 'dataSta',
    meta: {
      title: '客诉统计'
    },
    component: () => import('@/views/customerComplain/dataSta/dataSta'),
  },
  // {
  //   path: '/complainDetails',
  //   component: import('complainDetails/complainDetails'),
  //   name: '客诉明细'
  // },
  {
    path: 'complainSta',
    name: 'complainSta',
    meta: {
      title: '工作统计'
    },
    component: () => import('@/views/customerComplain/personSta/index'),
  },
  {
    path: 'personSta',
    name: 'personSta',
    meta: {
      title: '工作统计'
    },
    component: () => import('@/views/customerComplain/personSta/personSta'),
  },
  // {
  //   path: '/analysecomplain',
  //   component: import('analysecomplain/analysecomplain'),
  //   name: '客诉分析'
  // },
  // {
  //   path: '/analysecomplainDetails',
  //   component: import('analysecomplain/analysecomplainDetails'),
  //   name: '客诉详情'
  // },
  {
    path: 'complainFirsts',
    name: 'complainFirsts',
    meta: {
      title: '客诉一级分类'
    },
    component: () => import('@/views/customerComplain/customerService/knowledge/levelOne'),
  },
  {
    path: 'levelOneAddType',
    name: 'complainManage/levelOneAddType',
    meta: {
      title: '一级分类管理添加'
    },
    component: () => import('@/views/customerComplain/addType/addTypeOne'),
  },
  {
    path: 'seeTypeOne',
    name: 'complainManage/seeTypeOne',
    meta: {
      title: '一级分类管理修改'
    },
    component: () => import('@/views/customerComplain/addType/seeTypeOne'),
  },
  {
    path: 'complainSeconds',
    name: 'complainSeconds',
    meta: {
      title: '客诉二级分类'
    },
    component: () => import('@/views/customerComplain/customerService/knowledge/levelTwo'),
  },
  {
    path: 'levelTwoAddType',
    name: 'complainManage/levelTwoAddType',
    meta: {
      title: '二级分类管理添加'
    },
    component: () => import('@/views/customerComplain/addType/addTypeTwo'),
  },
  {
    path: 'seeTypeTwo',
    name: 'complainManage/seeTypeTwo',
    meta: {
      title: '二级分类管理修改'
    },
    component: () => import('@/views/customerComplain/addType/seeTypeTwo'),
  },
  // {
  //   path: '/knowPointManagement',
  //   component: import('customerService/knowledge/knowPointManagement'),
  //   name: '知识点管理'
  // },
  {
    path: 'parkingOperationAnalysis',
    name: 'parkingOperationAnalysis',
    meta: {
      title: '停车场分析报表'
    },
    component: () =>
      import('@/views/statisticalAnalysis/parkingStatisticalAnalysis/parkingOperationAnalysis'),
  },
  {
    path: "parkingHot",
    name: "parkingHot",
    component: () => import("@/views/statisticalAnalysis/dataForecasting/parkingIs"),
    meta: {
      title: "停车热力图"
    }
  },
  {
    path: "parkFilingSeach",
    name: "parkFilingSeach",
    component: () => import("@/views/Phase_iiiWebPage/recordPark/index"),
    meta: {
      title: "备案车场查询"
    }
  },
  {
    // path: "recordParkAdd",
    // name: "recordParkAdd",
    path: "recordParkAdd",
    // name: "information1/recordParkAdd",
    name: "parkFilingSeach/recordParkAdd",
    component: () =>
      import("@/views/Phase_iiiWebPage/recordPark/recordParkAdd"),
    meta: {
      title: "备案车场修改"
    },
  },
  {
    path: "recordParkDetails",
    name: "parkFilingSeach/recordParkDetails",
    component: () =>
      import("@/views/Phase_iiiWebPage/recordPark/recordParkDetails"),
    meta: {
      title: "备案车场查看"
    }
  },
  {
    path: "parkFilingBackstage",
    name: "parkFilingBackstage",
    component: () => import("@/views/Phase_iiiWebPage/recordPark/parkFilingBackstage.vue"),
    meta: {
      title: "备案申请"
    }
  },
  {
    path: "recordParkAddBA",
    // name: "information1/recordParkAdd",
    name: "parkFilingBackstage/recordParkAddBA",
    component: () =>
      import("@/views/Phase_iiiWebPage/recordPark/recordParkAddBA"),
    meta: {
      title: "备案车场后台-添加"
    },
  },
  {
    path: "detailsInfoBA",
    name: "parkFilingBackstage/recordParkDetailsBA",
    component: () =>
      import("@/views/Phase_iiiWebPage/recordPark/detailsBA"),
    meta: {
      title: "备案车场后台-查看"
    }
  },
  {
    path: "parkFilingDictionary",
    name: "parkFilingDictionary",
    component: () =>
      import("@/views/Phase_iiiWebPage/dictionaries"),
    meta: {
      title: "备案字典"
    }
  },
]
