import * as utils from '@/common/js/utils.js';
import axios from '@/common/js/http'

const state = {
  refeshMenu: null, //  刷新页面的权限数据
  authorityMenu: [],
  allAuthorityMenu: [],
  currentPortalMenu: [],
  button: {}
};

const actions = {
  CHANGE_MENU({
    commit
  }, pMenu) {
    commit('SET_PORTAL_MENU', pMenu);
  },
  CLEAR_MENU({
    commit
  }) {
    commit('SET_MENU_NULL')
  },
  GET_MENU({ commit, state }) {
    return axios.get("/acb/2.0/systems/loginUser/resources").then(res => {
      let sourceData = state.refeshMenu || res.value;
		// console.log('菜单分析:' + JSON.stringify(sourceData))
      let oldSource = JSON.parse(JSON.stringify(sourceData));

      let {
        arrAuthority,
        allAuButton,
        authorityMenu
      } = utils.handleResource(oldSource);

      commit('SET_BUTTON', allAuButton); // 所有页面按钮权限
      commit('SET_MENU', sourceData); // 原始接口数据
      commit('SET_AUTHORITY', authorityMenu); // 不包含按钮的菜单
      sessionStorage.authorityMenu = JSON.stringify(authorityMenu);
      return arrAuthority // 用于动态添加路由 扁平化
    });
  }
};
const mutations = {
  SET_REFRESH_MENU(state, payload) {
    state.refeshMenu = payload;
  },
  SET_MENU_NULL(state) {
    state.allAuthorityMenu = [];
    state.authorityMenu = [];
    state.currentPortalMenu = [];
    state.button = {}
    state.refeshMenu = null;
  },
  SET_AUTHORITY(state, data) {
    state.authorityMenu = data;
  },
  SET_BUTTON(state, data) {
    state.button = data;
  },
  SET_MENU(state, data) {
    state.allAuthorityMenu = data;
  },
  SET_PORTAL_MENU(state, data) {
    state.currentPortalMenu = data;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
