/*
 * @Author: 王昌浩
 * @Date: 2020-08-09 23:49:31
 * @LastEditTime: 2020-11-11 21:09:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \trunk\src\components\notification\notification.js
 */
import Vue from 'vue'
import notification from './notification.vue'

const NotificationBox = Vue.extend(notification)

notification.install = function (options, type) {
  if (options === undefined || options === null) {
    options = {
      content: '',
      path: ''
    }
  } else if (typeof options === 'string' || typeof options === 'number') {
    options = {
      content: options
    }
    if (type !== undefined && options !== null) {
      options.type = type
    }
  }

  let instance = new NotificationBox({
    data: options
  }).$mount()

  document.body.appendChild(instance.$el)

  Vue.nextTick(() => {
    instance.visible = true
  })
  return instance;
}

export default notification
