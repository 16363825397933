import Vue from "vue";
import VueRouter from "vue-router";
import _ from "lodash";
import store from "../store";
import routes from "./defaultRoutes";
import asyncRoutes from "./asyncRoutes";
import * as utils from "@/common/js/utils";

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const createRouter = () =>
  new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

// 重写router.go    因2.9测试 切换tab此方法产生bug，注释此段代码，暂无发现此段代码有何作用
let oldRouteGoFn = router.go;
router.go = function goFn(...args) {
  // console.log(...args);
  let path = utils.getPath(router.currentRoute.name);
  let route = utils.ifDupl(store.state.tabs.currentTabs, path);
  if (!route) {
    return new TypeError("不能找到 " + path + " 的父路由，请检查路由name 属性对应页面是否正确！");
  }
  if (route.children.length === 1) {
    oldRouteGoFn.apply(router, args);
  } else {
    const childrenList = route.children;
    let newChildrenList = [];
    childrenList.forEach((item) => {
      if (newChildrenList.indexOf(item) == -1) {
        newChildrenList.push(item);
      }
    });
    // router.push(route.children[route.children.length - 2]);
    // console.log(newChildrenList)
    router.push(newChildrenList[newChildrenList.length - 2]);
  }
};
router.beforeEach(async function (to, from, next) {
  console.log("to-->>", to);
  if (!sessionStorage.token && to.path == "/java_home_daping") {
    next("/");
    return false;
  }
  if (
    to.path != "login" &&
    to.path != "/" &&
    to.path != "/findPsd" &&
    to.path != "/downloadboss" &&
    to.path != "/daping"
  ) {
    if (!sessionStorage.token) {
      next("/");
      return false;
    }
    if (store.state.menu.allAuthorityMenu.length === 0) {
      // 通过vuex 获取权限
      let allAuRoutes = await store.dispatch("menu/GET_MENU");
      let _allAuRoutes = utils.flattenDeep(allAuRoutes);
      let _asyncRoutes = _.cloneDeep(asyncRoutes);
      let mockAsync = [];
      // 过滤出 有权限的 菜单
      _asyncRoutes[0].children = _allAuRoutes.reduce((mock, route) => {
        // console.log('route--->', route);
        _asyncRoutes[0].children.forEach((v) => {
          // 根据name 判断为哪个页面的子页面 可能会有多个根路由 pathAuInclude 会根据name 找个多个根路由 目前只支持 同一个根路由下的 多个子页面的name 如果一个页面 有多个根路由 会有问题 建议复制页面 给子页面配置不同的路由
          if (
            utils.pathAuInclude(route.webUri, v.name) &&
            !utils.findArrEle(mockAsync, "name", v.name)
          ) {
            // findArrEle 确定路由没有重复添加
            mock.push(v);
          }
        });
        return mock;
      }, mockAsync);
      resetRouter(); // 重置路由
      // 临时 添加路由 start
      mockAsync
        .push

        // {
        //   path: "highBitVideoRecording",
        //   name: "highBitVideoRecording",
        //   component: () => import('@/views/recordTotal/highBitVideoRecording/highBitVideoRecording'),
        //   meta: {
        //     title: '高位视频数据记录'
        //   }
        // }
        ();
      // 临时 end
      router.addRoutes(_asyncRoutes);
      next({
        ...to,
        replace: true,
      });
    } else {
      // 如果没有进入模块不允许跳转
      if (
        store.state.menu.currentPortalMenu &&
        store.state.menu.currentPortalMenu.length === 0 &&
        to.path != "/" &&
        to.path != "/portal"
      ) {
        next(false);
      } else {
        // 向页面 route 添加 按钮权限
        to.meta.authority = store.state.menu.button[utils.getPath(to.name).slice(1)];
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
