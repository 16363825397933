var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.visible
      ? _c(
          "div",
          { staticClass: "message" },
          [
            _c("div", { staticClass: "title" }, [
              _c("span", { staticClass: "tip" }, [_vm._v("消息提醒")]),
              _c("i", {
                staticClass: "el-icon-close",
                staticStyle: { cursor: "pointer" },
                on: { click: _vm.close },
              }),
            ]),
            _c(
              "div",
              {
                staticClass: "content",
                staticStyle: { "text-algin": "center" },
              },
              [
                _c("img", {
                  staticStyle: { width: "100px", height: "100px" },
                  attrs: { src: _vm.imgSrc, alt: "" },
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "content",
                staticStyle: {
                  "border-bottom-style": "solid",
                  "border-color": "#dcdee0",
                  "border-width": "1px",
                  "margin-bottom": "14px",
                },
              },
              [
                _c("span", { staticStyle: { color: "#323233" } }, [
                  _vm._v(_vm._s(_vm.content)),
                ]),
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "#0ba7ee",
                      cursor: "pointer",
                      "margin-left": "10px",
                    },
                    on: { click: _vm.btnSure },
                  },
                  [_vm._v("[去看看]")]
                ),
              ]
            ),
            _c(
              "el-checkbox",
              {
                model: {
                  value: _vm.checked,
                  callback: function ($$v) {
                    _vm.checked = $$v
                  },
                  expression: "checked",
                },
              },
              [_vm._v("本次不再提醒")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }