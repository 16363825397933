export default [
  {
    path: "parkManage",
    name: "parkManage",
    meta: {
      title: '停车场管理'
    },
    component: () => import('@/views/outParkManage'),
  },
  {
    path: 'bacbOverview',
    name: 'bacbOverview',
    component: () => import('@/views/outsideSummarize/index'),
    meta: {
      title: '概览'
    }
  },
  {
    path: 'outParkConfig',
    name: 'parkManage/outParkConfig',
    component: () => import('@/views/outParkManage/parkList/closeParkConfig'),
    meta: {
      title: '停车场配置'
    }
  },
  {
    path: 'outParkThirdPartyConfig',
    name: 'parkManage/outParkThirdPartyConfig',
    component: () => import('@/views/outParkManage/parkList/outParkThirdPartyConfig'),
    meta: {
      title: '停车场配置'
    }
  },
  {
    path: 'outParkLightConfig',
    name: 'parkManage/outParkLightConfig',
    component: () => import('@/views/outParkManage/parkList/outParkLightConfig'),
    meta: {
      title: '停车场配置'
    }
  },
  {
    path: 'outQrCodeDownload',
    name: 'parkManage/outQrCodeDownload',
    component: () =>
      import('@/views/outParkManage/qrCodeDownload/index'),
    meta: {
      title: '二维码下载'
    }
  }, {
    path: 'outAddClosePark',
    name: 'parkManage/addClosePark',
    component: () =>
      import('@/views/outParkManage/parkList/addClosePark'),
    meta: {
      title: '添加封闭车场'
    }
  },
  {
    path: 'calculateManageConf',
    name: 'calculateManageConf',
    component: () =>
      import('@/views/outParkManage/chargeRule/ruleList'),
    meta: {
      title: '计费规则管理'
    }
  },
  {
    path: 'bindDetail',
    name: 'calculateManageConf/bindDetail',
    component: () =>
      import('@/views/outParkManage/chargeRule/bindDetail'),
    meta: {
      title: '绑定详情'
    }
  },
  // 欠费离场规则管理 2.9
  {
    path: 'debtCalculateManageConf',
    name: 'debtCalculateManageConf',
    component: () =>
      import('@/views/outParkManage/arrearsRule/arrearsRule'),
    meta: {
      title: '欠费离场规则管理'
    }
  },
  {
    path: 'addArrearsRule',
    name: 'debtCalculateManageConf/addArrearsRule',
    component: () =>
      import('@/views/outParkManage/arrearsRule/addArrearsRule'),
    meta: {
      title: '添加欠费离场规则'
    }
  },
  {
    path: 'seeArrearsRule',
    component: () =>
      import('@/views/outParkManage/arrearsRule/seeArrearsRule'),
    name: 'debtCalculateManageConf/seeArrearsRule',
    meta: {
      title: '查看欠费离场规则'
    }
  },
  {
    path: 'outCloseParentDetail',
    name: 'parkManage/outCloseParentDetail',
    component: () =>
      import('@/views/outParkManage/parkList/detailComponent/parentDetail'),
    meta: {
      title: '停车场详情'
    }
  },
  {
    path: 'outParkConfig',
    name: 'parkManage/outParkConfig',
    component: () =>
      import('@/views/outParkManage/parkList/closeParkConfig'),
    meta: {
      title: '停车场配置'
    }
  },
  {
    path: 'secondParkConfig',
    name: 'parkManage/outCloseParentDetail/secondParkConfig',
    component: () =>
      import('@/views/outParkManage/parkList/secondParkConfig'),
    meta: {
      title: '二级停车场配置'
    }
  },
  {
    path: '/collectorM',
    component: resolve => require(['@/views/outParkManage/collectorManage/collentorListRoadside/collectorManageRoadside'], resolve),
    name: 'collectorM',
    meta: {
      title: '人员管理'
    }
  },
  {
    path: '/collectorMAdd',
    component: resolve => require(['@/views/outParkManage/collectorManage/collentorListRoadside/collectorManageAddRoadside'], resolve),
    name: 'collectorM/collectorMAdd',
    meta: {
      title: '人员管理添加'
    }
  },
  {
    path: '/PDAManage',
    // path: '/collectorM',
    component: resolve => require(['@/views/outParkManage/collectorManage/PDAManager/index'], resolve),
    name: 'PDAManage',
    // name: 'collectorM',
    meta: {
      title: 'PDA收费员管理'
    }
  },
  {
    path: '/PDAManageAdd',
    // path: '/collectorMAdd',
    component: resolve => require(['@/views/outParkManage/collectorManage/PDAManager/add'], resolve),
    name: 'PDAManage/PDAManageAdd',
    // name: 'collectorM/collectorMAdd',
    meta: {
      title: 'PDA收费员管理添加'
    }
  },
  // ////////////新增菜单
  {
    path: '/collectorMC',
    component: resolve => require(['@/views/outParkManage/collectorManage/tollgathererManage'], resolve),
    name: 'collectorMC',
    meta: {
      title: '收费员管理'
    }
  },
  {
    path: '/tollgathererManageAdd',
    component: resolve => require(['@/views/outParkManage/collectorManage/tollgathererManage/tollgathererManageAdd'], resolve),
    name: 'collectorMC/tollgathererManageAdd',
    meta: {
      title: '收费员管理添加'
    }
  },
  // /////////////////////////原始菜单
  // {
  //   path: '/workReport',
  //   component: resolve => require(['@/views/outParkManage/collectorManage/workReport/personnelReport'], resolve),
  //   name: 'workReport',
  //   meta: {
  //     title: '交接班报表'
  //   }
  // },
  {
    path: '/videoMonitor',
    component: resolve => require(['@/views/outParkManage/equipmentManage/videoMonitorEquipmentRoadside/'], resolve),
    name: 'videoMonitor',
    meta: {
      title: 'NVR管理' // 曾用名视频监控管理
    }
  },
  {
    path: '/videoMonitorChennel',
    component: resolve => require(['@/views/outParkManage/equipmentManage/outVideoMonitor'], resolve),
    name: 'videoMonitorChennel',
    meta: {
      title: '监控设备管理'
    }
  },
  {
    path: '/parkingStatusRecord',
    component: resolve => require(['@/views/outParkManage/equipmentManage/parkingStatusRecord'], resolve),
    name: 'parkingStatusRecord',
    meta: {
      title: '车场状态记录'
    }
  },
  {
    path: '/videoMonitorChennelEdit',
    component: () =>
      import('@/views/outParkManage/equipmentManage/outVideoMonitor/edit'),
    name: 'videoMonitorChennel/videoMonitorChennelEdit',
    meta: {
      title: '添加监控设备'
    }
  },
  {
    path: '/addVideoMonitorEquipRoadside',
    component: resolve => require(['@/views/outParkManage/equipmentManage/videoMonitorEquipmentRoadside/addVideoMonitorEquipRoadside'], resolve),
    name: 'videoMonitor/addVideoMonitorEquipRoadside',
    meta: {
      title: '添加视频监控设备'
    }
  },
  {
    path: '/editVideoMonitorEquipRoadside',
    component: resolve => require(['@/views/outParkManage/equipmentManage/videoMonitorEquipmentRoadside/editVideoMonitorEquipRoadside'], resolve),
    name: 'videoMonitor/editVideoMonitorEquipRoadside',
    meta: {
      title: '编辑视频监控设备'
    }
  },
  {
    path: '/outside',
    component: () => import('@/views/outParkManage/equipmentManage/roadOutEquipmentRoadside/'),
    name: 'outside',
    meta: {
      title: '路外设备管理',

    }
  },
  {
    path: '/roadOutEquipmentDetailRoaddside',
    component: () => import('@/views/outParkManage/equipmentManage/roadOutEquipmentRoadside/roadOutEquipmentDetailRoaddside'),
    name: 'outside/roadOutEquipmentDetailRoaddside',
    meta: {
      title: '路外设备监控详情'
    }
  },
  {
    path: '/gateRecord',
    component: () => import('@/views/outParkManage/exceptionManagement/gateRecord'),
    name: 'gateRecord',
    meta: {
      title: '特殊放行记录'
    }
  },
  {
    path: '/exceptionManagement1',
    component: () => import('@/views/outParkManage/exceptionManagement/zombiecar'),
    name: 'exceptionManagement1',
    meta: {
      title: '僵尸车管理'
    }
  },
  {
    path: '/exceptionManagement2',
    component: () => import('@/views/outParkManage/exceptionManagement/zombiecarhistory'),
    name: 'exceptionManagement2',
    meta: {
      title: '僵尸车清理记录'
    }
  },
  {
    path: '/correctRecord',
    component: () => import('@/views/outParkManage/exceptionManagement/correctRecord'),
    name: 'correctRecord',
    meta: {
      title: '车牌修正记录'
    }
  },
  {
    path: '/businessParkOrder',
    component: resolve => require(['@/views/outParkManage/paymentRecordQueryRoadside/paymentRecordRoadside'], resolve),
    name: 'businessParkOrder',
    meta: {
      title: '停车支付订单'
    }
  },
  {
    path: 'businessParkRecord',
    name: 'businessParkRecord',
    component: () =>
      import('@/views/outParkManage/parkRecordTotalRoadside/parkRecordTotalRoadside'),
    meta: {
      title: '停车记录查询'
    }
  },
  // 路外--欠费记录查询
  {
    path: 'outArrearsRecordTotal',
    name: 'outArrearsRecordTotal',
    component: () =>
      import('@/views/outParkManage/arrearsRecordTotal/arrearsRecordTotal'),
    meta: {
      title: '欠费记录查询'
    }
  },
  {
    path: 'businessDebtOrder',
    name: 'businessDebtOrder',
    component: () =>
      import('@/views/outParkManage/businessDebtOrder'),
    meta: {
      title: '停车欠费订单'
    }
  },
  {
    path: 'businessRobotOcrRecord',
    component: () => import('@/views/outParkManage/robotWater/index'),
    name: 'businessRobotOcrRecord',
    meta: {
      title: '机器人流水记录'
    }
  },
  {
    path: 'businessOcrRecord',
    component: () => import('@/views/outParkManage/parkingWater/index'),
    name: 'businessOcrRecord',
    meta: {
      title: '停车流水查询'
    }
  },
  {
    path: 'mbUpgrade',
    name: 'mbUpgrade',
    component: () =>
      import('@/views/outParkManage/mbUp'),
    meta: {
      title: 'MB升级'
    }
  },
  {
    path: 'exceptionParkReport',
    name: 'exceptionParkReport',
    component: () =>
      import('@/views/abnormalAlarm'),
    meta: {
      title: '上报记录查询'
    }
  },
  {
    path: 'abnormalAlarmDetail',
    name: 'exceptionParkReport/abnormalAlarmDetail',
    component: () => import('@/views/abnormalAlarm/abnormalAlarmDetail'),
    meta: {
      title: '异常停车上报详情'
    }
  },
  {
    path: 'exceptionParkConfig',
    name: 'exceptionParkReport/exceptionParkConfig',
    component: () =>
      import('@/views/abnormalAlarm/abnormalConfiguration'),
    meta: {
      title: '异常停车配置'
    }
  },
  {
    path: 'smartProcessManagement',
    component: () => import('@/views/outParkManage/intelligentHandleRecord/index'),
    name: 'smartProcessManagement',
    meta: {
      title: '智能处理记录'
    }
  },
  {
    path: 'exceptionParkStatic',
    name: 'exceptionParkStatic',
    component: () =>
      import('@/views/abnormalAlarm/abnormalStatistics'),
    meta: {
      title: '异常停车统计'
    }
  },
  {
    path: '/monitorEquipment',
    component: () => import('@/views/equipmentManage/videoMonitor/index'),
    name: 'monitorEquipment',
    meta: {
      title: '监控设备',
    }
  },
  {
    path: '/videoMonitorEdit',
    component: () => import('@/views/equipmentManage/videoMonitor/edit'),
    name: 'monitorEquipment/edit',
    meta: {
      title: '监控设备管理编辑',
    }
  }
]

