/*
 * @Author: DZM
 * @Date: 2022-03-25 16:57:20
 * @LastEditors: lijing
 * @LastEditTime: 2022-08-18 15:28:16
 * @Description: 
 */
import axios from '../../http';
import { dateFormat } from '../../public';
const leftSideApi = {
  /**
   * @description: 车场总数
   * @return {*}
   */
  parkingTotal(params) {
    return axios.get('/acb/2.0/dataAnalysisOverView/getParkAndBerthCount', {
      data: params
    })
  },
  /**
   * @description: 今日服务车次数
   * @return {*}
   */  
  NumberOfServiceToday(params) {
    return axios.get('/acb/2.0/dataAnalysisOverView/getParkRecordCount', {
      data: params
    })
  },
  /**
   * @description: 累计收入
   * @return {*}
   */  
  totalRevenue(params) {
    return axios.get('/acb/2.0/dataAnalysisOverView/summary', {
      data: params
    })
  },
  /**
   * @description: 累计收入&今日收入
   * @return {*}
   */  
  javaTotalRevenue(params) {
    return axios.get('/acb/2.0/aceParkAnalysis/aceTodayIncome', {
      data: params
    })
  },
  /**
   * @description: 注册会员
   * @return {*}
   */  
  totalMemberCount(params) {
    return axios.get('/acb/2.0/dataAnalysisOverView/totalMemberCount', {
      data: params
    })
  },
  /**
   * @description: 今日收入
   * @return {*}
   */  
  todayIncomeAmount(params) {
    return axios.get('/acb/2.0/dataAnalysisOverView/todayIncomeAmount', {
      data: params
    })
  },
  /**
   * @description: 停车周转分析图
   * @return {*}
   */  
   parkTurnOverDay(params) {
    return axios.get('/acb/2.0/dataAnalysisTrend/parkTurnOverDay', {
      data: params
    })
  },
  /**
   * @description: 停车周转分析图java
   * @return {*}
   */  
  parkRealTime(params) {
    return axios.get('/acb/2.0/aceBerth/parkRealTime', {
      data: params
    })
  },
  /**
   * @description: 泊位饱和度趋势及预测
   * @return {*}
   */  
   parkBerthOccupyDay(params) {
    return axios.get('/acb/2.0/dataAnalysisTrend/parkBerthOccupyDay', {
      data: params
    })
  },
  /**
   * @description: 近30日泊位利用率和周转率
   * @return {*}
   */  
  homePageBerthStatics(params) {
    return axios.get('/acb/2.0/aceBerth/homePageBerthStatics', {
      data: params
    })
  },
  // -----------------------------------------------------------------------------切换第二页API
  /**
   * @description: 剩余泊位预测
   * @return {*}
   */  
   berthPredict(params) {
    return axios.get('/acb/2.0/bigScreen/map/park/berthPredict', {
      data: params
    })
  },
  /**
   * @description: 剩余泊位预测java
   * @return {*}
   */  
  javaBerthPredict(params) {
    return axios.get('/acb/2.0/aceBerth/freeBerthRatio', {
      data: params
    })
  },
}
export default leftSideApi
