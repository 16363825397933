/*
 * @Author: DZM
 * @Date: 2022-03-16 10:55:58
 * @LastEditors: lijing
 * @LastEditTime: 2022-09-05 16:32:05
 * @Description: 
 */
  /**
   * @description: 分金额处理 示例：{{ 123 | moneyHandling('元') }}
   * @param {*} val 内容
   * @param {*} unit 单位
   * @return {*}
   */
  export const moneyHandling = (val, unit) => {
    let units = unit || '';
    if (!val) return '0.00' + units;
    return (val / 100).toFixed(2) + units
  }

  /**
   * @description: 万金额处理 示例：{{ 123 | keepTwoDecimalFull() }}
   * @param {*} val 内容
   * @param {*} 
   * @return {*}
   */
  // js将数字转换成万 并且保留两位小数
  export const keepTwoDecimalFull = (value) => {
    if (!value) return '0.00';
    value = value / 100
    if (value > 100000000 || value < -100000000) {
      return Number(value / 100000000).toFixed(2)
    } else if (value > 10000 || value < -10000) {
      return Number(value / 10000).toFixed(2)
    } else {
      return Number(value).toFixed(2)
    }
  }

  /**
   * @description: 金额转换为单位 示例：{{ 123 | GetUnit() }}
   * @param {*} val 内容
   * @param {*} 
   * @return {*}
   */
  // js将数字转换成万 并且保留两位小数
  export const GetUnit = (value) => {
    if (!value) "元"
    value = value / 100
    value = Math.abs(value)
    if (value > 100000000) {
      return "亿"
    } else if (value > 10000) {
      return "万"
    } else {
      return "元"
    }
  }



  /**
   * @description: 文字超出省略号显示
   * @param {*} val 内容
   * @param {*} digits 位数
   * @return {*}
   */  
  export const wordProcessing = (val, digits) => {
    if (!val) return '';
    if (val.length > digits) {
      return val.slice(0, digits) + '...';
    }
    return val;
  }

  /**
   * @description: 百分比计算
   * @param {*} val 内容
   */  
   export const percentageCalculation = (val) => {
    if (!val) return '0.00';
    return (val * 100).toFixed(2);
  }
