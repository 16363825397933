// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/AlibabaPuHuiTi-2-55-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/PingFang SC.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/SanFranciscoDisplay-Bold-4.otf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"Alibaba PuHuiTi\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  font-weight: normal;\n}\n@font-face {\n  font-family: \"PingFang\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  font-weight: normal;\n}\n@font-face {\n  font-family: \"SanFran\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  font-weight: normal;\n}\n\n.el_button {\n  width: 60px;\n  height: 32px;\n  padding: 0;\n  font-size: 14px;\n  color: #fff;\n  font-family: \"PingFang\";\n  background-color: #165dff !important;\n  border-color: #165dff !important;\n  border-radius: 2px;\n  -webkit-transition: all 0.5s;\n  transition: all 0.5s;\n}\n.el_button:hover {\n  background-color: #457dff !important;\n  border-color: #457dff !important;\n}\n.el_button_off {\n  width: 60px;\n  height: 32px;\n  padding: 0;\n  font-size: 14px;\n  background-color: #f2f3f5;\n  border: none;\n  -webkit-transition: all 0.5s;\n  transition: all 0.5s;\n}\n.el_button_off:hover {\n  color: #165dff !important;\n  background-color: #f2f3f5 !important;\n}\n", ""]);
// Exports
module.exports = exports;
