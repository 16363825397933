<template>
  <div class="a-date-time-picker-wrapper">
    <div class="date-picker">
      <el-select
        v-model.trim="selectKey"
        placeholder=""
        v-if="showSelect"
        @change="handleChange"
        class="time_type"
      >
        <el-option label="小时" value="datetime" v-if="selectkeys.includes('datetime')"></el-option>
        <el-option label="日" value="date" v-if="selectkeys.includes('date')"></el-option>
        <el-option label="周" value="week" v-if="selectkeys.includes('week')"></el-option>
        <el-option label="月" value="month" v-if="selectkeys.includes('month')"></el-option>
        <el-option
          label="年"
          value="year"
          v-if="selectkeys.includes('year') && YearShowHidden"
        ></el-option>
      </el-select>
      <el-date-picker
        @change="changeValue1"
        :picker-options="isPreviousday ? pickerOptions1 : pickerOptions"
        :editable="false"
        :clearable="false"
        :valueFormat="
          selectKey == 'datetime'
            ? 'yyyy-MM-dd HH:mm'
            : isPreviousday && selectKey == 'month'
            ? 'yyyy-MM'
            : isPreviousday && selectKey == 'year'
            ? 'yyyy'
            : 'yyyy-MM-dd'
        "
        :format="
          selectKey == 'datetime'
            ? 'yyyy-MM-dd HH:mm'
            : isPreviousday && selectKey == 'month'
            ? 'yyyy-MM'
            : isPreviousday && selectKey == 'year'
            ? 'yyyy'
            : 'yyyy-MM-dd'
        "
        v-model="value1"
        :type="selectKey"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        class="date_picker"
      >
      </el-date-picker>
      &nbsp;&nbsp; 至 &nbsp;&nbsp;
      <el-date-picker
        :picker-options="isPreviousday ? pickerOptions1 : pickerOptions"
        @change="changeValue2"
        :editable="false"
        :clearable="false"
        :valueFormat="
          selectKey == 'datetime'
            ? 'yyyy-MM-dd HH:mm'
            : isPreviousday && selectKey == 'month'
            ? 'yyyy-MM'
            : isPreviousday && selectKey == 'year'
            ? 'yyyy'
            : 'yyyy-MM-dd'
        "
        :format="
          selectKey == 'datetime'
            ? 'yyyy-MM-dd HH:mm'
            : isPreviousday && selectKey == 'month'
            ? 'yyyy-MM'
            : isPreviousday && selectKey == 'year'
            ? 'yyyy'
            : 'yyyy-MM-dd'
        "
        v-model="value2"
        :type="selectKey"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        class="date_picker"
      >
      </el-date-picker>
    </div>
    <div class="text-wrapper">
      <div class="left">
        <transition-group name="fade">
          <!-- <transition name="fade"> -->
          <div v-show="showLeft" :key="1">
            <span class="info-text-left">已为你调整时间</span>
          </div>
          <div v-show="!showLeft && showRight" :key="2" style="opacity: 0">已为你调整时间</div>
          <!-- </transition> -->
        </transition-group>
      </div>
      <div class="right">
        <transition name="fade">
          <div v-show="showRight">
            <span class="info-text-right" :style="comRightStyle">已为你调整时间</span>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
moment.suppressDeprecationWarnings = true;
export default {
  props: {
    // 年份的显示隐藏； 随便传点东西有值即可 string类型
    YearShowHidden: {
      type: String,
    },
    // dateType
    // date:1
    // week:5
    // month:2
    // year:3
    // ['date', 'month', 'week', 'year'] 第一个默认选中
    selectkeys: {
      type: Array,
      default: () => [],
    },
    previousDate: {},
    nextDate: {}, // 时间默认展示到前一天
    isDefaultMonth: {
      // 默认一个月
      type: Boolean,
      default: false,
    },
    isPreviousday: {
      type: Boolean,
      default: false,
    }, // 控件时间不能选择当天
  },
  computed: {
    comRightStyle() {
      // if (this.showLeft && this.showRight) {
      //     return "top:-7px;";
      // }

      if (!this.showLeft && this.showRight) {
        // return "left: 300px";
        //   position relative;
        //   top: -7px;
        //   left: 283px;
        // return `top:33px`;
      }
    },
  },
  watch: {
    selectKey: {
      handler() {
        // 初始化时间
        // this.show = true;
        this.initDate();
      },
      immediate: true,
    },
    selectkeys: {
      handler(val) {
        if (val.length > 0) {
          this.selectKey = val[0];
          this.showSelect = true;
        } else {
          this.selectKey = "date";
          this.showSelect = false;
        }
      },
      deep: true,
      immediate: true,
    },
    previousDate: {
      handler(val) {
        if (val) {
          let time =
            moment(new Date(val.getTime() - 24 * 60 * 60 * 1000)).format("YYYY-MM-DD") +
            " 00:00:00";
          this.value1 = time;
        }
      },
      deep: true,
      immediate: true,
    },
    nextDate: {
      handler(val) {
        if (val) {
          let time =
            moment(new Date(val.getTime() - 24 * 60 * 60 * 1000)).format("YYYY-MM-DD") +
            " 23:59:59";
          this.value2 = time;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      showLeft: false,
      showRight: false,
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          return time.getTime() >= Date.now();
        },
      },
      pickerOptions1: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e7;
        },
      },
      value1: "",
      value2: "",
      showSelect: false,
      selectKey: "date",
    };
  },
  methods: {
    handleChange() {
      // this.showLeft = !this.showLeft;
      // this.showRight = !this.showRight;
      // setTimeout(() => {
      //   this.showLeft = !this.showLeft;
      //   this.showRight = !this.showRight;
      // }, 1000);
    },
    handleShowLeft() {
      if (this.showLeft == false) {
        this.showLeft = !this.showLeft;
        setTimeout(() => {
          this.showLeft = !this.showLeft;
        }, 1500);
      }
    },
    handleShowRight() {
      if (this.showRight == false) {
        this.showRight = !this.showRight;
        setTimeout(() => {
          this.showRight = !this.showRight;
        }, 1500);
      }
    },
    getStartDate() {
      // let dateType = 1;
      let value = this.value1;
      switch (this.selectKey) {
        case "date":
          value = moment(this.value1).format("YYYY-MM-DD");
          break;
        case "week":
          value = moment(this.value1).format("YYYY-MM-DD");
          break;
        case "month":
          value = moment(this.value1).format("YYYY-MM");
          break;
        case "year":
          value = moment(this.value1).format("YYYY");
          break;
        case "datetime":
          value = moment(this.value1).format("YYYY-MM-DD HH:mm");
          break;
        default:
          break;
      }
      return value;
    },
    getEndDate() {
      let value = this.value2;
      switch (this.selectKey) {
        case "date":
          value = moment(this.value2).format("YYYY-MM-DD");
          break;
        case "week":
          value = moment(this.value2).format("YYYY-MM-DD");
          break;
        case "month":
          value = moment(this.value2).format("YYYY-MM");
          break;
        case "year":
          value = moment(this.value2).format("YYYY");
          break;
        case "datetime":
          value = moment(this.value2).format("YYYY-MM-DD HH:mm");
          break;
        default:
          break;
      }
      return value;
    },
    getDateType() {
      let dateType = 1;
      switch (this.selectKey) {
        case "date":
          dateType = 1;
          break;
        case "week":
          dateType = 3;
          break;
        case "month":
          dateType = 2;
          break;
        case "year":
          dateType = 4;
          break;
        case "datetime":
          dateType = 5;
          break;
        default:
          break;
      }
      return dateType;
    },
    getMonthType() {
      let dateType = 1;
      switch (this.selectKey) {
        case "date":
          dateType = 1;
          break;
        case "week":
          dateType = 4;
          break;
        case "month":
          dateType = 2;
          break;
        case "year":
          dateType = 3;
          break;
        default:
          break;
      }
      return dateType;
    },
    exchange() {
      let left = this.value1;
      let right = this.value2;

      let isBefore = moment(left).isBefore(right);
      if (!isBefore) {
        let temp = this.value1;
        this.value1 = this.value2;
        this.value2 = temp;
        this.handleShowLeft();
        this.handleShowRight();
      }

      this.formatDate1();
      this.formatDate2();
      // this.changeValue2
    },
    formatDate1() {
      let arr = this.value1.split(" ");
      if (arr[1] == "23:59:59") {
        arr[1] = "00:00:00";
      }
      this.value1 = arr.join(" ");
    },
    formatDate2() {
      let arr = this.value2.split(" ");
      if (arr[1] == "00:00:00") {
        arr[1] = "23:59:59";
      }
      this.value2 = arr.join(" ");
    },
    changeValue1(val) {
      if (this.selectKey == "week") {
        val = moment(val).subtract(1, "days").format("YYYY-MM-DD HH:mm:ss");
        this.value1 = val;
      }
      let nowT = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      let flag = moment(nowT).isBefore(val);

      if (flag) {
        this.value1 = nowT;
      }
      this.exchange();
      this.overstep(true);
    },
    overstep(isChangeLeft) {
      let left = this.value1;
      let right = this.value2;
      let days = 30;
      switch (this.selectKey) {
        case "date":
          days = 30;
          break;
        case "week":
          days = 12 * 7;
          break;
        case "month":
          days = 180;
          break;
        case "year":
          days = 365 * 5;
          break;
        case "datetime":
          days = 30;
          break;
        default:
          break;
      }
      // 超出范围处理逻辑
      if (Math.abs(moment(left).diff(moment(right), "days")) > days) {
        if (isChangeLeft) {
          let right = `${moment(left).add(days, "days").format("YYYY-MM-DD")} 23:59:59`;
          let nowT = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
          let isBefore = moment(nowT).isBefore(right);
          if (isBefore) {
            right = nowT;
          }
          this.value2 = right;
          this.handleShowRight();
        } else {
          let left = `${moment(right).subtract(days, "days").format("YYYY-MM-DD")} 00:00:00`;
          this.value1 = left;
          this.handleShowLeft();
        }
      }
      if (this.selectKey == "month") {
        this.value1 = `${moment(this.value1).format("YYYY-MM")}-01 00:00:00`;
        // console.log(, "11111111111");
        this.value2 = `${moment(this.value2).endOf("month").format("YYYY-MM-DD")} 23:59:59`;
      }
      let nowT = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      let isBefore = moment(nowT).isBefore(this.value2);
      if (isBefore) {
        this.value2 = nowT;
        this.handleShowRight();
      }
    },
    changeValue2(val) {
      // console.log()
      // 格式化第二个选择的时间
      switch (this.selectKey) {
        case "date":
          let date = moment(val).format("YYYY-MM-DD");
          this.value2 = `${date} 23:59:59`;
          break;
        case "week":
          let week = moment(this.weDateFormat(val).lastSunday).format("YYYY-MM-DD");
          this.value2 = `${week} 23:59:59`;
          break;
        case "month":
          let month = moment(val).format("YYYY-MM-DD");
          this.value2 = `${month} 23:59:59`;
          break;
        case "year":
          let year = moment(val).format("YYYY-MM-DD");
          this.value2 = `${year} 23:59:59`;
          break;
          // case "datetime":
          //   let datetime = moment(val).format("YYYY-MM-DD HH:mm");
          //   this.value2 = `${datetime} 23:59:59`;
          break;
        default:
          break;
      }

      //  超出当前时间置为当前时间
      let selectT = this.value2;
      let nowT = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

      let isBefore = moment(nowT).isBefore(selectT);
      if (isBefore) {
        this.value2 = nowT;
      }
      this.exchange();
      this.overstep(false);
    },
    lastYear(nowDay) {
      var yue2 = moment(nowDay).month(1).daysInMonth();
      var yearNum;

      if (yue2 === 28) {
        yearNum = 365;
      } else {
        yearNum = 366;
      }

      var lastYear = moment(nowDay).add(-1, "years").format("YYYY");
      return lastYear;
    },
    weDateFormat(date, strDate) {
      let weekOfDay = moment(date).format("E"); // 指定日期的周的第几天

      let lastMonday = moment(date)
        .subtract(weekOfDay - 1, "days")
        .format("YYYY-MM-DD"); //周一日期
      let lastTuesday = moment(date)
        .subtract(weekOfDay - 2, "days")
        .format("YYYY-MM-DD"); //周二日期
      let lastWednesday = moment(date)
        .subtract(weekOfDay - 3, "days")
        .format("YYYY-MM-DD"); //周三日期
      let lastThursday = moment(date)
        .subtract(weekOfDay - 4, "days")
        .format("YYYY-MM-DD"); //周四日期
      let lastFriday = moment(date)
        .subtract(weekOfDay - 5, "days")
        .format("YYYY-MM-DD"); //周五日期
      let lastSaturday = moment(date)
        .subtract(weekOfDay - 6, "days")
        .format("YYYY-MM-DD"); //周六日期
      let lastSunday = moment(date)
        .add(7 - weekOfDay, "days")
        .format("YYYY-MM-DD"); //周日日期
      return {
        lastMonday,
        lastTuesday,
        lastWednesday,
        lastThursday,
        lastFriday,
        lastSaturday,
        lastSunday,
      };
    },
    clear() {
      this.selectKey = "date";
      this.initDate();
    },
    initDate() {
      switch (this.selectKey) {
        case "date":
          let last7 = this.isPreviousday
            ? moment().subtract("days", 7).format("YYYY-MM-DD")
            : moment().subtract("days", 1).format("YYYY-MM-DD");
          this.value1 = `${last7} 00:00:00`;
          let nowDate = moment().subtract("days", 1).format("YYYY-MM-DD HH:mm:ss");
          this.value2 = nowDate;
          break;
        case "week":
          let last20 = moment().subtract("days", 19).format("YYYY-MM-DD HH:mm:ss");
          let lastMonday = this.weDateFormat(last20).lastMonday; // 所在周周一
          this.value1 = moment(lastMonday).format("YYYY-MM-DD HH:mm:ss");
          let nowDate2 = this.isPreviousday
            ? moment().subtract("days", 1).format("YYYY-MM-DD HH:mm:ss")
            : moment().format("YYYY-MM-DD HH:mm:ss");
          this.value2 = nowDate2;
          break;
        case "month":
          let val1 = "";
          if (!this.isDefaultMonth) {
            val1 = moment(new Date()).subtract(5, "months").format("YYYY-MM");
          } else {
            val1 = moment(new Date()).subtract(1, "months").format("YYYY-MM");
          }
          console.log(val1, "val1");
          // let nowMonth = moment(new Date()).startOf("month").format("YYYY-MM-DD HH:mm:ss");
          // console.log(nowMonth);
          // let arr = nowMonth.split("-");
          // arr.forEach((item, index) => {
          //   if (index == 0) {
          //     arr[index] = item - 1;
          //   }
          // });
          this.value1 = `${val1}-01 00:00:00`;
          this.value2 = this.isPreviousday
            ? moment().subtract("days", 1).format("YYYY-MM-DD HH:mm:ss")
            : moment().format("YYYY-MM-DD HH:mm:ss");
          break;
        case "year":
          // console.log(this.lastYear())
          this.value1 = `${this.lastYear()}-01-01 00:00:00`;
          this.value2 = this.isPreviousday
            ? moment().subtract("days", 1).format("YYYY-MM-DD HH:mm:ss")
            : moment().format("YYYY-MM-DD HH:mm:ss");
          break;
        case "datetime":
          let datetime = moment().subtract("days", 1).format("YYYY-MM-DD");
          this.value1 = `${datetime} 00:00`;
          let nowDatedatetime = moment().subtract("days", 1).format("YYYY-MM-DD");
          this.value2 = `${nowDatedatetime} 23:59`;
          console.log(datetime, this.value1, this.value2, "datetime", nowDatedatetime);
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    if (this.isDefaultMonth) {
      this.initDate();
    }
  },
};
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity 1.5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active, 2.1.8 版本以下 */ {
    opacity: 0
}
.a-date-time-picker-wrapper {
  display: flex;
  flex-direction: column;
  .date-picker {
    display: flex;
    flex-direction: row;
  }
  .text-wrapper {
    position: absolute;
    display: flex;
    flex-direction: row;
    color: #999999;
    font-size: 12px;
    // .left {
    //   flex:1
    // }
    // .right {
    //   flex:1
    // }
    .info-text-left {
      position relative;
      top: 25px;
      left:93px;
    }
    .info-text-right {
      position relative;
      top: 25px;
      left:166px;
    }
  }
}
.el-picker-panel__footer .el-button--text.el-picker-panel__link-btn {
    display: none;
  }
::v-deep .time_type {
  margin-right:10px
  .el-input__inner{
    width:72px
  }
}

::v-deep .date_picker{
  width:136px
  .el-input__inner{
    width:140px
  }
}
</style>
