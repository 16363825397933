/*
 * @Author: your name
 * @Date: 2021-01-29 11:27:59
 * @LastEditTime: 2021-02-24 16:15:31
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \aiparkcity-acb\src\router\asyncRoutes.js
 */
import parkManage from './modules/park-manage';
import systemManage from './modules/system-manage';
import outParkManage from './modules/outPark-manage';
import acsTwoThree from './modules/acsTwoThree';
import businessManage from './modules/businessManage';
import finance from './modules/finance';
import dataAnalysis from './modules/data-analysis';
import operationCenter from './modules/operation-center';
import parkGuidance from './modules/park-guidance';
import propertyManage from './modules/property-manage';
import recoveryOfArrears from './modules/recovery-of-arrears';
import javaDataAnalysis from './modules/java-data-analysis';
const allRoutes = [{
    path: '/',
    name: 'Index',
    component: () => import('../layout/index.vue'),
    children: [
      ...parkManage,
      ...businessManage,
      ...finance,
      ...dataAnalysis,
      ...operationCenter,
      ...systemManage,
      ...outParkManage,
      ...acsTwoThree,
      ...parkGuidance,
      ...propertyManage,
      ...recoveryOfArrears,
      ...javaDataAnalysis
    ]
  },
  {
    path: '*',
    name: '404',
    hidden: true,
    component: () =>
      import('../views/404'),
  }
];

export default allRoutes;
