<template>
  <div class="a-park-type-select-wrapper">
    <el-select v-model.trim="parkType" filterable clearable @change="change">
      <el-option label="全部" :value="''" :key="''"></el-option>
      <el-option
        :label="value.desc"
        :value="value.value"
        :key="value.value"
        v-for="value in parkTypeList"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      parkType: "",
      parkTypeList: [
        {
          value: 1,
          desc: "路内道路",
        },
        {
          value: 2,
          desc: "路外车场",
        },
      ],
    };
  },
  created() {},
  methods: {
    getParkType() {
      return this.parkType;
    },
    clear() {
      this.parkType = "";
    },
    change(val) {
      this.$emit("change", val);
    },
  },
};
</script>

<style lang="stylus" rel="stylesheet/stylus"></style>
