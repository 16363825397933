/*
 * @Author: DZM
 * @Date: 2022-03-23 18:14:51
 * @LastEditors: lijing
 * @LastEditTime: 2022-03-28 14:49:59
 * @Description: 
 */
// 首页地图 Echart图表
import homeEachart from './homeEachart';
// 左侧组件
import leftSideApi from './leftSideApi';
// 右侧组件
import rightSideApi from './rightSideApi';

export default {
  homeEachart,
  leftSideApi,
  rightSideApi
}
